import axios from 'axios';
import { Constants } from '../constants';

export const MENUITEMS = [
  {
    path: '/brands/dashboard', title: 'Dashboard', icon: 'panel', sidebartitle: 'Brands', active: false, type: 'link',
  },
  {
    title: 'Manager',
    icon: 'pencil-alt',
    type: 'sub',
    badgeType: 'primary',
    badgeValue: 'new',
    active: false,
    children: [
      { path: '/brands/manager', title: 'All Brands', type: 'link' },
      { path: '/brands/manager?filter=cigar_brands', title: 'Cigar Brands', type: 'link' },
      { path: '/brands/manager?filter=other_brands', title: 'Bloggers, Podcasters, etc', type: 'link' },
      { path: '/brands/claims', title: 'Pending Claims', type: 'link' },
    ],
  },
  {
    path: '/cigars/dashboard', title: 'Dashboard', icon: 'panel', sidebartitle: 'Cigars', active: false, type: 'link',
  },
  {
    title: 'Manager',
    icon: 'pencil-alt',
    type: 'sub',
    badgeType: 'primary',
    badgeValue: 'new',
    active: false,
    children: [
      { path: '/cigars/manager', title: 'All Cigars', type: 'link' },
      { path: '/targets/manager', title: 'Image Recognition', type: 'link' },
      // { path: '/cigars/pending', title: 'Pending Cigars', type: 'link' },
      { path: '/cigars/matches', title: 'Pending Matches', type: 'link', badgeValue: '4' },
    ],
  },
  {
    path: '/drink-pairings/manager', title: 'Drink Pairings', iconType: 'component', icon: 'FiCoffee', active: false, type: 'link',
  },
  {
    path: '/venues/dashboard', title: 'Dashboard', icon: 'panel', sidebartitle: 'Venues', active: false, type: 'link',
  },
  {
    title: 'Manager',
    icon: 'pencil-alt',
    type: 'sub',
    badgeType: 'primary',
    badgeValue: 'new',
    active: false,
    children: [
      { path: '/venues/manager', title: 'All Venues', type: 'link' },
      { path: '/venues/pending', title: 'Pending Venues', type: 'link' },
      { path: '/venues/claims', title: 'Pending Claims', type: 'link' },
    ],
  },
  {
    path: '/groups/manager', title: 'Group Manager', icon: 'pencil-alt', sidebartitle: 'Groups', active: false, type: 'link',
  },
  {
    path: '/events/manager', title: 'Event Manager', icon: 'pencil-alt', sidebartitle: 'Events', active: false, type: 'link',
  },
  {
    path: '/shop/dashboard', title: 'Dashboard', icon: 'panel', sidebartitle: 'Shop', active: false, type: 'link',
  },
  {
    path: '/shop/manager', title: 'Products', icon: 'pencil-alt', active: false, type: 'link',
  },
  {
    path: '/shop/orders', title: 'Orders', icon: 'package', active: false, type: 'link',
  },
  {
    path: '/shop/carts/abandoned', title: 'Abandoned Carts', icon: 'shopping-cart', active: false, type: 'link',
  },
  {
    path: '/shop/discounts', title: 'Discounts', iconType: 'component', icon: 'FiPercent', active: false, type: 'link',
  },
  {
    title: 'Configuration',
    icon: 'settings',
    type: 'sub',
    badgeType: 'primary',
    badgeValue: 'new',
    active: false,
    children: [
      { path: '/shop/config/payment-gateways', title: 'Payment Gateways', type: 'link' },
      { path: '/shop/config/taxes', title: 'Taxes', type: 'link' },
      { path: '/shop/config/shipping', title: 'Shipping', type: 'link' },
    ],
  },
  {
    // path: '/seo/dashboard', title: 'Dashboard', icon: 'panel', sidebartitle: 'SEO', active: false, type: 'link',
    path: 'https://search.google.com/search-console/performance/search-analytics?resource_id=sc-domain%3Aboxpressd.app&num_of_days=28', title: 'View Performance', icon: 'panel', sidebartitle: 'SEO', active: false, type: 'link',
  },
  {
    path: '/seo/sitemap',
    title: 'Update Sitemaps',
    iconType: 'component',
    icon: 'FiRefreshCw',
    type: 'action',
    onClick: () => {
      const types = ['articles', 'news', 'brands', 'cigars', 'products', 'venues', 'groups', 'events', 'users', 'checkin_videos', 'session_videos'];
      const promises = [];
      // TODO Handle paging - products, for one, have multiple sitemaps
      // FIXME Maybe add success = [] and failure = [] so we can show a popup with the list of sitemaps and whether they updated or not
      types.forEach((type) => { promises.push(axios.put(`${Constants.apiPath}/sitemaps/${type}/sitemap.xml`)); });
      Promise.all(promises).then(() => { alert('Sitemaps updated!'); }).catch((err) => console.error(err)); // TODO Toast instead?
    },
  },
  {
    path: 'https://blog.boxpressd.io/wp-admin/edit.php', title: 'All Posts', icon: 'layers', sidebartitle: 'Blog', active: false, type: 'link',
  },
  {
    path: 'https://blog.boxpressd.io/wp-admin/post-new.php', title: 'Add Post', icon: 'pencil-alt', active: false, type: 'link',
  },
  {
    path: 'http://status.boxpressd.com', title: 'Status', icon: 'alert', sidebartitle: 'Tools', active: false, type: 'link',
  },
  {
    path: 'https://boxpressd.slack.com/', title: 'Slack', iconType: 'component', icon: 'FiSlack', active: false, type: 'link',
  },
  {
    path: 'https://app.nuclino.com/Boxpressd', title: 'Wiki', icon: 'book', active: false, type: 'link',
  },
  {
    // FIXME Would like their logo
    // path: 'https://vault.boxpressd.io:8200', title: 'Vault', iconType: 'svg', icon: 'vault', active: false, type: 'link'
    path: 'https://vault.boxpressd.io:8200', title: 'Vault', icon: 'unlock', active: false, type: 'link',
  },
  {
    // FIXME Would like their logo
    // path: 'https://app.breezy.hr/app/c/boxpressd/home', title: 'Breezy', iconType: 'svg', icon: 'vault', active: false, type: 'link'
    path: 'https://app.breezy.hr/app/c/boxpressd/home', title: 'Breezy', icon: 'briefcase', active: false, type: 'link',
  },
  {
    title: 'Analytics',
    icon: 'bar-chart',
    type: 'sub',
    badgeType: 'primary',
    badgeValue: 'new',
    active: false,
    children: [
      { path: 'https://insights.hotjar.com', title: 'Hotjar', type: 'link' },
      { path: 'https://app.logrocket.com/xbsriq/boxpressd', title: 'LogRocket', type: 'link' },
      { path: 'https://app.smartlook.com/org/4db92ce087819b41fe0c2d83/project/06f4bf98cec52633cec9535a/dashboard', title: 'Smartlook', type: 'link' },
      { path: 'https://app.livesession.io/app/9c87714b', title: 'LiveSession', type: 'link' },
      { path: 'https://app.segment.com/boxpressd/overview', title: 'Segment', type: 'link' },
      { path: 'https://analytics.amplitude.com/boxpressd', title: 'Amplitude', type: 'link' },
      { path: 'https://app.pendo.io/s/6621173319073792', title: 'Pendo', type: 'link' },
    ],
  },
  {
    title: 'Growth',
    icon: 'stats-up',
    type: 'sub',
    badgeType: 'primary',
    badgeValue: 'new',
    active: false,
    children: [
      { path: 'https://app.onesignal.com/', title: 'OneSignal', type: 'link' },
      { path: 'https://insights.hotjar.com/', title: 'SendGrid', type: 'link' },
      { path: 'https://app.brevo.com/crm/dashboard', title: 'Brevo CRM', type: 'link' },
      { path: 'https://ads.boxpressd.com/', title: 'Ad Server', type: 'link' },
      // { path: 'https://account.postmarkapp.com/servers', title: 'Postmark (Deprecated)', type: 'link' },
    ],
  },
  {
    title: 'Developer',
    icon: 'harddrives',
    type: 'sub',
    badgeType: 'primary',
    badgeValue: 'new',
    active: false,
    children: [
      { path: 'https://console.aws.amazon.com/console/home?region=us-east-1', title: 'AWS', type: 'link' },
      { path: 'https://console.firebase.google.com/', title: 'Firebase', type: 'link' },
      { path: 'https://logtail.com/team/89719/sources', title: 'Logtail', type: 'link' },
      { path: 'https://web.postman.co/collections/4886111-0ce89295-6395-4dff-97bb-fa4502efa46f', title: 'API Docs', type: 'link' },
      { path: 'https://sentry.io/organizations/boxpressd/', title: 'Sentry', type: 'link' },
      { path: 'https://one.newrelic.com', title: 'New Relic', type: 'link' },
      { path: 'https://app.testim.io/', title: 'Testim', type: 'link' },
      { path: 'https://portal.sofy.ai/app/applications/testruns?applicationguid=47ecb38d-7d79-0ba7-670e-ac6050647038&applicationid=37636', title: 'Sofy.ai', type: 'link' },
    ],
  },
  {
    title: 'Finances',
    icon: 'money',
    type: 'sub',
    badgeType: 'primary',
    badgeValue: 'new',
    active: false,
    children: [
      { path: 'https://billing.boxpressd.io/', title: 'Lago Billing', type: 'link' },
      { path: 'https://banknovo.com/', title: 'Novo', type: 'link' },
      { path: 'https://waveapps.com', title: 'Wave', type: 'link' },
      { path: 'https://pcivault.io/', title: 'PCI Vault', type: 'link' },
      { path: 'https://www.paypal.com', title: 'PayPal', type: 'link' },
      { path: 'https://www.authorize.net/', title: 'Authorize.Net', type: 'link' },
      { path: 'https://give.cornerstone.cc/dashboard', title: 'Cornerstone Gateway', type: 'link' },
      { path: 'https://pay.cornerstone.cc/boxpressd', title: 'Cornerstone Payments', type: 'link' },
      { path: 'https://cps.transactiongateway.com/merchants/index.php?tid=2f5cf1a9280486b2911aa7037d674906', title: 'NMI', type: 'link' },
    ],
  },
  {
    title: 'User Support',
    icon: 'headphone-alt',
    type: 'sub',
    badgeType: 'primary',
    badgeValue: 'new',
    active: false,
    children: [
      { path: 'https://lounge.boxpressd.com', title: 'Virtual Lounge', type: 'link' },
      { path: 'https://boxpressd.gorgias.com/app', title: 'Gorgias Help Desk', type: 'link' },
      { path: 'https://www.tidio.com/panel/dashboard', title: 'Tidio Chat', type: 'link' },
      { path: 'https://boxpressd.freshdesk.com/a/dashboard/default', title: 'Freshdesk (Business FAQ)', type: 'link' },
    ],
  },
];
