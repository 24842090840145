import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import cellEditFactory from 'react-bootstrap-table2-editor';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import Breadcrumb from '../../components/common/breadcrumb.component';
import { connect } from 'react-redux';
import { getShippingRates } from '../../redux/actions/shop.actions';
import { Card, Modal, UncontrolledTooltip } from 'reactstrap';
import { isMobile } from 'react-device-detect';
import RowMoreOptions from '../../components/table/row-more-options';
import '../../assets/css/linked-cigars.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import SweetAlert from 'react-bootstrap-sweetalert';
import TableLoader from '../../components/table-loader';
import NoDataIndicator from '../../components/no-data-indicator';
import Button from '@material-ui/core/Button';
import Icon from '../../components/icon';
import axios from 'axios';
import { Constants } from '../../constants';
import ModalDialog from '../../components/ModalDialog';

const { SearchBar } = Search;

const defaultSorted = [{
    dataField: 'name',
    order: 'asc'
}];

const cellEditProps = {
    mode: 'dbclick'
};

const defaults = {
    message: {
        type: 'info',
        show: false,
        title: '',
        text: ''
    },
};

class ShippingTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            // data: data.data,
            // totalSize: data.recordsFiltered,
            sizePerPage: 10,
            showCreateShippingModal: false,
            ...defaults
        };
        this.handleTableChange = this.handleTableChange.bind(this);
    }

    componentDidMount() {
        this.handleTableChange(null, { page: 1, sizePerPage: 30 });

    }

    handleChange = (key) => {
        return (e) => {
            this.setState({[key]: e.target.value});
        }
    };

    handleEditorStateChange = (editorState) => {
        this.setState({
            editorState,
        });
    };

    handleInputChange = (key) => {
        return (event) => {
            console.log(key);
            console.log(event.target.value);
            this.setState({[key]: event.target.value});
        }
    };

    handleAddressChange = (key) => {
        return (event) => {
            console.log(key);
            console.log(event.target.value);
            // FIXME Should update locally - not in the actual Snipcart store
            // if (this.state.editingShippingAddress) {
            //     this.setState({
            //         shippingAddress: {
            //             ...this.state.shippingAddress,
            //             [key]: event.target.value
            //         }
            //     });
            // } else {
            //     this.setState({
            //         billingAddress: {
            //             ...this.state.billingAddress,
            //             [key]: event.target.value
            //         }
            //     });
            // }
        }
    };

    handleSelectChange = (key) => {
        return (value) => {
            this.setState({[key]: value});
        }
    };

    handleCheckboxChange = (key) => {
        console.log('Check change');
        return (event) => {
            this.setState({[key]: event.target.checked});
        }
    };

    handleRadioChange = (key) => {
        return (event) => {
            this.setState({[key]: event.target.value});
        }
    };

    handleLinkedCigarChange = (index, type) => {
        let linkedCigars = this.state.linkedCigars;
        console.log(linkedCigars);
        let linkedCigar = linkedCigars[index] || {};
        return (value) => {
            linkedCigar[type] = value;
            linkedCigars[index] = linkedCigar;
            this.setState({ linkedCigars }, () => {
                console.log('State updated!');
                if (type === 'cigar') {
                    console.log('Running this.loadVitolas(index)...');
                    this.loadVitolas(index);
                }
            });
        }
    };

    keyForType = (type) => {
        switch (type) {
            case 'shipping':
                return 'showCreateShippingModal';
            default:
                return '';
        }
    };

    onCloseModal = (type) => {
        this.setState({
            [this.keyForType(type)]: false,
        });
    };

    onOpenModal = (type, row) => {
        this.setState({ [this.keyForType(type)]: true });
    };

    onSaveModal = (type) => {
        switch (type) {
            case 'shipping': {
                const { name, guaranteedDays, minWeight, maxWeight, price } = this.state;
                axios.post(`${Constants.apiPath}/shop/shipping`, {
                    name,
                    guaranteed_days: guaranteedDays,
                }).then((response) => {
                    // TODO This should be a loop or bulk POST - for now, our rates cover all weights so not required yet
                    axios.post(`${Constants.apiPath}/shop/shipping/rates`, {
                        shipping_method_id: response.data.id,
                        min_weight: minWeight,
                        max_weight: maxWeight,
                        price,
                    }).then(() => {
                        this.onCloseModal('shipping');
                        // TODO Reset the defaults
                    }).catch((err) => {
                        console.log(err);
                    });
                }).catch((err) => {
                    console.log(err);
                });
            }
                break;
            default:
                this.onCloseModal(type);
                break;
        }
    };

    itemFormatter = (items, row) => {
        if (items) {
            return (
              <label>
                  {items.map((item) => {
                      return <p>{`${item.quantity}x ${item.name}`}</p>;
                  })}
              </label>
            );
        }
        return null;
    };

    itemQuantityFormatter = (items, row) => {
        if (items) {
            return (
              <label>{items.length}</label>
            );
        }
        return null;
    };

    priceFormatter = (cell, row) => {
        if (cell) {
            let price = cell;
            if (typeof price === 'number' || (typeof price === 'string' && price.indexOf('$') === -1)) {
                // FIXME This should be fixed on the server, but since the dev db and prod db aren't configured the same, we need this. Once configured correctly, remove this
                price = '$' + parseFloat(cell).toFixed(2);
            }
            return (
              <label>{price}</label>
            );
        }
        return null;
    };

    checkBoxFormatter = (cell, row) => {
        return (
          <div className="checkbox checkbox-secondary">
              <input name='checkbox' checked={cell} type="checkbox"/>
              <label></label>
          </div>
        );
    };

    actionDropdown = (index, product) => (
      <RowMoreOptions row={index} items={[{
          type: 'MenuItem',
          onClick: () => {},
          title: 'TODO'
      }]}
      onShow={() => {
          // Hide the row buttons
          document.querySelector(`#dropdown-actions-${index}`).classList.add('d-none');
      }}/>
    );

    deliveryFormatter = (cell, row, index) => {
        if (cell) {
            return (
              <span>{cell} days</span>
            );
        }
        return 'N/A';
    };

    locationFormatter = (cell, row, index) => {
        return (
          <span>{'All'}</span>
        );
    };

    rateFormatter = (cell, row, index) => {
        return (
          <span>
              <ul>
                  {/* TODO Include the min and max weights if included */}
                  {cell.map((rate) => (
                      <li>${rate.price}</li>
                  ))}
              </ul>
          </span>
        );
    };

    editBtnFormatter = (cell, row, index) => {
        console.log("Index rendered: " + index);
        return (
          <div style={{ width: 68, float: 'right' }}>
              <div id={`dropdown-actions-${index}`} className='d-none'>
                  <i className='icon-pencil ml-1 mr-3' style={{cursor: 'pointer'}} onClick={() => {}}/>
                  {this.actionDropdown(index, row)}
              </div>
          </div>
        );
    };

    handleTableChange = (type, { page, sizePerPage, filters, sortField, sortOrder, cellEdit, searchText }) => {
        console.log("Updating table...");
        this.props.getShippingRates(page, sizePerPage, {
            // TODO Filter options
        });

        this.setState({
            page,
            sizePerPage
        });
    };

    closeAlert = () => {
        this.setState({
            message: {
                show: false,
                title: '',
                text: ''
            }
        });
    };

    newShippingModal = () => {
        // FIXME Price should probably be in a rates[] array - [{min_weight, max_weight, price...}]
        const { name, guaranteedDays, price } = this.state;
        return (
          <Modal isOpen={this.state.showCreateShippingModal} onClosed={() => this.onCloseModal('shipping')}>
              <div className="modal-header">
                  <h5 className="modal-title">Create New Shipping Method</h5>
              </div>
              <div className="modal-body">
                  <Card style={{ padding: 10 }}>
                      <h5>Rate Name &amp; Delivery Time</h5>
                      <hr />
                      <span style={{fontWeight: 700}}>Name</span>
                      <div style={{marginTop: 10, marginBottom: 10}}>
                          <input
                            className="form-control"
                            value={name}
                            style={{ textTransform: 'capitalize' }}
                            onChange={this.handleChange('name')}
                          />
                      </div>
                      <span style={{fontWeight: 700}}>Guaranteed days for delivery (optional)</span>
                      <div style={{marginTop: 10, marginBottom: 10}}>
                          <input
                            className="form-control"
                            type="number"
                            value={guaranteedDays}
                            onChange={this.handleChange('guaranteedDays')}
                          />
                      </div>
                  </Card>
                  <Card style={{ padding: 10 }}>
                      <div>
                          <h5 style={{ display: 'inline' }}>Rates</h5>
                          <span
                            style={{ color: '#d6c290', cursor: 'pointer', float: 'right' }}
                            onClick={() => {
                                ModalDialog.show({
                                    title: 'Coming soon',
                                    message: 'Right now, rates from M&D include all weights'
                                });
                            }}
                          >
                            {'Add Rate'}
                          </span>
                      </div>
                      <hr />
                      <div className="row" style={{ marginTop: 10, marginBottom: 5 }}>
                          {/* FIXME I think Google Shopping format is in grams - but most Americans will know lbs, we should manage the conversion */}
                          <div className="col">
                              From weight (grams)
                          </div>
                          <div className="col">
                              To weight (grams)
                          </div>
                          <div className="col">
                              Price
                          </div>
                          <div style={{ width: 60 }} />
                      </div>
                      {/* TODO Dynamically add more rows */}
                      <div className="row" style={{ marginBottom: 10 }}>
                          <div className="col">
                              <input
                                className="form-control"
                                type="number"
                                // TODO Implement this
                                // value={address.city}
                                // style={{textTransform: 'capitalize'}}
                                // onChange={this.handleAddressChange('firstName')}
                              />
                          </div>
                          <div className="col">
                              <input
                                className="form-control"
                                type="number"
                                // TODO Implement this
                                // value={address.city}
                                // style={{textTransform: 'capitalize'}}
                                // onChange={this.handleAddressChange('firstName')}
                              />
                          </div>
                          <div className="col">
                              <input
                                className="form-control"
                                type="number"
                                value={price} // FIXME This should be an array eventually
                                onChange={this.handleChange('price')}
                              />
                          </div>
                          <div style={{ width: 60 }}>
                              <Icon name="trash" />
                          </div>
                      </div>
                  </Card>
              </div>
              <div className="modal-footer">
                  <Button onClick={() => this.onCloseModal('shipping')}>Cancel</Button>
                  <Button
                    onClick={() => this.onSaveModal('shipping')}
                    variant="contained"
                    color="secondary"
                  >
                      {'Create'}
                  </Button>
              </div>
          </Modal>
        )
    };

    render() {
        const { sizePerPage, page } = this.state;
        const { shippingRates, totalShippingRateSize, loadingShippingRates, error } = this.props.ShopManager;

        console.log(loadingShippingRates ? 'Loading shipping rates...' : 'Finished loading shipping rates.');

        const columns = [{
            dataField: 'name',
            text: 'Name',
            sort: true,
            editable: false
        },{
            dataField: 'guaranteed_days',
            text: 'Delivery Time',
            sort: true,
            editable: false,
            formatter: this.deliveryFormatter,
        },{
            dataField: '',
            text: 'Location',
            sort: true,
            editable: false,
            formatter: this.locationFormatter,
        },{
            dataField: 'rates',
            text: 'Rates',
            sort: true,
            editable: false,
            formatter: this.rateFormatter,
        },{
            dataField: 'edit',
            text: '',
            formatter: this.editBtnFormatter,
            sort: true
        }];

        const rowEvents = {
            onMouseEnter: (e, row, index) => {
                // console.log("Mouse entered: " + index);
                document.querySelector(`#dropdown-actions-${index}`).classList.remove('d-none');
            },
            onMouseLeave: (e, row, index) => {
                document.querySelector(`#dropdown-actions-${index}`).classList.add('d-none');
            },
            onDoubleClick: (e, row, index) => {
                // INFO If we don't include this event, the double click to edit doesn't work
                // console.log(e);
            }
        };


        const pageButtonRenderer = ({page, active, onPageChange}) => {
            const handleClick = (e) => {
                e.preventDefault();
                onPageChange(page);
            };
            var classname = 'btn btn-outline-secondary';
            if (active) {
                classname = 'btn btn-secondary';
            }
            return (
              <li className="page-item pl-1" key={page}>
                  <a href="#" onClick={ handleClick } className={ classname }>{ page }</a>
              </li>
            );
        };

        // TODO https://www.npmjs.com/package/react-device-detect use a different layout for mobile
        return (
          <div>
              <SweetAlert
                show={this.state.message.show}
                type={this.state.message.type}
                title={this.state.message.title}
                onConfirm={this.closeAlert}
              >
                  {this.state.message.text}
              </SweetAlert>

              <Breadcrumb title="Shipping Methods" label="Shipping Config" parent="Shop" />

              <div className="container-fluid">
                  <div className="row">
                      <div className="col-sm-12">
                          <div className="card mb-0">
                              <div className="card-body datatable-react">
                                  <ToolkitProvider
                                    keyField="id"
                                    data={ shippingRates }
                                    columns={ columns }
                                    search
                                  >
                                      {
                                          toolkitprops => (
                                            <div >
                                                {/*Known issue https://github.com/react-bootstrap-table/react-bootstrap-table2/issues/787*/}
                                                <SearchBar { ...toolkitprops.searchProps } delay={800} />
                                                {/*<ClearSearchButton { ...props.searchProps } />*/}
                                                <button
                                                  className="btn btn-primary mr-2"
                                                  onClick={() => {
                                                      this.onOpenModal('shipping');
                                                  }}
                                                  style={isMobile ? {padding: '6px 14px', float: 'right'} : {float: 'right'}}>
                                                    {isMobile ? (<i className='icon icon-plus'/>) : 'New Shipping Method'}
                                                </button>
                                                <BootstrapTable
                                                  remote
                                                  keyField="id"
                                                  data={ shippingRates }
                                                  columns={ columns }
                                                  rowEvents={ rowEvents }
                                                  defaultSorted={ defaultSorted }
                                                  wrapperClasses="table-responsive"
                                                  pagination={ paginationFactory({ pageButtonRenderer, page, sizePerPage, totalShippingRateSize }) }
                                                  cellEdit={ cellEditFactory(cellEditProps) }
                                                  // filter={ filterFactory() }
                                                  onTableChange={ this.handleTableChange }
                                                  noDataIndication={ () => (!loadingShippingRates ? (error ? <NoDataIndicator message={`${error.message}. Please contact an admin if the problem persists.`} /> : <NoDataIndicator message={'No shipping methods found.'} />) : <TableLoader />) }
                                                  { ...toolkitprops.baseProps }
                                                />
                                            </div>
                                          )
                                      }
                                  </ToolkitProvider>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
              {this.newShippingModal()}
          </div>
        );
    }
}

const mapStateToProps = ({ShopManager}) => {
    return { ShopManager };
};

export default connect(
  mapStateToProps,{
      getShippingRates,
  }
)(ShippingTable);
