import {
  GET_ALL_VENUES,
  SEARCH_VENUES,
  POST_VENUE,
  PUT_VENUE,
  GET_CLAIMS,
  GET_PENDING_VENUES,
} from './actionTypes';
import { Constants } from '../../constants';
import axios from 'axios';

export const getAllVenues = (page, limit, query, filters = {}) => dispatch => {
  axios.get(`${Constants.apiPath}/venues/datatables/all`, {
    params: {
      page,
      limit,
      q: query,
      ...filters,
    },
  })
    .then((res) => {
      console.log(res);
      // console.log("Total records:");
      // console.log(res.data.recordsFiltered);
      dispatch({
        type: GET_ALL_VENUES,
        payload: {
          data: res.data.data,
          totalSize: res.data.recordsFiltered,
        },
      });
    }).catch((e) => {
    console.log(e);
  });
};

export const searchVenues = (query) => dispatch => {
  axios.get(`${Constants.apiPath}/venues/datatables/all`, {
    params: {
      q: query,
    },
  }).then((res) => {
    console.log(res);
    dispatch({
      type: SEARCH_VENUES,
      payload: {
        data: res.data.data,
      },
    });
  }).catch((e) => {
    console.log(e);
  });
};

export const postVenue = (venue) => dispatch => {
  return new Promise((resolve, reject) => {
    axios.post(`${Constants.apiPath}/venues`, venue).then((res) => {
      dispatch({
        type: POST_VENUE,
        payload: {
          data: res.data,
        },
      });
      resolve(res.data);
    }).catch((e) => {
      console.log(e);
      reject(e);
    });
  });
};

export const putVenue = (venue) => dispatch => {
  return new Promise((resolve, reject) => {
    axios.put(`${Constants.apiPath}/venues/${venue.id}`, venue).then((res) => {
      dispatch({
        type: PUT_VENUE,
        payload: {
          data: res.data,
        },
      });
      console.log('Updated venue on server:');
      console.log(res.data);
      // FIXME This should get fixed on the server
      if (Array.isArray(res.data)) {
        resolve(res.data[1][0]);
      } else {
        resolve(res.data);
      }
    }).catch((e) => {
      console.log(e);
      reject(e);
    });
  });
};

export const getPendingVenues = (page, limit, query, venueId) => dispatch => {
  let venueUrl = `${Constants.apiPath}/venues/datatables/pending`;

  if (venueId) {
    venueUrl = `${Constants.apiPath}/venues/datatables/pending/${venueId}`;
  }

  console.log(venueUrl);
  axios.get(venueUrl, {
    params: {
      page,
      limit,
    },
  }).then((res) => {
    dispatch({
      type: GET_PENDING_VENUES,
      payload: {
        loading: false,
        data: res.data.data,
        totalSize: res.data.recordsFiltered,
      },
    });
  }).catch((e) => {
    console.log(e.message);
  });
};

export const getPendingClaims = (page, limit, query = null, venueId = null) => dispatch => {
  console.log('Getting pending claims...');
  let venueUrl = `${Constants.apiPath}/venues/datatables/claims`;

  if (venueId) {
    venueUrl = `${Constants.apiPath}/venues/datatables/claims/${venueId}`;
  }

  console.log(venueUrl);
  axios.get(venueUrl, {
    params: {
      page,
      limit,
    },
  }).then((res) => {
    console.log('Venue claim response:');
    console.log(res);
    dispatch({
      type: GET_CLAIMS,
      payload: {
        loading: false,
        data: res.data,
        totalSize: res.data.recordsFiltered,
      },
    });
  }).catch((e) => {
    console.log('Venue claim error:');
    console.log(e.message);
    dispatch({
      type: GET_CLAIMS,
      payload: {
        loading: false,
      },
    });
  });
};
