import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
  PaginationTotalStandalone,
  SizePerPageDropdownStandalone,
} from 'react-bootstrap-table2-paginator';
import cellEditFactory from 'react-bootstrap-table2-editor';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import Autocomplete from 'react-google-autocomplete';
import { connect } from 'react-redux';
import {
  Modal,
  Input,
  Label,
  Tooltip,
  Card,
  Spinner,
  FormGroup,
  ModalFooter,
  Col,
  Row,
  ModalBody,
  InputGroupAddon, InputGroupText, InputGroup,
} from 'reactstrap';
import Select from 'react-select';
import AsyncCreatableSelect from 'react-select/async-creatable/dist/react-select.esm';
import { isMobile } from 'react-device-detect';
import { DropzoneComponent as Dropzone } from 'react-dropzone-component';
import SweetAlert from 'react-bootstrap-sweetalert';
import AsyncSelect from 'react-select/async/dist/react-select.esm';
import axios from 'axios';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Switch from '@material-ui/core/Switch';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  DateTimePicker,
} from '@material-ui/pickers';
import Button from '../../components/overrides/button';
import NoDataIndicator from '../../components/no-data-indicator';
import TableLoader from '../../components/table-loader';
import Icon from '../../components/icon';
import ModalDialog from '../../components/ModalDialog';
import { canadianProvinces, countries, usStates } from '../../utils/geographyUtils';
import RowMoreOptions from '../../components/table/row-more-options';
import { Constants } from '../../constants';
import {
  getAllVenues,
  postVenue,
  putVenue, searchVenues,
} from '../../redux/actions/venue.actions';
import Breadcrumb from '../../components/common/breadcrumb.component';
import './styles.scss';
import Toast from 'cogo-toast';
import Pagination from '../../components/ui/base/pagination';
import TouchSpin from '../../components/touch-spin';
import DebounceSearchBar from '../../components/DebounceSearchBar';
import MediaCropper from '../../components/cropper';
import Hashids from 'hashids';
import FancyBox from 'react-fancybox';
const ReactDOMServer = require('react-dom/server');
const hashids = new Hashids('', 12);

const env = process.env.NODE_ENV || 'development';
const config = require('../../config/config.json')[env];

const defaultSorted = [{
  dataField: 'name',
  order: 'asc',
}];

const cellEditProps = {
  mode: 'dbclick',
};

const date = new Date();
let dateMonth = date.getMonth() + 1;
let dateDay = date.getDate();
let dateDayNext = date.getDate() + 1;

if (dateMonth < 10) {
  dateMonth = `0${dateMonth}`;
}
if (dateDay < 10) {
  dateDay = `0${dateDay}`;
}
if (dateDayNext < 10) {
  dateDayNext = `0${dateDayNext}`;
}

const dateTomorrow = String(`${date.getFullYear()}-${dateMonth}-${dateDayNext}`);
const dateToday = String(`${date.getFullYear()}-${dateMonth}-${dateDay}`);
const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

const defaults = {
  selectedId: null,
  selectedName: '',
  selectedDescription: null,
  selectedWebsite: '',
  selectedAddress: null,
  selectedPhone: '',
  selectedEmail: '',
  selectedYelpId: '',
  selectedFoursquareId: '',
  selectedGoogleId: '',
  selectedTwitterId: '',
  selectedFacebookId: '',
  selectedInstagramId: '',
  selectedCanBuy: '',
  selectedCanBuyOnline: '',
  selectedCanSmoke: '',
  selectedTags: '',
  selectedVenueImages: [],
  selectedImagesToAttach: [],
  selectedImagesToRemove: [],
  selectedForceMerge: false,
  selectedHoursType: 'custom',
  selectedMonday: false,
  selectedTuesday: false,
  selectedWednesday: false,
  selectedThursday: false,
  selectedFriday: false,
  selectedSaturday: false,
  selectedSunday: false,
  selectedHours: [
    // {open: {day:0, time: 900, hour: 9, min: 0}}
    {}, {}, {}, {}, {}, {}, {},
  ],
  selectedEvent: {},
  selectedStartDate: `${dateToday}T12:00:00`,
  selectedEndDate: `${dateToday}T12:00:00`,
  active: false,
  dialogModal: {
    show: false,
    title: '',
    text: '',
  },
  mergeVenue: '',
  merging: false,
  venueLogo: undefined,
  venueCoverImage: undefined,
  virtualTourEmbedCode: null,
};

const defaultAmenities = [
  'Accepts Credit Cards',
  'Accepts Cryptocurrency',
  'Free WiFi',
  'Parking - Private Lot',
  'Parking - On Street',
  'Parking - Valet',
  'Walk-In Humidor',
  'Lounge',
  'Alcohol - All',
  'Alcohol - Beer & Wine Only',
  'Alcohol - Beer Only',
  'Kitchen',
  'Outdoor Seating',
  'Military Discounts',
  'Wheelchair Accessible',
  'Pool Tables',
  'TV',
];

const hourTimeValues = () => {
  const x = 5; // minutes interval
  const times = []; // time array
  let tt = 0; // start time
  const ap = ['AM', 'PM']; // AM-PM

  // loop to increment the time and push results in array
  for (let i = 0; tt < 24 * 60; i++) {
    const hh = Math.floor(tt / 60); // getting hours of day in 0-24 format
    let h = hh % 12;
    if (h < 1) {
      h = 12;
    }
    const mm = (tt % 60); // getting minutes of the hour in 0-55 format
    const time = `${h}:${(`0${mm}`).slice(-2)} ${ap[Math.floor(hh / 12)]}`; // pushing data in array in [00:00 - 12:00 AM/PM format]
    times[i] = {
      value: time,
      label: time,
    };
    tt += x;
  }
  // console.log(times);

  return times;
};

const hourTimeDefaults = hourTimeValues();

const djsPreviewTemplate = ReactDOMServer.renderToStaticMarkup(
  <div className="dz-preview dz-file-preview">
    <div className="dz-details">
      {/* <div className="dz-filename"><span data-dz-name="true"></span></div> */}
      <img
        style={{
          maxWidth: 116,
          maxHeight: 106,
        }}
        data-dz-thumbnail="true"
      />
    </div>
    {/* <div className="dz-progress"><span className="dz-upload" data-dz-uploadprogress="true"></span></div> */}
    {/* <div className="dz-success-mark"><span>✔</span></div> */}
    {/* <div className="dz-error-mark"><span>✘</span></div> */}
    {/* <div className="dz-error-message"><span data-dz-errormessage="true"></span></div> */}
  </div>,
);

class VenueTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      venues: [],
      page: 1,
      // data: data.data,
      // totalSize: data.recordsFiltered,
      sizePerPage: 10,
      showPagePickerModal: false,
      showVenueModal: false,
      showMergeModal: false,
      showOwnerModal: false,
      showHoursModal: false,
      showEventsModal: false,
      showImageUrlModal: false,
      showFilterDrawer: false,
      showMissingPrice: false,
      showPossibleDuplicates: false,
      selectedCity: null,
      selectedState: null,
      selectedCountry: null,
      message: {
        type: 'info',
        show: false,
        title: '',
        text: '',
      },
      ...defaults,
    };
    this.handleTableChange = this.handleTableChange.bind(this);
    this.toggleTooltip = this.toggleTooltip.bind(this);
  }

  componentWillReceiveProps(nextProps, nextContext) {
    const { data } = nextProps.VenueManager;
    this.setState({ venues: data });
  }

  // FIXME Make this function reusable
  uploadMedia = (blob, extension, type) => {
    const formData = new FormData();

    // INFO Must be named 'media' to work with our server
    const venueHash = hashids.encode(this.state.selectedId ? this.state.selectedId : new Date().getTime());
    formData.append('media', blob, `${venueHash}-${type}.${extension}`);
    console.log(`${venueHash}-${type}.${extension}`);

    console.log('Uploading to media server...');
    console.log('Uploading media to server...');

    const mediaInstance = axios.create();
    mediaInstance.defaults.headers.common = {
      'Content-Type': 'multipart/form-data',
      Accept: 'text/html',
    };
    this.setState({ uploadingMedia: true });
    mediaInstance.post(`${config.mediaEndPoint}/upload?type=venues`, formData).then((res) => {
      console.log('Uploaded to media server!');
      console.log(res.data);
      // Sentry.setExtra('uploadMedia_res_data', JSON.stringify(res.data));
      // setUploadingMedia(false);
      this.setState({
        uploadingMedia: false,
        [type]: res.data.media_url,
      });
    }).catch((err) => {
      this.setState({ uploadingMedia: false });
      // setUploadingMedia(false);
      // ErrorLogger.captureException(err);
      console.log(err);
    });
  };

  toggleTooltip(key) {
    return () => {
      this.setState({
        [key]: !this.state[key],
      });
    };
  }

  handleChange = (key) => (value) => {
    this.setState({ [key]: value });
  };

  handleInputChange = (key) => (event) => {
    console.log(key);
    console.log(event.target.value);
    this.setState({ [key]: event.target.value });
  };

  handleSelectChange = (key) => (value) => {
    if (key === 'selectedCountry') {
      this.setState({ selectedState: null });
    }
    this.setState({ [key]: value });
  };

  handleCheckboxChange = (key) => (event) => {
    this.setState({ [key]: event.target.checked });
  };

  handleRadioChange = (key) => (event) => {
    this.setState({ [key]: event.target.value });
  };

  handleDateChange = (field) => (newDate) => {
    console.log(newDate);
    this.setState({
      [field]: newDate,
    });
  };

  handlePageChange = (e, index) => {
    this.setState({
      page: index,
    });
    this.props.getAllVenues(index + 1, this.state.sizePerPage);
  };

  loadVenues = (inputValue, callback) => {
    this.props.searchVenues(inputValue);
    setTimeout(() => { // FIXME How do we wait or listen for the change instead? UPDATE This can be done with hooks / functional component useEffect
      callback(this.props.VenueManager.venueSearch.map((venue) => ({
        value: venue.id,
        label: venue.name,
      })));
    }, 500);
  };

  onHoursFocusChange = (index) => () => {
    switch (index) {
      case 0: { // INFO Starts on Sunday from Google
        this.setState({
          selectedSunday: true,
        });
        break;
      }
      case 1: {
        this.setState({
          selectedMonday: true,
        });
        break;
      }
      case 2: {
        this.setState({
          selectedTuesday: true,
        });
        break;
      }
      case 3: {
        this.setState({
          selectedWednesday: true,
        });
        break;
      }
      case 4: {
        this.setState({
          selectedThursday: true,
        });
        break;
      }
      case 5: {
        this.setState({
          selectedFriday: true,
        });
        break;
      }
      case 6: {
        this.setState({
          selectedSaturday: true,
        });
        break;
      }
    }
  };

  dropzoneEventHandlers(holder) {
    const self = this;
    console.log(holder);
    return {
      addRemoveLinks: true,
      accept(file, done) {
        console.log('File:');
        console.log(file);
        console.log('uploaded');
        done();
      },
      init: (dropzone) => {
        // Dropzone.autoDiscover = false;
        console.log('Initialized dropzone');
        console.log(dropzone);
        console.log(this.state);
        const image = this.state[holder];
        if (image) {
          const imageFile = {
            name: image,
            size: 0,
          };
          dropzone.emit('addedfile', imageFile);
          dropzone.emit('thumbnail', imageFile, image);
          dropzone.emit('complete', imageFile);
          dropzone.files.push(imageFile);

          // FIXME This doesn't always hide
          // Hide the message
          setTimeout(() => {
            console.log(dropzone.element);
            const messageDiv = dropzone.element.closest('.dz-message');
            if (messageDiv) {
              messageDiv.style.display = 'none';
            } else {
              dropzone.element.children[0].style.display = 'none';
            }
          }, 500);
        }
      },
      drop: (event) => {
        console.log('Dropped event:');
        console.log(event);
        console.log(event.target);
        event.target.style.display = 'none';
        const messageDiv = this[holder].dropzone.element.closest('.dz-message');
        if (messageDiv) {
          messageDiv.style.display = 'none';
        } else {
          this[holder].dropzone.element.children[0].style.display = 'none';
        }
        this.setState({
          activeDropzone: this[holder],
          activeDropzoneId: holder,
        });
      },
      addedfile(file) {
        console.log('Dropzone files:');
        console.log(this.files);

        console.log('Added file');
        console.log(file);

        if (this.files.length > 0) {
          if (this.files[0]) {
            const { status } = JSON.parse(JSON.stringify(this.files[0]));
            console.log('Status: ', status);
            if (status !== 'queued' && status !== 'added') {
              console.log('Deleting file...');
              this.removeFile(this.files[0]);
            }
          }
        }

        if (file && this.files.length === 1) {
          console.log(file);
          console.log('Loading file...');
          const fileReader = new FileReader();
          fileReader.onload = (event) => {
            console.log('Loaded file.');
            const dataUrl = event.target.result;
            console.log(dataUrl);
            self.setState({
              selectedImageFile: dataUrl,
              showMediaCropper: true,
            });
          };
          fileReader.readAsDataURL(file);
        }
      },
      success: (file, response, error) => {
        console.log(response);
      },
    };
  }

  keyForType = (type) => {
    switch (type) {
      case 'venue':
        return 'showVenueModal';
      case 'merge':
        return 'showMergeModal';
      case 'owner':
        return 'showOwnerModal';
      case 'hours':
        return 'showHoursModal';
      case 'event':
        return 'showEventsModal';
      case 'imageurl':
        return 'showImageUrlModal';
      case 'filter':
        return 'showFilterDrawer';
      case 'page':
        return 'showPagePickerModal';
      default:
        return '';
    }
  };

  onOpenModal = (type, row) => {
    if (type === 'venue') {
      if (row) {
        console.log(JSON.stringify(row));

        let address = null;

        if (row.location) {
          const { location } = row;
          address = {
            street: location.address1 || null,
            city: location.city || null,
            state: location.state || null,
            zip: location.zip || null,
            country: location.country || null,
            latitude: location.coordinate !== null ? location.coordinate.latitude : null,
            longitude: location.coordinate !== null ? location.coordinate.longitude : null,
          };
        }

        this.setState({
          selectedId: row.id ? row.id : null,
          selectedVenueImages: row.images,
          selectedName: row.name || '',
          selectedDescription: row.description || '',
          selectedWebsite: row.website || '',
          selectedAddress: address,
          selectedEmail: row.email || '',
          selectedPhone: row.phone || '',
          selectedYelpId: row.yelp_id || '',
          selectedFoursquareId: row.foursquare_id || '',
          selectedGoogleId: row.place_id || row.google_id || '',
          selectedTwitterId: row.twitter_id || '',
          selectedFacebookId: row.facebook_id || '',
          selectedInstagramId: row.instagram_id || '',
          selectedCanBuy: row.can_buy || '',
          selectedCanBuyOnline: row.can_buy_online || '',
          selectedCanSmoke: row.can_smoke || '',
          selectedHours: row.hours.map((hour) => {
            // FIXME If either of these are present, we really don't need any of the others - hopefully they can either be removed from the table or filtered out server-side
            if (hour.permanently_closed || hour.always_open) {
              return hour;
            }
            return ({
              open: {
                day: hour.open_day,
                time: hour.open_time && hour.open_time.toString().padStart(4, '0'),
                hours: hour.open_time && hour.open_time.toString().padStart(4, '0').substring(0, 2),
                minutes: hour.open_time && hour.open_time.toString().padStart(4, '0').substring(2, 4),
              },
              close: {
                day: hour.close_day,
                time: hour.close_time && hour.close_time.toString().padStart(4, '0'),
                hours: hour.close_time && hour.close_time.toString().padStart(4, '0').substring(0, 2),
                minutes: hour.close_time && hour.close_time.toString().padStart(4, '0').substring(2, 4),
              },
            });
          }),
          selectedTags: row.tags.map((venueTag) => ({ value: venueTag.tag.title, label: venueTag.tag.title })),
          virtualTourEmbedIframe: row.virtual_tour_embed, // FIXME These should probably be an array at some point
          active: row.active,
          venueLogo: row.icon_url || row.profile_image_url || undefined,
          venueCoverImage: row.cover_url || row.cover_image_url || undefined,
        });
      }
    } else if (type === 'social') {
      this.setState({
        message: {
          type: 'info',
          show: true,
          title: 'Under Development',
          text: 'This feature isn\'t available yet.',
        },
      });
    } else if (type === 'merge') {
      if (row && row.id) {
        this.setState({ selectedVenue: row, selectedId: row.id });
      }
    }
    this.setState({ [this.keyForType(type)]: true });
  };

  onCloseModal = (type) => {
    if (type === 'venue') {
      this.setState({
        [this.keyForType(type)]: false,
        ...defaults,
      });
    } else if (type === 'hours') {
      // TODO Reset the selectedHours?
      this.setState({
        [this.keyForType(type)]: false,
      });
    } else {
      this.setState({
        [this.keyForType(type)]: false,
      });
    }
  };

  onSaveModal = (type) => {
    if (type === 'venue') {
      console.log('Submitting!');
      console.log(this.state);

      const { selectedTags, selectedHoursType, selectedHours, selectedId, venueLogo, venueCoverImage } = this.state;

      const tags = [];
      if (selectedTags.length) {
        for (let i = 0; i < selectedTags.length; i++) {
          tags.push(selectedTags[i].value);
        }
      }

      let hours = null;
      // let { active } = this.state;
      if (selectedHoursType) {
        hours = [];

        if (selectedHoursType === 'custom') {
          if (selectedHours) {
            console.log('Selected hours:');
            console.log(selectedHours);
            for (const hour of selectedHours) {
              if (hour.open && hour.close) {
                console.log('Adding time slot:');
                console.log(hour);
                // FIXME If only open exists with 0000, what does that mean? Always open? Hours unknown? Throw it out?
                hours.push({
                  open_day: hour.open.day,
                  open_time: hour.open.time && hour.open.time.padStart(4, '0'),
                  close_day: hour.close.day,
                  close_time: hour.close.time && hour.close.time.padStart(4, '0'),
                });
              }
            }
            // active = true;
          }
        } else if (selectedHoursType === 'always_open') {
          hours.push({ always_open: true, permanently_closed: false });
          // active = true;
        } else if (selectedHoursType === 'closed') {
          hours.push({ permanently_closed: true, always_open: false });
          // active = false;
        }
      }

      console.log('Hours:');
      console.log(hours);

      const venue = {
        name: this.state.selectedName,
        description: this.state.selectedDescription,
        website: this.state.selectedWebsite,
        phone: this.state.selectedPhone,
        email: this.state.selectedEmail,
        addr_line_1: this.state.selectedAddress ? this.state.selectedAddress.street : null,
        addr_line_2: null, // TODO
        addr_city: this.state.selectedAddress ? this.state.selectedAddress.city : null,
        addr_state: this.state.selectedAddress ? this.state.selectedAddress.state : null,
        addr_zip: this.state.selectedAddress ? this.state.selectedAddress.zip : null,
        addr_country: this.state.selectedAddress ? this.state.selectedAddress.country : null,
        latitude: this.state.selectedAddress ? this.state.selectedAddress.latitude : null,
        longitude: this.state.selectedAddress ? this.state.selectedAddress.longitude : null,
        yelp_id: this.state.selectedYelpId,
        foursquare_id: this.state.selectedFoursquareId,
        google_id: this.state.selectedGoogleId,
        place_id: this.state.selectedGoogleId, // FIXME This one is deprecated, but include anyway for now
        twitter_id: this.state.selectedTwitterId,
        facebook_id: this.state.selectedFacebookId,
        instagram_id: this.state.selectedInstagramId,
        can_buy: this.state.selectedCanBuy === true,
        can_buy_online: this.state.selectedCanBuyOnline === true,
        can_smoke: this.state.selectedCanSmoke === true,
        virtual_tour_embed: this.state.virtualTourEmbedCode || null,
        verified: true, // FIXME What defines this? Edited?
        custom: false, // TODO Pull from a toggle?
        active: this.state.active === true,
        // FIXME Decide on which to use of these 4
        icon_url: venueLogo || undefined,
        profile_image_url: venueLogo || undefined,
        cover_url: venueCoverImage || undefined,
        cover_image_url: venueCoverImage || undefined,
        hours,
        tags,
      };

      // Prevent empty strings
      Object.keys(venue).forEach(function (key, index) {
        if (this[key] === '') {
          this[key] = null;
        }
      }, venue);

      const {
        page,
        sizePerPage,
        searchText,
        tableChangeType,
      } = this.state;

      if (selectedId) {
        venue.id = selectedId;
        this.props.putVenue(venue).then((updatedVenue) => {
          Toast.success('Venue updated successfully', { heading: updatedVenue.name, position: 'top-right' });
          this.handleTableChange(tableChangeType, { page, sizePerPage, searchText });
        }).catch((err) => {
          console.log(err);
          console.log('Showing error alert...');
          this.setState({
            message: {
              show: true,
              type: 'error',
              title: 'Uh oh!',
              text: 'Unable to update the venue details. Please contact an admin.',
            },
          });
        });

        this.setState({
          [this.keyForType(type)]: false,
          ...defaults,
        });

        this.updateVenueMedia(selectedId);
      } else {
        this.props.postVenue(venue).then((updatedVenue) => {
          Toast.success('Venue added successfully', { heading: updatedVenue.name, position: 'top-right' });

          this.setState({
            [this.keyForType(type)]: false,
            ...defaults,
          });

          this.updateVenueMedia(updatedVenue.id);
          this.handleTableChange(tableChangeType, { page, sizePerPage, searchText });
        }).catch((err) => {
          console.log(err);
          console.log('Showing error alert...');
          this.setState({
            message: {
              show: true,
              type: 'error',
              title: 'Uh oh!',
              text: 'Unable to add the venue details. Please contact an admin.',
            },
          });
        });
      }

      console.log(venue);
    } else if (type === 'imageurl') {
      const urlParts = this.state.selectedImageUrl.split('?')[0].split('/');
      let fileName = urlParts[urlParts.length - 1];
      if (fileName.indexOf('.jpg') === -1 && fileName.indexOf('.jpeg') === -1 && fileName.indexOf('.png') === -1) {
        fileName += '.jpg';
      }
      console.log({
        original_src: this.state.selectedImageUrl,
        filename: fileName,
        prefix: 'venues',
      });
      axios.post(`${Constants.apiPath}/import/media`, {
        original_src: this.state.selectedImageUrl,
        filename: fileName,
        prefix: 'venues', // This is the CDN prefix, e.g. 'products', 'cigars', etc
      }).then((response) => {
        console.log('Image imported successfully');
        console.log(response.data);
        const media = response.data;
        const images = [...this.state.selectedVenueImages];
        const imagesToAdd = [...this.state.selectedImagesToAttach];
        images.push({
          id: media.id,
          url: media.media_url,
        });
        imagesToAdd.push(media.media_url);
        this.setState({
          submitting: false,
          selectedVenueImages: images,
          selectedImagesToAttach: imagesToAdd,
        });
        this.onCloseModal('imageurl');
      }).catch((err) => {
        console.log(err);
        this.setState({
          submitting: false,
        });
        this.onCloseModal('imageurl');
      });
    } else if (type === 'filter') {
      this.onCloseModal('filter');
      this.handleTableChange(null, {
        page: 1,
        sizePerPage: 10,
      });
    } else if (type === 'hours') {
      this.onCloseModal('hours');
    } else if (type === 'merge') {
      const { selectedId } = this.state;
      const mergeDetails = this.state.mergeVenue;
      console.debug(selectedId);
      console.debug(mergeDetails);
      if (mergeDetails) {
        let mergeVenue;
        for (const v of this.props.VenueManager.venueSearch) {
          if (v.id === mergeDetails.value) {
            mergeVenue = v;
            break;
          }
        }
        console.debug(mergeVenue);
        this.setState({ merging: true });
        axios.post(`${Constants.apiPath}/venues/${selectedId}/merge`, mergeVenue).then((response) => {
          this.handleRowDelete({ id: selectedId });
          this.handleRowChange(response.data);
          this.onCloseModal('merge');
          this.setState({ merging: false });
        }).catch((err) => {
          this.setState({
            merging: false,
            message: {
              show: true,
              type: 'error',
              title: 'Uh oh!',
              text: 'Unable to merge the venue details. Please contact an admin.',
            },
          });
        });
      } else {
        this.setState({
          message: {
            show: true,
            type: 'error',
            title: 'Please select a venue to merge into',
            text: 'You must choose a venue from the dropdown to merge the currently selected venue into.',
          },
        });
      }
    }
  };

  imageFormatter = (cell, row) => {
    if (cell) {
      return (
        <div className="circle-thumbnail">
          <FancyBox
            thumbnail={cell}
            image={cell}
          />
        </div>
      );
    }
    return null;
  };

  checkBoxFormatter = (cell, row) => (
    <div className="checkbox checkbox-secondary">
      <input name="checkbox" checked={cell} type="checkbox" />
      <label></label>
    </div>
  );

  activeFormatter = (active, venue) => {
    console.log('Updating active switch state...');
    return (
      <span>
        <Switch
          checked={active}
          onChange={(e) => {
            console.log('Changed active state:');
            console.log(e.target);
            console.log(e.target.checked ? 'Switch is on' : 'Switch is off');
            venue.active = e.target.checked;
            console.log('Updating venue...');
            console.log(venue);
            this.props.putVenue(venue).then((updatedVenue) => {
              this.handleRowChange(updatedVenue);
            });
          }}
          color="secondary"
        />
      </span>
    );
  };

  streetFormatter = (cell, row) => {
    if (cell) {
      return (
        <span>{cell.address1}</span>
      );
    }
    return null;
  };

  cityFormatter = (cell, row) => {
    if (cell) {
      return (
        <span>{cell.city}</span>
      );
    }
    return null;
  };

  yelpLinkFormatter = (cell, row) => {
    if (cell) {
      const href = `https://www.yelp.com/biz/${cell}`;
      return (
        <a href={href} target="_blank"><i style={{ fontSize: 18 }} className="fa fa-external-link ml-2" /></a>
      );
    }
    return null;
  };

  foursquareLinkFormatter = (cell, row) => {
    if (cell) {
      const href = `https://foursquare.com/v/${cell}`;
      return (
        <a href={href} target="_blank"><i style={{ fontSize: 18 }} className="fa fa-external-link ml-2" /></a>
      );
    }
    return null;
  };

  googleLinkFormatter = (cell, row) => {
    if (cell) {
      const href = `https://www.google.com/maps/search/?api=1&query=Google&query_place_id=${cell}`;
      return (
        <a href={href} target="_blank"><i style={{ fontSize: 18 }} className="fa fa-external-link ml-2" /></a>
      );
    }
    return null;
  };

  syncTowerData = (venue) => {
    this.setState({
      message: {
        type: 'info',
        show: true,
        title: 'Under Development',
        text: 'This feature isn\'t available yet.',
      },
    });
  };

  syncTowerImages = (venue) => {
    // TODO Loading indicator?
    console.log(`Syncing images for ${venue.name}`);
    axios.post(`${Constants.apiPath}/venues/sync/${venue.id}/images`)
      .then(() => {
        this.setState({
          message: {
            type: 'success',
            show: true,
            title: 'Success!',
            text: 'The images have been successfully updated.',
          },
        });
        const {
          tableChangeType,
          page,
          sizePerPage,
          searchText,
        } = this.state;
        this.handleTableChange(tableChangeType, {
          page,
          sizePerPage,
          searchText,
        });
      }).catch((err) => {
        console.log(err);
        if (err.response.status) {
          this.setState({
            message: {
              type: 'info',
              show: true,
              title: 'No Images',
              text: 'Unable to get images for this venue. This usually means there either are no owner-added images available or the Google Place ID is not set.',
            },
          });
        } else {
          this.setState({
            message: {
              type: 'danger',
              show: true,
              title: 'Uh oh!',
              text: 'Something went wrong. Please try again later or check the logs.',
            },
          });
        }
      });
  };

  updateVenueMedia = (productId) => {
    const {
      selectedImagesToAttach, // FIXME Technically can be a video down the road, too
      selectedImagesToRemove,
      tableChangeType,
      page,
      sizePerPage,
      searchText,
    } = this.state;

    if (selectedImagesToAttach.length > 0 || selectedImagesToRemove.length > 0) {
      // FIXME Check to see what we need to pass - I think we need to know if it's from Google or a similar site vs user uploaded
      axios.put(`${Constants.apiPath}/venues/${productId}/media`, {
        add: selectedImagesToAttach.map((url) => ({
          url,
          external_id_type: 'google', // TODO Would like a better way to determine this - right now, they will likely all come from Google
        })),
        remove: selectedImagesToRemove,
      }).then((res) => {
        console.log('Added media successfully!');
        this.handleTableChange(tableChangeType, {
          page,
          sizePerPage,
          searchText,
        });
      }).catch((err) => {
        console.log('Unable to add media');
        console.log(err);
      });
    }
  };

  closeAlert = () => {
    this.setState({
      message: {
        show: false,
        title: '',
        text: '',
      },
    });
  };

  deleteVenue = () => {
    this.setState({
      dialogModal: {
        show: true,
        title: 'Are you sure you want to delete?',
        text: 'This will delete the venue and cannot be undone. Note: If users have this venue associated with a check in or smoke session, this will return an error. If that happens, you will need to merge the venue instead.',
      },
    });
  };

  deleteVenueConfirmed = () => {
    // TODO Delete on server
    this.setState({
      message: {
        type: 'info',
        show: true,
        title: 'Under Development',
        text: 'This feature isn\'t available yet.',
      },
    });
  };

  actionDropdown = (index, venue) => (
    <RowMoreOptions
      row={index}
      items={[{
        type: 'MenuItem',
        onClick: () => this.onOpenModal('social', venue),
        title: 'Create social media post',
      }, {
        type: 'Divider',
      }, {
        type: 'MenuItem',
        onClick: () => this.syncTowerData(venue),
        title: 'Sync Google Data',
      }, {
        type: 'MenuItem',
        onClick: () => this.syncTowerImages(venue),
        title: 'Sync Google Images',
      }, {
        type: 'Divider',
      }, {
        type: 'MenuItem',
        onClick: () => this.onOpenModal('merge', venue),
        title: 'Merge',
      }, {
        type: 'MenuItem',
        onClick: () => this.deleteVenue(),
        style: { color: 'red' },
        title: 'Delete',
      }]}
      onShow={() => {
        // Hide the row buttons
        document.querySelector(`#dropdown-actions-${index}`).classList.add('d-none');
      }}
    />
  );

  editBtnFormatter = (cell, row, index) => (
    <div style={{ width: 68 }}>
      <div id={`dropdown-actions-${index}`} className="d-none">
        <Icon
          name="edit2"
          className="ml-1 mr-3"
          style={{
            cursor: 'pointer',
            display: 'inline',
            height: 18,
            width: 18,
          }}
          onClick={() => this.onOpenModal('venue', row)}
        />
        {this.actionDropdown(index, row)}
      </div>
    </div>
  );

  handleRowChange = (updatedVenue) => {
    const { venues } = this.state;
    console.log(`Updating venue for ${updatedVenue.id}...`);
    const updatedVenues = venues.map((venue) => {
      if (venue.id === updatedVenue.id) {
        return { ...venue, ...updatedVenue };
      }
      return venue;
    });
    this.setState({ venues: updatedVenues });
  };

  handleRowDelete = (deletedVenue) => {
    const { venues } = this.state;
    console.log(`Removing venue for ${deletedVenue.id}...`);
    console.log(deletedVenue);
    let i = venues.length;
    while (i--) {
      const venue = venues[i];
      if (venue.id === deletedVenue.id) {
        venues.splice(i, 1);
      }
    }
    this.setState({ venues });
  };

  handleTableChange = (type, { page, sizePerPage, sortField, sortOrder, cellEdit, searchText }) => {
    const { selectedCity, selectedState, selectedCountry } = this.state;
    console.log('Updating table...');
    let filters;
    if (this.state.showPossibleDuplicates) {
      filters = {
        only_duplicates: true,
      };
    }
    this.props.getAllVenues(page, sizePerPage, searchText, {
      city: selectedCity ? selectedCity.label : null,
      state: selectedState ? selectedState.value : null,
      country: selectedCountry ? selectedCountry.label : null,
      ...filters,
    });

    // FIXME This still doesn't fix the search - they still haven't fixed it (https://github.com/react-bootstrap-table/react-bootstrap-table2/issues/787)
    //  Might have to build it in house - https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html?selectedKind=Table%20Search&selectedStory=Fully%20Custom%20Search&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel
    setTimeout(() => {
      this.setState({
        tableChangeType: type,
        page,
        sizePerPage,
        searchText,
      });
    }, 3000);
  };

  // TODO Simplify this with an array or similar
  setCheckedForIndex(index, checked) {
    return () => { // FIXME Not working, get infinite loop when this return is missing, no change when it's here
      switch (index) {
        case 0: { // INFO Starts on Sunday from Google
          this.setState({
            selectedSunday: checked,
          });
          break;
        }
        case 1: {
          this.setState({
            selectedMonday: checked,
          });
          break;
        }
        case 2: {
          this.setState({
            selectedTuesday: checked,
          });
          break;
        }
        case 3: {
          this.setState({
            selectedWednesday: checked,
          });
          break;
        }
        case 4: {
          this.setState({
            selectedThursday: checked,
          });
          break;
        }
        case 5: {
          this.setState({
            selectedFriday: checked,
          });
          break;
        }
        case 6: {
          this.setState({
            selectedSaturday: checked,
          });
          break;
        }
      }
    };
  }

  formatHoursTime = (index, type) => {
    const { selectedHours } = this.state;
    if (selectedHours) {
      const time = selectedHours[index];
      console.log(time);
      if (time) {
        const data = time[type];
        // console.log('Formatting hours data:');
        // console.log(data);
        if (data) {
          // TODO Can use data.day - should we make sure it matches the index?
          this.setCheckedForIndex(index, true);
          let { hours } = data;
          const suffix = hours >= 12 ? 'PM' : 'AM';
          hours = (hours % 12) || 12;
          const minutes = (data.minutes < 10 ? '0' : '') + data.minutes;
          const value = `${hours}:${minutes.slice(-2)} ${suffix}`;
          return {
            value,
            label: value,
          };
        }
      }
    }
    // FIXME Will this cause an issue when the text is empty?
    // this.setCheckedForIndex(index, false);
    return ''; // FIXME Default
  };

  periodAtIndex = (index) => {
    for (let i = 0; i < this.state.selectedHours.length; i++) {
      const period = this.state.selectedHours[i];
      if (period.open && period.open.day === index) {
        return period;
      }
    }
    return null;
  }

  // FIXME This fails if the business is closed on a given day - it need to keep the day index in the object rather than
  //  get the period based on it's index in the selectedHours array
  formatHoursDisplay = (day, index) => {
    const period = this.periodAtIndex(index);
    if (period) {
      console.log('Found period!');
      console.log(period);
    }
    let openValue = 'Unknown';
    let closedValue = 'Unknown';
    if (period && (period.open || period.close)) {
      if (period.open) {
        let openHours = period.open.hours;
        const openSuffix = openHours >= 12 ? 'PM' : 'AM';
        openHours = (openHours % 12) || 12;
        const openMinutes = (period.open.minutes < 10 ? '0' : '') + period.open.minutes;
        openValue = `${openHours}:${openMinutes.slice(-2)} ${openSuffix}`;
      }

      if (period.close) {
        let closedHours = period.close.hours;
        const closedSuffix = closedHours >= 12 ? 'PM' : 'AM';
        closedHours = (closedHours % 12) || 12;
        const closedMinutes = (period.close.minutes < 10 ? '0' : '') + period.close.minutes;
        closedValue = `${closedHours}:${closedMinutes.slice(-2)} ${closedSuffix}`;
      }
      return (
        <div>
          {day}
          :
          {' '}
          {`${openValue} - ${closedValue}`}
        </div>
      );
    }
    if (!this.state.selectedHours || this.state.selectedHours.length === 0) {
      return (
        <div>
          {day}
          : Unknown
        </div>
      );
    }
    return (
      <div>
        {day}
        : Closed
      </div>
    );
  };

  handleHoursChange = (index, type) => (selection) => {
    console.log('Hours selection:');
    console.log(selection);
    const hours = this.state.selectedHours;

    let time = selection.value; // 9:15 PM
    console.log(time);

    const pm = time.indexOf('PM') !== -1;

    const parts = time.replace(' AM', '').replace(' PM', '').split(':');
    let hour = parts[0];
    const min = parts[1];

    time = parseInt(time.replace(':', '').replace(' AM', '').replace(' PM', '').trim());

    if (pm && hour < 12) {
      hour = (parseInt(hour) + 12).toString();
      time += 1200;
    }
    if (!pm && hour == 12) {
      hour = (parseInt(hour) - 12).toString();
      time -= 1200;
    }

    if (!hours[index]) {
      hours[index] = {};
    }

    hours[index][type] = {
      day: index,
      time,
      hours: hour,
      minutes: min,
    };

    console.log('Selected hours:');
    console.log(hours);

    this.setState({
      selectedHours: hours,
    });
  };

  /**
   * `day` is 0-based, starting with Sunday. `hours` are based on 24-hours and `minutes` are self explanitory.
   */
  renderBusinessHours = () => {
    const { selectedHours } = this.state;
    console.log('Rendering business hours:');
    console.log(selectedHours);
    if (selectedHours && selectedHours.length === 1) {
      if (selectedHours[0].permanently_closed) {
        return <div>Permanently Closed</div>;
      }
      if (selectedHours[0].always_open) {
        return <div>Always Open</div>;
      }
    }
    return (
      <div>
        {days.map((day, i) => <div>{this.formatHoursDisplay(day, i)}</div>)}
      </div>
    );
  };

  renderEvents = () => null;

  resetFilters = () => {
    // TODO Set them back to defaults and reload
    alert('Reset coming soon!');
  };

  renderFilterOptions = () => (
    <SwipeableDrawer
      anchor="right"
      open={this.state.showFilterDrawer}
      // onClick={() => this.setState({ showFilterDrawer: false })}
      // onKeyDown={() => this.setState({ showFilterDrawer: false })}
      onClose={() => this.setState({ showFilterDrawer: false })}
    >
      {/* TODO Add app bar on mobile with back arrow */}
      <div
        style={{
          width: isMobile ? 'calc(100vw - 40px)' : 300,
          marginLeft: 20,
          marginRight: 20,
          marginBottom: 30,
        }}
      >
        <div style={{ height: 20 }} />
        <FormLabel component="legend">By Country</FormLabel>
        <Select
          value={this.state.selectedCountry}
          style={{ maxWidth: 'calc(100% - 40px)' }}
          onChange={this.handleSelectChange('selectedCountry')}
          options={countries.map((country) => ({ value: country.abbreviation, label: country.name }))}
          // isOptionSelected={option => {
          //   return this.state.selectedShop === option;
          // }}
        />
        <div style={{ height: 20 }} />
        <FormLabel component="legend">By State / Province</FormLabel>
        <Select
          value={this.state.selectedState}
          style={{ maxWidth: 'calc(100% - 40px)' }}
          onChange={this.handleSelectChange('selectedState')}
          // loadOptions={this.loadShops.bind(this)}
          options={this.state.selectedCountry && this.state.selectedCountry.value === 'CA' ? canadianProvinces.map((province) => ({
            value: province.abbreviation,
            label: province.name,
          })) : usStates.map((state) => ({ value: state.abbreviation, label: state.name }))}
          // isOptionSelected={option => {
          //   return this.state.selectedShop === option;
          // }}
        />
        <div style={{ height: 20 }} />
        <FormLabel component="legend">By City (Coming Soon)</FormLabel>
        <AsyncSelect
          value={this.state.selectedCity}
          style={{ maxWidth: 'calc(100% - 40px)' }}
          onChange={this.handleSelectChange('selectedCity')}
          // loadOptions={this.loadShops.bind(this)}
          // defaultOptions={defaultShops}
          // isOptionSelected={option => {
          //   return this.state.selectedShop === option;
          // }}
        />
        <div style={{ height: 20 }} />
        <FormLabel component="legend">By Issue</FormLabel>
        <div>
          <FormControlLabel
            control={(
              <Checkbox
                checked={this.state.showMissingImages}
                onChange={this.handleCheckboxChange('showMissingImages')}
                value="primary"
                inputProps={{ 'aria-label': 'Missing images' }}
              />
            )}
            label="Missing images"
          />
        </div>
        <div>
          <FormControlLabel
            control={(
              <Checkbox
                checked={this.state.showMissingGeom}
                onChange={this.handleCheckboxChange('showMissingGeom')}
                value="primary"
                inputProps={{ 'aria-label': 'Missing geometry point' }}
              />
            )}
            label="Missing location Geometry Point"
          />
        </div>
        <div>
          <FormControlLabel
            control={(
              <Checkbox
                checked={this.state.showPossibleDuplicates}
                onChange={this.handleCheckboxChange('showPossibleDuplicates')}
                value="primary"
                inputProps={{ 'aria-label': 'Possible Duplicates' }}
              />
            )}
            label="Possible Duplicates"
          />
        </div>

        <div
          style={{
            display: 'flex',
            position: 'fixed',
            bottom: 0,
            backgroundColor: 'white',
            width: 300,
            paddingTop: 10,
            paddingBottom: 10,
            margin: 'auto',
          }}
        >
          <Button
            onClick={() => {
              this.setState({
                showFilterDrawer: false,
              }, this.resetFilters);
            }}
            style={{
              display: 'block',
              flex: 1,
              marginRight: 10,
              textAlign: 'center',
            }}
          >
            {'Reset'}
          </Button>
          <Button
            variant="contained"
            color="secondary"
            style={{
              display: 'block',
              flex: 1,
              textAlign: 'center',
            }}
            onClick={() => this.onSaveModal('filter')}
          >
            {'Apply'}
          </Button>
        </div>
      </div>
    </SwipeableDrawer>
  );

  renderMediaCropper = () => {
    const {
      selectedImageFile,
      showMediaCropper,
      activeDropzone,
      activeDropzoneId,
    } = this.state;
    return (
      <MediaCropper
        circle={activeDropzoneId === 'venueLogo'}
        aspectRatio={activeDropzoneId === 'venueLogo' ? 1 : 1000 / 300}
        src={selectedImageFile}
        open={showMediaCropper}
        toggle={() => this.setState({ showMediaCropper: !showMediaCropper })}
        onClose={() => this.setState({ showMediaCropper: false })}
        onSave={(croppedCanvas) => {
          const base64 = croppedCanvas.toDataURL();
          this.setState({
            showMediaCropper: false,
            selectedImage: base64, // FIXME Need to know which image this is for?
          });
          croppedCanvas.toBlob((blob) => {
            this.forceUpdate();

            console.log('Canvas blob');
            console.log(blob);
            console.log(activeDropzone);
            console.log(activeDropzoneId);
            const fileReader = new FileReader();
            fileReader.onload = (event) => {
              const dataUrl = event.target.result;
              console.log(dataUrl);
              const { dropzone } = activeDropzone;
              dropzone.emit('addedfile', blob);
              dropzone.emit('thumbnail', blob, dataUrl);
              dropzone.emit('complete', blob);
              dropzone.files.push(blob);
            };
            fileReader.readAsDataURL(blob);

            this.uploadMedia(blob, base64.split(';')[0].split('/')[1], activeDropzoneId);
          }/* , 'image/png' */);
        }}
      />
    );
  };

  render() {
    const { sizePerPage, page } = this.state;
    const { venues } = this.state;
    const { totalSize, loading } = this.props.VenueManager;

    console.log('Venues:');
    console.log(venues);

    const columns = [{
      dataField: 'profile_image_url',
      text: 'Image',
      formatter: this.imageFormatter,
      sort: false,
    }, {
      dataField: 'name',
      text: 'Name',
      sort: true,
    }, {
      dataField: 'active',
      text: 'Active?',
      formatter: this.activeFormatter,
      sort: false,
    }, {
      dataField: 'website',
      text: 'Website',
      sort: true,
    }, {
      dataField: 'location',
      text: 'Street',
      formatter: this.streetFormatter,
      sort: true,
    }, {
      dataField: 'location',
      text: 'City',
      formatter: this.cityFormatter,
      sort: true,
    }, {
      dataField: 'can_buy',
      text: 'Can Buy',
      formatter: this.checkBoxFormatter,
      sort: true,
    }, {
      dataField: 'can_smoke',
      text: 'Can Smoke',
      formatter: this.checkBoxFormatter,
      sort: true,
    }, {
      dataField: 'can_buy_online',
      text: 'Can Buy Online',
      formatter: this.checkBoxFormatter,
      sort: true,
    }, {
      dataField: 'yelp_id',
      text: 'Yelp',
      formatter: this.yelpLinkFormatter,
      sort: true,
    }, {
      dataField: 'foursquare_id',
      text: 'Foursquare',
      formatter: this.foursquareLinkFormatter,
      sort: true,
    }, {
      dataField: 'google_id',
      text: 'Google',
      formatter: this.googleLinkFormatter,
      sort: true,
    }, {
      dataField: 'edit',
      text: '',
      formatter: this.editBtnFormatter,
      sort: true,
    }];

    // TODO Expand row for more details, such as full address, owner info, hours, etc?
    //  https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html?selectedKind=Row%20Expand&selectedStory=Basic%20Row%20Expand&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel

    const rowEvents = {
      onMouseEnter: (e, row, index) => {
        // console.log("Mouse entered: " + index);
        document.querySelector(`#dropdown-actions-${index}`).classList.remove('d-none');
      },
      onMouseLeave: (e, row, index) => {
        document.querySelector(`#dropdown-actions-${index}`).classList.add('d-none');
      },
      onDoubleClick: (e, row, index) => {
        // INFO If we don't include this event, the double click to edit doesn't work
        // console.log(e);
      },
    };

    const pageButtonRenderer = ({ page, active, onPageChange }) => {
      const handleClick = (e) => {
        e.preventDefault();
        onPageChange(page);
      };
      let classname = 'btn btn-outline-secondary';
      if (active) {
        classname = 'btn btn-secondary';
      }
      return (
        <li className="page-item pl-1" key={page}>
          <a href="#" onClick={handleClick} className={classname}>{page}</a>
        </li>
      );
    };

    // TODO https://www.npmjs.com/package/react-device-detect use a different layout for mobile
    return (
      <div>
        <SweetAlert
          show={this.state.message.show}
          type={this.state.message.type}
          title={this.state.message.title}
          onConfirm={this.closeAlert}
        >
          {this.state.message.text}
        </SweetAlert>

        <Breadcrumb title="Venue Manager" label="Manager" parent="Venues" />

        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card mb-0">
                <div className="card-body datatable-react">
                  <PaginationProvider
                    pagination={paginationFactory({
                      pageButtonRenderer,
                      page,
                      sizePerPage,
                      custom: true,
                      totalSize,
                      showTotal: true,
                    })}
                  >
                    {
                      ({
                        paginationProps,
                        paginationTableProps,
                      }) => (
                        <div>
                          <ToolkitProvider
                            keyField="id"
                            data={venues}
                            columns={columns}
                            search
                          >
                            {
                              (toolkitprops) => (
                                <div>
                                  {/* Known issue https://github.com/react-bootstrap-table/react-bootstrap-table2/issues/787 */}
                                  <DebounceSearchBar
                                    {...toolkitprops.searchProps}
                                    placeholder="Search venues..."
                                    onChange={() => this.setState({ page: 1 })}
                                  />
                                  {/* <ClearSearchButton { ...props.searchProps } /> */}
                                  <button
                                    className="btn btn-primary"
                                    onClick={() => this.onOpenModal('venue')}
                                    style={{ float: 'right' }}
                                  >
                                    {isMobile ? (<i className="icon icon-plus" />) : 'Add Venue'}
                                  </button>
                                  <button
                                    className="btn btn-outline-primary mr-2"
                                    onClick={() => this.onOpenModal('filter')}
                                    style={isMobile ? {
                                      padding: '6px 14px',
                                      float: 'right',
                                    } : { float: 'right' }}
                                  >
                                    {isMobile ? (<i className="icon icon-filter" />) : 'Filter'}
                                  </button>
                                  <div style={{ marginLeft: 10, float: 'left' }}>
                                    <SizePerPageDropdownStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                  <BootstrapTable
                                    remote
                                    keyField="id"
                                    data={venues}
                                    columns={columns}
                                    defaultSorted={defaultSorted}
                                    wrapperClasses="table-responsive"
                                    rowEvents={rowEvents}
                                    {...paginationTableProps}
                                    cellEdit={cellEditFactory(cellEditProps)}
                                    onTableChange={this.handleTableChange}
                                    noDataIndication={() => (!loading
                                      ? <NoDataIndicator message="No venues found matching search query." />
                                      : <TableLoader />)}
                                    {...toolkitprops.baseProps}
                                  />
                                  <PaginationTotalStandalone
                                    {...paginationProps}
                                  />
                                  <div style={isMobile ? { marginTop: 10 } : { width: 200, display: 'inline-block', float: 'right' }}>
                                    <Pagination
                                      hideNavigation={!isMobile}
                                      page={page}
                                      sizePerPage={sizePerPage}
                                      totalSize={totalSize}
                                      handlePageChange={this.handlePageChange}
                                      onShowPagePicker={() => this.onOpenModal('page')}
                                    />
                                  </div>
                                  {!isMobile && (
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  )}
                                </div>
                              )
                            }
                          </ToolkitProvider>
                        </div>
                      )
                    }
                  </PaginationProvider>
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.pagePickerModal()}
        {this.venueModal()}
        {this.mergeModal()}
        {this.ownerModal()}
        {this.hoursModal()}
        {this.eventModal()}
        {this.dialogModal()}
        {this.imageFromUrlModal()}
        {this.renderFilterOptions()}
        {this.renderMediaCropper()}
      </div>
    );
  }

  handleAddressChange = (key) => (event) => {
    const address = this.state.selectedAddress;
    address[key] = event.target.value;

    this.setState({
      selectedAddress: address,
    });
  };

  addressComponents = () => (
    <div>
      <hr />
      <div className="row">
        <div className="col-md-12">
          Country
          <input
            className="form-control"
            type="text"
            name="state"
            value={this.state.selectedAddress.country}
            style={{ textTransform: 'capitalize', marginBottom: 10 }}
            onChange={this.handleAddressChange('country')}
          />
        </div>
        <div className="col-md-12">
          Street
          <input
            className="form-control"
            type="text"
            name="street"
            value={this.state.selectedAddress.street}
            style={{ textTransform: 'capitalize', marginBottom: 10 }}
            onChange={this.handleAddressChange('street')}
          />
        </div>
        <div className="col-md-4">
          City / Town
          <input
            className="form-control"
            type="text"
            name="city"
            value={this.state.selectedAddress.city}
            style={{ textTransform: 'capitalize' }}
            onChange={this.handleAddressChange('city')}
          />
        </div>
        <div className="col-md-4">
          State / Province
          <input
            className="form-control"
            type="text"
            name="state"
            value={this.state.selectedAddress.state}
            style={{ textTransform: 'capitalize' }}
            onChange={this.handleAddressChange('state')}
          />
        </div>
        <div className="col-md-4">
          Zip / Postal Code
          <input
            className="form-control"
            type="text"
            name="zip"
            value={this.state.selectedAddress.zip}
            style={{ textTransform: 'capitalize' }}
            onChange={this.handleAddressChange('zip')}
          />
        </div>
      </div>
    </div>
  );

  // FIXME Put this in a helper class - it gets reused
  addressFromComponents = (comp) => {
    const address = {};

    for (let i = 0; i < comp.length; i++) {
      const obj = comp[i];
      // console.log(obj.types);
      if (obj.types.indexOf('street_number') !== -1) {
        address.street_number = obj.long_name;
      } else if (obj.types.indexOf('route') !== -1) {
        address.street = `${address.street_number} ${obj.long_name}`;
      } else if (obj.types.indexOf('locality') !== -1 || obj.types.indexOf('postal_town') !== -1) {
        address.city = obj.long_name;
      } else if (obj.types.indexOf('administrative_area_level_1') !== -1) {
        address.state = obj.short_name;
      } else if (obj.types.indexOf('postal_code') !== -1) {
        address.zip = obj.short_name;
      } else if (obj.types.indexOf('country') !== -1) {
        address.country = obj.long_name;
      }
    }
    return address;
  };

  venueImageUrl = (image) => {
    if (image && image.url) {
      if (image.url.indexOf('http') === -1) {
        return `https://${image.url}`;
      }
      return image.url;
    }
    return null; // FIXME What? This should never happen...
  };

  venueModal = () => {
    const {
      showVenueModal,
      selectedHours,
      selectedEvents,
      selectedDescription,
      virtualTourEmbedCode,
      virtualTourEmbedIframe,
    } = this.state;
    return (
      <Modal
        keyboard
        isOpen={showVenueModal}
        onClosed={() => this.onCloseModal('venue')}
        toggle={() => this.onCloseModal('venue')}
        className="modal-dialog-lg"
      >
        <div className="modal-header">
          <h5 className="modal-title">Add New Venue</h5>
          <div className="venue-status-toggle">
            <FormControlLabel
              control={(
                <Switch
                  checked={this.state.active}
                  onChange={(e) => {
                    console.log('Changed active state:');
                    console.log(e.target);
                    console.log(e.target.checked ? 'Switch is on' : 'Switch is off');
                    this.setState({ active: e.target.checked });
                  }}
                  color="secondary"
                />
              )}
              label="Active"
            />
          </div>
          <div style={{ marginTop: 4 }}>
            <Icon
              name="more-vertical"
              aria-label="more"
              aria-controls="long-menu"
              aria-owns={this.state.anchorElement ? 'long-menu' : null}
              aria-haspopup="true"
              className="dropbtn"
              style={{ cursor: 'pointer', color: '#2b2b2b', padding: 0, display: 'inline', height: 18, width: 18 }}
              // onClick={this.togglePopover}
            />
          </div>
        </div>
        <div className="modal-body">
          <div className="row">
            <div className="col-md-8">
              <Card
                style={{
                  padding: 10,
                  marginTop: 12,
                }}
              >
                <div className="row">
                  <div className="col-md-12">
                    <span style={{ fontWeight: 700 }}>Name Search</span>
                    <div
                      style={{
                        marginTop: 10,
                        marginBottom: 10,
                      }}
                    >
                      <Autocomplete
                        // apiKey={config.google.api_key}
                        style={{ width: '100%' }}
                        className="form-control"
                        placeholder="Start typing..."
                        value={this.state.selectedName}
                        onChange={this.handleInputChange('selectedName')}
                        onPlaceSelected={(place) => {
                          console.log(place);

                          const lat = place.geometry.location.lat();
                          const lng = place.geometry.location.lng();
                          const comp = place.address_components;

                          const address = this.addressFromComponents(comp);
                          address.latitude = lat;
                          address.longitude = lng;
                          address.formatted_address = place.formatted_address;

                          // TODO Might be able to use images returned if uploaded by owner
                          // TODO Try to pull in whether location is permanently_closed or not

                          const state = {
                            selectedName: place.name,
                            selectedWebsite: place.website,
                            selectedPhone: place.international_phone_number,
                            selectedGoogleId: place.place_id,
                            selectedAddress: address,
                            selectedHours: place.opening_hours ? place.opening_hours.periods : null,
                          };
                          console.log(state);
                          this.setState(state);
                        }}
                        types={['establishment']}
                        fields={[
                          'name',
                          'address_components',
                          'geometry.location',
                          'place_id',
                          'formatted_address',
                          'photos',
                          'url',
                          'website',
                          'opening_hours',
                          'international_phone_number',
                        ]}
                        // componentRestrictions={{country: "us"}}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <span style={{ fontWeight: 700 }}>Description</span>
                    <div
                      style={{
                        marginTop: 10,
                        marginBottom: 10,
                      }}
                    >
                      <textarea
                        className="form-control"
                        value={selectedDescription}
                        onChange={(e) => {
                          this.setState({ selectedDescription: e.target.value });
                        }}
                      />
                    </div>
                  </div>
                </div>
              </Card>

              <Card
                style={{
                  padding: 10,
                  marginTop: 12,
                }}
              >
                <span style={{ fontWeight: 700 }}>Address Search</span>
                <Autocomplete
                  // apiKey={config.google.api_key}
                  style={{ width: '100%' }}
                  className="form-control"
                  onPlaceSelected={(place) => {
                    console.log(place);

                    const lat = place.geometry.location.lat();
                    const lng = place.geometry.location.lng();

                    const comp = place.address_components;
                    console.log(comp);

                    const address = this.addressFromComponents(comp);
                    address.latitude = lat;
                    address.longitude = lng;

                    this.setState({
                      selectedAddress: address,
                    });
                  }}
                  types={['address']}
                  fields={['address_components', 'geometry.location', 'place_id', 'formatted_address', 'photos']}
                  // componentRestrictions={{country: "us"}}
                />
                {this.state.selectedAddress && this.addressComponents()}
              </Card>

              <Card style={{ padding: 10 }}>
                <div className="row" style={{ display: 'flex' }}>
                  <div className="col-md-12">
                    <div>
                      <span style={{ fontWeight: 700 }}>Images</span>
                      <span
                        style={{
                          color: '#d6c290',
                          cursor: 'pointer',
                          float: 'right',
                        }}
                        onClick={() => this.onOpenModal('imageurl')}
                      >
                        {'Add image from URL'}
                      </span>
                    </div>
                    <div className="row" style={{ padding: 20 }}>
                      {this.state.selectedVenueImages.map((image) => (
                        <div
                          className="col-md-3"
                          style={{
                            marginTop: 10,
                            marginBottom: 10,
                            cursor: 'pointer',
                          }}
                        >
                          <div className="image-overlay">
                            <Icon
                              name="trash"
                              className="delete-image-icon"
                              onClick={() => {
                                console.log('Clicked delete icon');
                                console.log(image);
                                ModalDialog.show({
                                  title: 'Delete image?',
                                  message: 'Are you sure you want to delete this image?',
                                  type: 'error',
                                  buttons: [{
                                    label: 'Cancel',
                                    onClick: () => {
                                      ModalDialog.close();
                                    },
                                  }, {
                                    label: 'Delete',
                                    style: { color: 'rgb(239, 81, 100)' },
                                    onClick: () => {
                                      console.log('Adding image to remove...');
                                      console.log(image);
                                      const images = this.state.selectedVenueImages;
                                      for (let i = 0; i < images.length; i++) {
                                        if (images[i].id === image.id) {
                                          images.splice(i, 1);
                                          break;
                                        }
                                      }

                                      const imagesToRemove = [...this.state.selectedImagesToRemove];
                                      imagesToRemove.push(image);
                                      this.setState({
                                        selectedVenueImages: images,
                                        selectedImagesToRemove: imagesToRemove,
                                      }, () => {
                                        console.log(this.state);
                                      });
                                      ModalDialog.close();
                                    },
                                  }],
                                });
                              }}
                            />
                            <Icon
                              name="maximize2"
                              className="expand-icon"
                              onClick={() => {
                                console.log('Clicked expand icon');
                                alert('Coming soon!');
                              }}
                            />
                          </div>
                          <img
                            src={this.venueImageUrl(image)}
                            style={{
                              width: '100%',
                              borderRadius: 5,
                              border: '1px solid #dfdfdf',
                            }}
                          />
                        </div>
                      ))}
                      <Dropzone
                        id="venueImages"
                        config={{
                          postUrl: 'no-url',
                          iconFiletypes: ['.jpg', '.png'],
                        }}
                        djsConfig={{
                          dictDefaultMessage: 'Add images or drop here to upload',
                          previewTemplate: djsPreviewTemplate,
                        }}
                        eventHandlers={this.dropzoneEventHandlers('venueImages')}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <section className="dropzone">
                            <div style={{ height: '100%' }} {...getRootProps()}>
                              <input {...getInputProps()} />
                              {/* <p>Drop images here</p> */}
                            </div>
                          </section>
                        )}
                      </Dropzone>
                    </div>
                  </div>
                </div>
              </Card>

              <Card
                style={{
                  padding: 10,
                  marginTop: 12,
                }}
              >
                <span style={{ fontWeight: 700 }}>Contact Info</span>
                <div className="row">
                  <div className="col-md-4">
                    Website
                    <div
                      style={{
                        marginTop: 10,
                        marginBottom: 10,
                      }}
                    >
                      <input
                        className="form-control"
                        type="text"
                        name="website"
                        value={this.state.selectedWebsite}
                        onChange={this.handleInputChange('selectedWebsite')}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    Phone
                    <div
                      style={{
                        marginTop: 10,
                        marginBottom: 10,
                      }}
                    >
                      <input
                        className="form-control"
                        type="phone"
                        name="phone"
                        value={this.state.selectedPhone}
                        onChange={this.handleInputChange('selectedPhone')}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    Email
                    <div
                      style={{
                        marginTop: 10,
                        marginBottom: 10,
                      }}
                    >
                      <input
                        className="form-control"
                        type="email"
                        name="email"
                        value={this.state.selectedEmail}
                        onChange={this.handleInputChange('selectedEmail')}
                      />
                    </div>
                  </div>
                </div>
              </Card>
              <Card
                style={{
                  padding: 10,
                  marginTop: 12,
                }}
              >
                <span style={{ fontWeight: 700 }}>Social</span>
                <div className="row">
                  <div className="col-md-4">
                    Yelp ID
                    <input
                      className="form-control"
                      type="text"
                      name="yelpId"
                      value={this.state.selectedYelpId}
                      onChange={this.handleInputChange('selectedYelpId')}
                    />
                  </div>
                  <div className="col-md-4">
                    Foursquare ID
                    <input
                      className="form-control"
                      type="text"
                      name="foursquareId"
                      value={this.state.selectedFoursquareId}
                      onChange={this.handleInputChange('selectedFoursquareId')}
                    />
                  </div>
                  <div className="col-md-4">
                    Google Place ID
                    <input
                      className="form-control"
                      type="text"
                      name="googleId"
                      value={this.state.selectedGoogleId}
                      onChange={this.handleInputChange('selectedGoogleId')}
                    />
                  </div>
                </div>

                <div className="row" style={{ marginTop: 10 }}>
                  <div className="col-md-4">
                    Twitter
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>@</InputGroupText>
                      </InputGroupAddon>
                      <Input
                        className="form-control"
                        type="text"
                        name="twitterId"
                        value={this.state.selectedTwitterId}
                        placeholder="<username>"
                        onChange={this.handleInputChange('selectedTwitterId')}
                      />
                    </InputGroup>
                  </div>
                  <div className="col-md-4">
                    Facebook
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>fb.com/</InputGroupText>
                      </InputGroupAddon>
                      <Input
                        className="form-control"
                        type="text"
                        name="twitterId"
                        value={this.state.selectedFacebookId}
                        placeholder="<username>"
                        onChange={this.handleInputChange('selectedFacebookId')}
                      />
                    </InputGroup>
                  </div>
                  <div className="col-md-4">
                    Instagram
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>@</InputGroupText>
                      </InputGroupAddon>
                      <Input
                        className="form-control"
                        type="text"
                        name="twitterId"
                        value={this.state.selectedInstagramId}
                        placeholder="<username>"
                        onChange={this.handleInputChange('selectedInstagramId')}
                      />
                    </InputGroup>
                  </div>
                </div>
              </Card>

              <Card style={{ padding: 10 }}>
                <div className="row" style={{ display: 'flex' }}>
                  <div className="col-md-12">
                    <div>
                      <span style={{ fontWeight: 700 }}>Virtual Tour</span>
                      {!virtualTourEmbedIframe && <div>Include iframe embed code from Google Maps, Matterport, etc</div>}
                      {!virtualTourEmbedIframe && (
                        <textarea
                          className="form-control"
                          placeholder="Paste iframe embed code here"
                          value={virtualTourEmbedCode}
                          onChange={(e) => {
                            this.setState({ virtualTourEmbedCode: e.target.value });
                          }}
                        />
                      )}
                      {virtualTourEmbedIframe && <div style={{ width: '100%' }} dangerouslySetInnerHTML={{ __html: virtualTourEmbedIframe }} />}
                    </div>
                  </div>
                </div>
              </Card>
            </div>
            <div className="col-md-4">
              <Card className="venue-dropzone-wrapper" style={{ marginTop: 12, padding: 10 }}>
                <div>
                  <div style={{ width: '100%' }}>
                    <Dropzone
                      id="venueCoverImage"
                      ref={(ref) => this.venueCoverImage = ref}
                      config={{
                        postUrl: 'no-url',
                        iconFiletypes: ['.jpg', '.png'],
                      }}
                      djsConfig={{
                        dictDefaultMessage: 'Drop cover photo here',
                        previewTemplate: djsPreviewTemplate,
                      }}
                      eventHandlers={this.dropzoneEventHandlers('venueCoverImage')}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <section className="dropzone">
                          <div style={{ height: '100%' }} {...getRootProps()}>
                            <input {...getInputProps()} />
                            <p>Drop cover photo here</p>
                          </div>
                        </section>
                      )}
                    </Dropzone>
                  </div>
                </div>
                <div>
                  <div style={{ width: 120, margin: '10px auto' }}>
                    <Dropzone
                      id="venueLogo"
                      ref={(ref) => this.venueLogo = ref}
                      config={{
                        postUrl: 'no-url',
                        iconFiletypes: ['.jpg', '.png'],
                      }}
                      djsConfig={{
                        dictDefaultMessage: 'Drop logo here',
                        previewTemplate: djsPreviewTemplate,
                      }}
                      eventHandlers={this.dropzoneEventHandlers('venueLogo')}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <section className="dropzone">
                          <div style={{ height: '100%' }} {...getRootProps()}>
                            <input {...getInputProps()} />
                            <p>Drop venue logo here</p>
                          </div>
                        </section>
                      )}
                    </Dropzone>
                  </div>
                </div>
              </Card>
              <Card
                style={{
                  padding: 10,
                  marginTop: 12,
                }}
              >
                <div className="row">
                  <div className="col-md-12">
                    Tags / Amenities
                    <AsyncCreatableSelect
                      value={this.state.selectedTags}
                      onChange={this.handleSelectChange('selectedTags')}
                      defaultOptions={defaultAmenities.map((value) => ({
                        value,
                        label: value,
                      }))}
                      isMulti
                      // getOptionLabel={option =>
                      //     `${option}`
                      // }
                      // getOptionValue={option => `${option}`}
                      // loadOptions={this.loadTags}
                      isOptionSelected={(option) => this.state.selectedTags === option}
                    />
                  </div>
                </div>

                <div style={{ margin: 20 }}>
                  <div className="checkbox checkbox-primary">
                    <Input
                      name="buy"
                      id="buy-cb"
                      type="checkbox"
                      checked={this.state.selectedCanBuy}
                      onChange={this.handleCheckboxChange('selectedCanBuy')}
                    />
                    <Label className="mb-0" htmlFor="buy-cb">Can Buy In Shop</Label>
                  </div>

                  <div className="checkbox checkbox-primary">
                    <Input
                      name="buyOnline"
                      id="buy-online-cb"
                      type="checkbox"
                      checked={this.state.selectedCanBuyOnline}
                      onChange={this.handleCheckboxChange('selectedCanBuyOnline')}
                    />
                    <Label className="mb-0" htmlFor="buy-online-cb">Can Buy Online</Label>
                  </div>

                  <div className="checkbox checkbox-primary">
                    <Input
                      name="smoke"
                      id="smoke-cb"
                      type="checkbox"
                      checked={this.state.selectedCanSmoke}
                      onChange={this.handleCheckboxChange('selectedCanSmoke')}
                    />
                    <Label className="mb-0" htmlFor="smoke-cb">Can Smoke</Label>
                  </div>
                </div>
              </Card>

              <Card
                style={{
                  padding: 10,
                  marginTop: 12,
                }}
              >
                <div>
                  <span style={{ fontWeight: 700 }}>Owner Info</span>
                  <span
                    style={{
                      color: '#d6c290',
                      cursor: 'pointer',
                      float: 'right',
                    }}
                    onClick={() => this.onOpenModal('owner')}
                  >
                    {'Edit'}
                  </span>
                </div>
                {'Coming soon'}
              </Card>

              <Card
                style={{
                  padding: 10,
                  marginTop: 12,
                }}
              >
                <div>
                  <span style={{ fontWeight: 700 }}>Business Hours</span>
                  <span
                    style={{
                      color: '#d6c290',
                      cursor: 'pointer',
                      float: 'right',
                    }}
                    onClick={() => this.onOpenModal('hours')}
                  >
                    {'Manage'}
                  </span>
                </div>
                {!selectedHours && 'No hours added'}
                {selectedHours && this.renderBusinessHours()}
              </Card>

              <Card
                style={{
                  padding: 10,
                  marginTop: 12,
                }}
              >
                <div>
                  <span style={{ fontWeight: 700 }}>Events</span>
                  <span
                    style={{
                      color: '#d6c290',
                      cursor: 'pointer',
                      float: 'right',
                    }}
                    onClick={() => this.onOpenModal('event')}
                  >
                    {'Add New'}
                  </span>
                </div>
                {!selectedEvents && 'No events added'}
                {selectedEvents && this.renderEvents()}
              </Card>

              <div className="row" style={{ justifyContent: 'center' }}>
                <button
                  id="merge"
                  style={{ margin: 5 }}
                  type="button"
                  className="btn btn-raised btn-default btn-sm"
                  onClick={() => this.onOpenModal('merge')}
                >
                  {'Merge Into Another Venue'}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <Button onClick={this.deleteVenue} color="danger">Delete</Button>
          <Button onClick={() => this.onCloseModal('venue')}>Cancel</Button>
          <Button onClick={() => this.onSaveModal('venue')} variant="contained" color="secondary">Save</Button>
        </div>
      </Modal>
    );
  };

  mergeModal = () => (
    <Modal
      isOpen={this.state.showMergeModal}
      onClosed={() => this.onCloseModal('merge')}
      toggle={() => this.onCloseModal('merge')}
    >
      <div className="modal-header">
        <h5 className="modal-title">Merge Venue</h5>
      </div>
      <div className="modal-body md">
        Select venue to merge into:
        <AsyncSelect
          value={this.state.mergeVenue}
          onChange={this.handleSelectChange('mergeVenue')}
          getOptionLabel={(option) => `${option.label}`}
          getOptionValue={(option) => `${option}`}
          isOptionSelected={(option) => this.state.mergeVenue.value === option.value}
          loadOptions={this.loadVenues}
        />
        <div className="checkbox checkbox-primary ml-3">
          <Input name="force" id="force-cb" type="checkbox" checked={this.state.selectedForceMerge} />
          <Label className="mb-0" htmlFor="force-cb">
            {'Force details into selected venue?'}
            <i
              className="icon-info-alt"
              id="forceTooltip"
              style={{ marginLeft: 10 }}
            />
          </Label>
        </div>
        <Tooltip
          placement="top"
          isOpen={this.state.showMergeTooltip}
          target="forceTooltip"
          toggle={this.toggleTooltip('showMergeTooltip')}
        >
          This will force the active venue details into the venue from the select field above. When left unchecked, the
          software will only use the data from the active venue to fill in missing data from the one in the select
          field.
        </Tooltip>
      </div>
      <div className="modal-footer">
        <Button onClick={() => this.onCloseModal('merge')}>Cancel</Button>
        <Button
          onClick={() => this.onSaveModal('merge')}
          variant="contained"
          color="secondary"
          disabled={this.state.merging}
        >
          {this.state.merging && <Spinner color="light" style={{ marginRight: 8 }} />}
          {this.state.merging ? 'Merging...' : 'Merge'}
        </Button>
      </div>
    </Modal>
  );

  imageFromUrlModal = () => (
    <Modal
      isOpen={this.state.showImageUrlModal}
      onClosed={() => this.onCloseModal('imageurl')}
      toggle={() => this.onCloseModal('imageurl')}
    >
      <div className="modal-header">
        <h5 className="modal-title">Add Image From URL</h5>
      </div>
      <div className="modal-body">
        Paste Image URL
        <input
          className="form-control"
          type="text"
          name="image-url"
          placeholder="https://"
          value={this.state.selectedImageUrl}
          onChange={this.handleInputChange('selectedImageUrl')}
        />
      </div>
      <div className="modal-footer">
        <Button onClick={() => this.onCloseModal('imageurl')}>Cancel</Button>
        <Button
          disabled={this.state.submitting}
          onClick={() => this.onSaveModal('imageurl')}
          variant="contained"
          color="secondary"
        >
          {this.state.submitting
          && <Spinner type="border" color="light" size="sm" style={{ marginRight: 10 }} />}
          {this.state.submitting ? 'Adding image' : 'Add image'}
        </Button>
      </div>
    </Modal>
  );

  ownerModal = () => (
    <Modal
      isOpen={this.state.showOwnerModal}
      onClosed={() => this.onCloseModal('owner')}
      toggle={() => this.onCloseModal('owner')}
    >
      <div className="modal-header">
        <h5 className="modal-title">Owner Info</h5>
      </div>
      <div className="modal-body md">
        Coming soon - bypass the claim process and grant a user ownership of this venue.
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-outline-secondary" onClick={() => this.onCloseModal('owner')}>
          {'Close'}
        </button>
        {/* <button type="button" className="btn btn-primary" onClick={() => this.onSaveModal('owner')}>Merge</button> */}
      </div>
    </Modal>
  );

  hoursModal = () => (
    <Modal
      isOpen={this.state.showHoursModal}
      onClosed={() => this.onCloseModal('hours')}
      toggle={() => this.onCloseModal('hours')}
    >
      <div className="modal-header">
        <h5 className="modal-title">Business Hours</h5>
      </div>
      <div className="modal-body md">

        <div className="row">
          <div
            className="col-md-12"
            style={{
              margin: 16,
              paddingLeft: 20,
            }}
          >
            <div className="radio radio-primary">
              <Input
                name="hours-type"
                id="hours-custom-cb"
                type="radio"
                value="custom"
                checked={this.state.selectedHoursType === 'custom'}
                onChange={this.handleRadioChange('selectedHoursType')}
              />
              <Label className="mb-0" htmlFor="hours-custom-cb">Open on selected hours</Label>
            </div>

            <div className="radio radio-primary">
              <Input
                name="hours-type"
                id="hours-always-open-cb"
                type="radio"
                value="always_open"
                checked={this.state.selectedHoursType === 'always_open'}
                onChange={this.handleRadioChange('selectedHoursType')}
              />
              <Label className="mb-0" htmlFor="hours-always-open-cb">Always open</Label>
            </div>

            <div className="radio radio-primary">
              <Input
                name="hours-type"
                id="hours-none-cb"
                type="radio"
                value="no_hours"
                checked={this.state.selectedHoursType === 'no_hours'}
                onChange={this.handleRadioChange('selectedHoursType')}
              />
              <Label className="mb-0" htmlFor="hours-none-cb">No hours available</Label>
            </div>

            <div className="radio radio-primary">
              <Input
                name="hours-type"
                id="hours-closed-cb"
                type="radio"
                value="closed"
                checked={this.state.selectedHoursType === 'closed'}
                onChange={this.handleRadioChange('selectedHoursType')}
              />
              <Label className="mb-0" htmlFor="hours-closed-cb">Closed permanently</Label>
            </div>
          </div>
        </div>

        <div style={this.state.selectedHoursType === 'custom' ? { marginTop: 10 } : { display: 'none' }}>
          {/* FIXME Make these components? */}
          <Card style={{ padding: 10 }}>
            <div className="row" style={{ marginBottom: 6 }}>
              <div className="col-md-4">
                <div className="checkbox checkbox-primary" style={{ marginLeft: 24 }}>
                  <Input
                    name="days"
                    id="sunday-cb"
                    type="checkbox"
                    checked={this.state.selectedSunday}
                    onChange={this.handleCheckboxChange('selectedSunday')}
                  />
                  <Label className="mb-0" htmlFor="sunday-cb">Sunday</Label>
                </div>
              </div>

              <div className="col-md-8">
                <table style={{ width: '100%' }}>
                  <tbody>
                    <tr>
                      <td>
                        <Select
                          style={{
                            control: (styles) => ({
                              ...styles,
                              width: 120,
                            }),
                          }}
                          autosize={false}
                          options={hourTimeDefaults}
                          onFocus={this.onHoursFocusChange(0)}
                          value={this.formatHoursTime(0, 'open')}
                          onChange={this.handleHoursChange(0, 'open')}
                        />
                      </td>
                      <td style={{ textAlign: 'center', width: 30 }}>-</td>
                      <td>
                        <Select
                          style={{
                            control: (styles) => ({
                              ...styles,
                              width: 120,
                            }),
                          }}
                          autosize={false}
                          options={hourTimeDefaults}
                          onFocus={this.onHoursFocusChange(0)}
                          value={this.formatHoursTime(0, 'close')}
                          onChange={this.handleHoursChange(0, 'close')}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div className="row" style={{ marginBottom: 6 }}>
              <div className="col-md-4">
                <div className="checkbox checkbox-primary" style={{ marginLeft: 24 }}>
                  <Input
                    name="days"
                    id="monday-cb"
                    type="checkbox"
                    checked={this.state.selectedMonday}
                    onChange={this.handleCheckboxChange('selectedMonday')}
                  />
                  <Label className="mb-0" htmlFor="monday-cb">Monday</Label>
                </div>
              </div>

              <div className="col-md-8">
                <table style={{ width: '100%' }}>
                  <tbody>
                    <tr>
                      <td>
                        <Select
                          style={{
                            control: (styles) => ({
                              ...styles,
                              width: 120,
                            }),
                          }}
                          autosize={false}
                          options={hourTimeDefaults}
                          onFocus={this.onHoursFocusChange(1)}
                          value={this.formatHoursTime(1, 'open')}
                          onChange={this.handleHoursChange(1, 'open')}
                        />
                      </td>
                      <td style={{ textAlign: 'center', width: 30 }}>-</td>
                      <td>
                        <Select
                          style={{
                            control: (styles) => ({
                              ...styles,
                              width: 120,
                            }),
                          }}
                          autosize={false}
                          options={hourTimeDefaults}
                          onFocus={this.onHoursFocusChange(1)}
                          value={this.formatHoursTime(1, 'close')}
                          onChange={this.handleHoursChange(1, 'close')}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div className="row" style={{ marginBottom: 6 }}>
              <div className="col-md-4">
                <div className="checkbox checkbox-primary" style={{ marginLeft: 24 }}>
                  <Input
                    name="days"
                    id="tuesday-cb"
                    type="checkbox"
                    checked={this.state.selectedTuesday}
                    onChange={this.handleCheckboxChange('selectedTuesday')}
                  />
                  <Label className="mb-0" htmlFor="tuesday-cb">Tuesday</Label>
                </div>
              </div>

              <div className="col-md-8">
                <table style={{ width: '100%' }}>
                  <tbody>
                    <tr>
                      <td>
                        <Select
                          style={{
                            control: (styles) => ({
                              ...styles,
                              width: 120,
                            }),
                          }}
                          autosize={false}
                          options={hourTimeDefaults}
                          onFocus={this.onHoursFocusChange(2)}
                          value={this.formatHoursTime(2, 'open')}
                          onChange={this.handleHoursChange(2, 'open')}
                        />
                      </td>
                      <td style={{ textAlign: 'center', width: 30 }}>-</td>
                      <td>
                        <Select
                          style={{
                            control: (styles) => ({
                              ...styles,
                              width: 120,
                            }),
                          }}
                          autosize={false}
                          options={hourTimeDefaults}
                          onFocus={this.onHoursFocusChange(2)}
                          value={this.formatHoursTime(2, 'close')}
                          onChange={this.handleHoursChange(2, 'close')}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div className="row" style={{ marginBottom: 6 }}>
              <div className="col-md-4">
                <div className="checkbox checkbox-primary" style={{ marginLeft: 24 }}>
                  <Input
                    name="days"
                    id="wednesday-cb"
                    type="checkbox"
                    checked={this.state.selectedWednesday}
                    onChange={this.handleCheckboxChange('selectedWednesday')}
                  />
                  <Label className="mb-0" htmlFor="wednesday-cb">Wednesday</Label>
                </div>
              </div>

              <div className="col-md-8">
                <table style={{ width: '100%' }}>
                  <tbody>
                    <tr>
                      <td>
                        <Select
                          style={{
                            control: (styles) => ({
                              ...styles,
                              width: 120,
                            }),
                          }}
                          autosize={false}
                          options={hourTimeDefaults}
                          onFocus={this.onHoursFocusChange(3)}
                          value={this.formatHoursTime(3, 'open')}
                          onChange={this.handleHoursChange(3, 'open')}
                        />
                      </td>
                      <td style={{ textAlign: 'center', width: 30 }}>-</td>
                      <td>
                        <Select
                          style={{
                            control: (styles) => ({
                              ...styles,
                              width: 120,
                            }),
                          }}
                          autosize={false}
                          options={hourTimeDefaults}
                          onFocus={this.onHoursFocusChange(3)}
                          value={this.formatHoursTime(3, 'close')}
                          onChange={this.handleHoursChange(3, 'close')}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div className="row" style={{ marginBottom: 6 }}>
              <div className="col-md-4">
                <div className="checkbox checkbox-primary" style={{ marginLeft: 24 }}>
                  <Input
                    name="days"
                    id="thursday-cb"
                    type="checkbox"
                    checked={this.state.selectedThursday}
                    onChange={this.handleCheckboxChange('selectedThursday')}
                  />
                  <Label className="mb-0" htmlFor="thursday-cb">Thursday</Label>
                </div>
              </div>

              <div className="col-md-8">
                <table style={{ width: '100%' }}>
                  <tbody>
                    <tr>
                      <td>
                        <Select
                          style={{
                            control: (styles) => ({
                              ...styles,
                              width: 120,
                            }),
                          }}
                          autosize={false}
                          options={hourTimeDefaults}
                          onFocus={this.onHoursFocusChange(4)}
                          value={this.formatHoursTime(4, 'open')}
                          onChange={this.handleHoursChange(4, 'open')}
                        />
                      </td>
                      <td style={{ textAlign: 'center', width: 30 }}>-</td>
                      <td>
                        <Select
                          style={{
                            control: (styles) => ({
                              ...styles,
                              width: 120,
                            }),
                          }}
                          autosize={false}
                          options={hourTimeDefaults}
                          onFocus={this.onHoursFocusChange(4)}
                          value={this.formatHoursTime(4, 'close')}
                          onChange={this.handleHoursChange(4, 'close')}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div className="row" style={{ marginBottom: 6 }}>
              <div className="col-md-4">
                <div className="checkbox checkbox-primary" style={{ marginLeft: 24 }}>
                  <Input
                    name="friday"
                    id="friday-cb"
                    type="checkbox"
                    checked={this.state.selectedFriday}
                    onChange={this.handleCheckboxChange('selectedFriday')}
                  />
                  <Label className="mb-0" htmlFor="friday-cb">Friday</Label>
                </div>
              </div>

              <div className="col-md-8">
                <table style={{ width: '100%' }}>
                  <tbody>
                    <tr>
                      <td>
                        <Select
                          style={{
                            control: (styles) => ({
                              ...styles,
                              width: 120,
                            }),
                          }}
                          autosize={false}
                          options={hourTimeDefaults}
                          onFocus={this.onHoursFocusChange(5)}
                          value={this.formatHoursTime(5, 'open')}
                          onChange={this.handleHoursChange(5, 'open')}
                        />
                      </td>
                      <td style={{ textAlign: 'center', width: 30 }}>-</td>
                      <td>
                        <Select
                          style={{
                            control: (styles) => ({
                              ...styles,
                              width: 120,
                            }),
                          }}
                          autosize={false}
                          options={hourTimeDefaults}
                          onFocus={this.onHoursFocusChange(5)}
                          value={this.formatHoursTime(5, 'close')}
                          onChange={this.handleHoursChange(5, 'close')}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div className="row" style={{ marginBottom: 6 }}>
              <div className="col-md-4">
                <div className="checkbox checkbox-primary" style={{ marginLeft: 24 }}>
                  <Input
                    name="days"
                    id="saturday-cb"
                    type="checkbox"
                    checked={this.state.selectedSaturday}
                    onChange={this.handleCheckboxChange('selectedSaturday')}
                  />
                  <Label className="mb-0" htmlFor="saturday-cb">Saturday</Label>
                </div>
              </div>

              <div className="col-md-8">
                <table style={{ width: '100%' }}>
                  <tbody>
                    <tr>
                      <td>
                        <Select
                          style={{
                            control: (styles) => ({
                              ...styles,
                              width: 120,
                            }),
                          }}
                          autosize={false}
                          options={hourTimeDefaults}
                          onFocus={this.onHoursFocusChange(6)}
                          value={this.formatHoursTime(6, 'open')}
                          onChange={this.handleHoursChange(6, 'open')}
                        />
                      </td>
                      <td style={{ textAlign: 'center', width: 30 }}>-</td>
                      <td>
                        <Select
                          style={{
                            control: (styles) => ({
                              ...styles,
                              width: 120,
                            }),
                          }}
                          autosize={false}
                          options={hourTimeDefaults}
                          onFocus={this.onHoursFocusChange(6)}
                          value={this.formatHoursTime(6, 'close')}
                          onChange={this.handleHoursChange(6, 'close')}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </Card>
        </div>
      </div>
      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-outline-secondary"
          onClick={() => this.onCloseModal('hours')}
        >
          {'Cancel'}
        </button>
        <button type="button" className="btn btn-primary" onClick={() => this.onSaveModal('hours')}>Set</button>
      </div>
    </Modal>
  );

  // FIXME Remove this from here - it's handled in it's own page now. Ok to show last X events here with option to edit
  //  but that option should open the route /events/manager and pass the event ID + edit action as params
  eventModal = () => {
    const event = this.state.selectedEvent;

    return (
      <Modal
        isOpen={this.state.showEventsModal}
        onClosed={() => this.onCloseModal('event')}
        toggle={() => this.onCloseModal('event')}
        // style={{ maxWidth: 600 }}
        className="modal-dialog-sm"
        fade
      >
        <div className="modal-header">
          <h5 className="modal-title">New Event</h5>
          <Button className="close" color="" onClick={() => this.onCloseModal('event')}>
            <Icon name="x" />
          </Button>
        </div>
        <ModalBody>
          <FormGroup>
            <Label for="dropzone">Images</Label>
            {event.image_url ? (
              <div>
                <div style={{ display: 'inline-block', verticalAlign: 'middle' }}>
                  <img src={event.image_url} alt="" style={{ width: 100, float: 'left' }} />
                </div>
                <div style={{ height: 100, display: 'inline-block', verticalAlign: 'middle' }}>
                  <div style={{ marginTop: 24 }}>
                    <span style={{ marginLeft: 20 }}>{event.image_url}</span>
                    <br />
                    <a href="#" style={{ marginLeft: 20 }}>Replace image</a>
                  </div>
                </div>
              </div>
            ) : (
              <div id="event-dropzone">
                <Dropzone
                  config={{
                    postUrl: 'no-url',
                    iconFiletypes: ['.jpg', '.png'],
                  }}
                  djsConfig={{
                    dictDefaultMessage: 'Add images or drop here to upload',
                    previewTemplate: djsPreviewTemplate,
                  }}
                  eventHandlers={this.dropzoneEventHandlers('eventImages')}
                />
              </div>
            )}
            <span style={{ color: '#999999' }}>Your image needs to be at least 500 x 500 pixels.</span>
          </FormGroup>
          <FormGroup>
            <Label for="name">Event Name</Label>
            <Input id="name" value={event.name} />
          </FormGroup>
          <FormGroup>
            <Label for="description">Description</Label>
            <textarea className="form-control" id="description" style={{ height: 125 }} value={event.description} />
          </FormGroup>
          <Row>
            <Col>
              <FormGroup>
                <Label for="picker-start-time">Start Time</Label>
                <div id="picker-start-time">
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DateTimePicker
                      inputVariant="outlined"
                      className="form-control"
                      value={this.state.selectedStartDate}
                      onChange={this.handleDateChange('selectedStartDate')}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="picker-end-time">End Time</Label>
                <div id="picker-end-time">
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DateTimePicker
                      inputVariant="outlined"
                      className="form-control"
                      value={this.state.selectedEndDate}
                      onChange={this.handleDateChange('selectedEndDate')}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => this.onCloseModal('event')}>Cancel</Button>
          <Button color="brand" onClick={() => this.onSaveModal('event')}>Publish Event</Button>
        </ModalFooter>
      </Modal>
    );
  };

  dialogModal = () => (
    <Dialog
      open={this.state.dialogModal.show}
      onClose={this.closeDialogModal}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{this.state.dialogModal.title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {this.state.dialogModal.text}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={this.closeDialogModal}>
          Cancel
        </Button>
        <Button
          onClick={() => {
            this.closeDialogModal();
            this.deleteVenueConfirmed();
          }}
          variant="outlined"
          color="danger"
          autoFocus
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );

  closeDialogModal = () => {
    this.setState({
      dialogModal: {
        show: false,
        title: '',
        text: '',
      },
    });
  };

  // FIXME Make this a re-usable component
  pagePickerModal = () => (
    <Modal
      isOpen={this.state.showPagePickerModal}
      toggle={() => this.onCloseModal('page')}
      onClosed={() => this.onCloseModal('page')}
    >
      <div className="modal-header">
        <h5 className="modal-title">Select Page</h5>
      </div>
      <div className="modal-body">
        <TouchSpin
          max={Math.ceil(this.props.VenueManager.totalSize / this.state.sizePerPage)}
          min={1}
          step={1}
          value={this.state.number || (!this.state.edited && this.state.page)}
          onChange={(value) => {
            this.setState({ edited: true, number: value });
          }}
        />
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-outline-secondary" onClick={() => this.onCloseModal('page')}>
          {'Cancel'}
        </button>
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => {
            this.handlePageChange(null, this.state.number);
            this.onCloseModal('page');
          }}
        >
          {'Go'}
        </button>
      </div>
    </Modal>
  );
}

const mapStateToProps = ({ VenueManager }) => ({ VenueManager });

export default connect(
  mapStateToProps, {
    getAllVenues,
    searchVenues,
    postVenue,
    putVenue,
  },
)(VenueTable);
