import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import cellEditFactory from 'react-bootstrap-table2-editor';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { connect } from 'react-redux';
import VoucherCodes from 'voucher-code-generator';
import { InputGroup, InputGroupAddon, Input, Modal, Label } from 'reactstrap';
import { isMobile } from 'react-device-detect';
import '../../assets/css/linked-cigars.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import SweetAlert from 'react-bootstrap-sweetalert';
import Button from '@material-ui/core/Button';
import Select from 'react-select';
import FormControl from '@material-ui/core/FormControl';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';
import DatePicker from 'reactstrap-date-picker';
import axios from 'axios';
import NoDataIndicator from '../../components/no-data-indicator';
import TableLoader from '../../components/table-loader';
import RowMoreOptions from '../../components/table/row-more-options';
import { getDiscounts } from '../../redux/actions/shop.actions';
import Breadcrumb from '../../components/common/breadcrumb.component';
import { Constants } from '../../constants';

const { SearchBar } = Search;

const defaultSorted = [{
  dataField: 'name',
  order: 'asc',
}];

const cellEditProps = {
  mode: 'dbclick',
};

const discountTypes = [{
  label: 'Percentage',
  value: 'percentage',
}, {
  label: 'Fixed Amount',
  value: 'fixed',
}, {
  label: 'Gift Card',
  value: 'gift-card',
}, {
  label: 'Free Shipping',
  value: 'free-shipping',
}, {
  label: 'Buy X Get Y',
  value: 'buyxgety',
}];

const defaults = {
  message: {
    type: 'info',
    show: false,
    title: '',
    text: '',
  },
  requirementType: 'none',
  eligibility: 'everyone',
  limitTotal: false,
  limitCustomer: false,
};

class DiscountTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      // data: data.data,
      // totalSize: data.recordsFiltered,
      sizePerPage: 10,
      showCreateDiscountModal: false,
      ...defaults,
    };
    this.handleTableChange = this.handleTableChange.bind(this);
    this.toggleTooltip = this.toggleTooltip.bind(this);
  }

  componentDidMount() {
    this.handleTableChange(null, { page: 1, sizePerPage: 30 });
  }

  toggleTooltip(key) {
    return () => {
      this.setState({
        [key]: !this.state[key],
      });
    };
  }

    handleChange = (key) => (e) => {
      this.setState({ [key]: e.target.value });
    };

    handleProductChange = (key) => (event) => {
      const product = this.state.selectedProduct;
      product[key] = event.target.value;
      this.setState({ selectedProduct: product });
    };

    handleEditorStateChange = (editorState) => {
      this.setState({
        editorState,
      });
    };

    handleInputChange = (key) => (event) => {
      console.log(key);
      console.log(event.target.value);
      this.setState({ [key]: event.target.value });
    };

    handleAddressChange = (key) => (event) => {
      console.log(key);
      console.log(event.target.value);
      // FIXME Should update locally - not in the actual Snipcart store
      // if (this.state.editingShippingAddress) {
      //     this.setState({
      //         shippingAddress: {
      //             ...this.state.shippingAddress,
      //             [key]: event.target.value
      //         }
      //     });
      // } else {
      //     this.setState({
      //         billingAddress: {
      //             ...this.state.billingAddress,
      //             [key]: event.target.value
      //         }
      //     });
      // }
    };

    handleSelectChange = (key) => (value) => {
      this.setState({ [key]: value });
    };

    handleCheckboxChange = (key) => {
      console.log('Check change:');
      console.log(key);
      return (event) => {
        console.log(event.target.checked ? 'Checked' : 'Not checked');
        this.setState({ [key]: event.target.checked });
      };
    };

    handleRadioChange = (key) => {
      console.log('Check change:');
      console.log(key);
      return (event) => {
        console.log(event.target.checked ? 'Checked' : 'Not checked');
        this.setState({ [key]: event.target.value });
      };
    };

    handleLinkedCigarChange = (index, type) => {
      const { linkedCigars } = this.state;
      console.log(linkedCigars);
      const linkedCigar = linkedCigars[index] || {};
      return (value) => {
        linkedCigar[type] = value;
        linkedCigars[index] = linkedCigar;
        this.setState({ linkedCigars }, () => {
          console.log('State updated!');
          if (type === 'cigar') {
            console.log('Running this.loadVitolas(index)...');
            this.loadVitolas(index);
          }
        });
      };
    };

    keyForType = (type) => {
      switch (type) {
        case 'discount':
          return 'showCreateDiscountModal';
        default:
          return '';
      }
    };

    onCloseModal = (type) => {
      this.setState({
        [this.keyForType(type)]: false,
      });
    };

    onOpenModal = (type, row) => {
      this.setState({ [this.keyForType(type)]: true });
    };

    onSaveModal = (type) => {
      switch (type) {
        case 'discount': {
          const {
            discountType,
            discountValue,
            discountCode,
            requirementType,
            eligibility,
            limitTotal,
            limitCustomer,
          } = this.state;
          axios.post(`${Constants.apiPath}/shop/discounts`, {
            type: discountType.value,
            amount: discountValue,
            name: discountCode,
            code: discountCode,
            requirement_type: requirementType === 'none' ? null : requirementType, // Either `amount` or `quantity`
            // requirement, // TODO
            // eligibility, // FIXME This should be an array of user IDs - for now, we don't need this
            // usage_limits, // FIXME How should this get handled?
          }).then(() => {
            this.onCloseModal('discount');
            this.setState({ ...this.state, ...defaults });
            this.handleTableChange(null, { page: 1, sizePerPage: 30 }); // FIXME This should use the current page and size / search query
          }).catch((err) => {
            console.log(err);
          });
        }
          break;
        default:
          this.onCloseModal(type);
          break;
      }
    };

    itemFormatter = (items, row) => {
      if (items) {
        return (
          <label>
            {items.map((item) => <p>{`${item.quantity}x ${item.name}`}</p>)}
          </label>
        );
      }
      return null;
    };

    itemQuantityFormatter = (items, row) => {
      if (items) {
        return (
          <label>{items.length}</label>
        );
      }
      return null;
    };

    priceFormatter = (cell, row) => {
      if (cell) {
        let price = cell;
        if (typeof price === 'number' || (typeof price === 'string' && price.indexOf('$') === -1)) {
          // FIXME This should be fixed on the server, but since the dev db and prod db aren't configured the same, we need this. Once configured correctly, remove this
          price = `$${parseFloat(cell).toFixed(2)}`;
        }
        return (
          <label>{price}</label>
        );
      }
      return null;
    };

    checkBoxFormatter = (cell, row) => (
      <div className="checkbox checkbox-secondary">
        <input name="checkbox" checked={cell} type="checkbox" />
        <label></label>
      </div>
    );

    actionDropdown = (index, product) => (
      <RowMoreOptions
        row={index}
        items={[{
          type: 'MenuItem',
          onClick: () => {},
          title: 'TODO',
        }]}
        onShow={() => {
          // Hide the row buttons
          document.querySelector(`#dropdown-actions-${index}`).classList.add('d-none');
        }}
      />
    );

    requirementFormatter = (cell, row, index) => {
      console.log(`Index rendered: ${index}`);
      if (cell) {
        // TODO Needs to use row.requirement_type to determine the formatting
        return (
          <span>{cell}</span>
        );
      }
      return 'None';
    };

    actionFormatter = (cell, row, index) => {
      console.log(`Index rendered: ${index}`);
      if (row.type === 'percentage') {
        return (
          <span>{`${cell}% OFF`}</span>
        );
      } if (row.type === 'fixed') {
        return (
          <span>{`${cell}% OFF`}</span>
        );
      } if (row.type === 'gift-card') {
        return (
          <span>{`$${cell} Remaining Balance`}</span>
        );
      }
      return 'N/A';
    };

    usageFormatter = (cell, row, index) =>
    // TODO
      '0'
    ;

    maxUsageFormatter = (cell, row, index) =>
    // TODO
      'Unlimited'
    ;

    expirationFormatter = (cell, row, index) =>
    // TODO
      'No Expiration'
    ;

    editBtnFormatter = (cell, row, index) => {
      console.log(`Index rendered: ${index}`);
      return (
        <div style={{ width: 68, float: 'right' }}>
          <div id={`dropdown-actions-${index}`} className="d-none">
            <i className="icon-pencil ml-1 mr-3" style={{ cursor: 'pointer' }} onClick={() => {}} />
            {this.actionDropdown(index, row)}
          </div>
        </div>
      );
    };

    handleTableChange = (type, { page, sizePerPage, filters, sortField, sortOrder, cellEdit, searchText }) => {
      console.log('Updating table...');
      this.props.getDiscounts(page, sizePerPage, {
        // TODO Filter options
      });

      this.setState({
        page,
        sizePerPage,
      });
    };

    closeAlert = () => {
      this.setState({
        message: {
          show: false,
          title: '',
          text: '',
        },
      });
    };

    newDiscountModal = () => {
      const {
        discountType,
        discountValue,
        discountCode,
        requirementType,
        eligibility,
        limitTotal,
        limitCustomer,
        startDate,
        endDate,
      } = this.state;
      return (
        <Modal isOpen={this.state.showCreateDiscountModal} onClosed={() => this.onCloseModal('discount')}>
          <div className="modal-header">
            <h5 className="modal-title">Create New Discount</h5>
          </div>
          <div className="modal-body">
            <span style={{ fontWeight: 700 }}>Type</span>
            <div style={{ marginTop: 10, marginBottom: 10 }}>
              <Select
                value={discountType}
                onChange={this.handleSelectChange('discountType')}
                options={discountTypes.map((type) => ({
                  value: type.value,
                  label: type.label,
                }))}
                getOptionLabel={(option) => `${option.label}`}
                getOptionValue={(option) => `${option}`}
                isOptionSelected={(option) => discountType && (discountType.value === option.value)}
              />
            </div>
            <span style={{ fontWeight: 700 }}>Discount Value</span>
            <div style={{ marginTop: 10, marginBottom: 10 }}>
              <InputGroup>
                {(!discountType || discountType.value !== 'percentage') && <InputGroupAddon addonType="prepend">$</InputGroupAddon>}
                <Input value={discountValue} onChange={this.handleChange('discountValue')} placeholder={discountType && discountType.value === 'percentage' ? 'Percent' : 'Amount'} />
                {discountType && discountType.value === 'percentage' && <InputGroupAddon addonType="append">%</InputGroupAddon>}
              </InputGroup>
            </div>
            <div>
              <span style={{ fontWeight: 700 }}>Discount Code</span>
              <span
                style={{ color: '#d6c290', cursor: 'pointer', float: 'right' }}
                onClick={() => {
                  if (discountType && discountType.label === 'Gift Card') {
                    const code = VoucherCodes.generate({
                      length: 16,
                      count: 1,
                      pattern: '#### #### #### ####',
                      charset: '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ',
                    });
                    this.setState({
                      discountCode: code,
                    });
                  } else {
                    const code = VoucherCodes.generate({
                      length: 8,
                      count: 1,
                      charset: '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ',
                    });
                    this.setState({
                      discountCode: code,
                    });
                  }
                }}
              >
                {'Generate'}
              </span>
            </div>
            <div style={{ marginTop: 10, marginBottom: 10 }}>
              <input
                className="form-control"
                value={discountCode}
                // style={{textTransform: 'capitalize'}}
                onChange={this.handleChange('discountCode')}
              />
            </div>
            <span style={{ fontWeight: 700 }}>Minimum Requirements</span>
            <div style={{ marginTop: 10, marginBottom: 10 }}>
              <FormControl component="fieldset">
                <RadioGroup aria-label="requirements" name="min-requirements" value={requirementType} onChange={this.handleRadioChange('requirementType')}>
                  <FormControlLabel value="none" control={<Radio />} label="None" />
                  <FormControlLabel value="amount" control={<Radio />} label="Minimum purchase amount ($)" />
                  <FormControlLabel value="quantity" control={<Radio />} label="Minimum quantity of items" />
                </RadioGroup>
              </FormControl>
            </div>
            <span style={{ fontWeight: 700 }}>Customer Eligibility</span>
            <div style={{ marginTop: 10, marginBottom: 10 }}>
              <FormControl component="fieldset">
                <RadioGroup aria-label="customer eligibility types" name="eligibility-types" value={eligibility} onChange={this.handleRadioChange('eligibility')}>
                  <FormControlLabel value="everyone" control={<Radio />} label="Everyone" />
                  {/* <FormControlLabel value="groups" control={<Radio />} label="Specific groups of customers" /> */}
                  <FormControlLabel value="customers" control={<Radio />} label="Specific customers" />
                </RadioGroup>
              </FormControl>
            </div>
            <span style={{ fontWeight: 700 }}>Usage Limits</span>
            <div style={{ marginTop: 10, marginBottom: 10 }}>
              <FormControl component="fieldset">
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox checked={limitTotal} onChange={this.handleCheckboxChange('limitTotal')} name="limit-total" />}
                    label="Limit number of times this discount can be used in total"
                  />
                  <FormControlLabel
                    control={<Checkbox checked={limitCustomer} onChange={this.handleCheckboxChange('limitCustomer')} name="limit-customer" />}
                    label="Limit to one use per customer"
                  />
                </FormGroup>
              </FormControl>
            </div>
            <span style={{ fontWeight: 700 }}>Active Dates</span>
            <div style={{ marginTop: 10, marginBottom: 10 }}>
              <div className="row">
                <div className="col-md-6">
                  <FormGroup>
                    <Label>Start Date</Label>
                    <DatePicker id="start-datepicker" dateFormat="MM/DD/YYYY" value={startDate} onChange={(v, f) => this.handleChange(v, f)} />
                  </FormGroup>
                </div>
                <div className="col-md-6">
                  <FormGroup>
                    <Label>End Date</Label>
                    <DatePicker id="end-datepicker" dateFormat="MM/DD/YYYY" value={endDate} onChange={(v, f) => this.handleChange(v, f)} />
                  </FormGroup>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <Button onClick={() => this.onCloseModal('discount')}>Cancel</Button>
            <Button
              onClick={() => this.onSaveModal('discount')}
              variant="contained"
              color="secondary"
            >
              {'Create'}
            </Button>
          </div>
        </Modal>
      );
    };

    render() {
      const { sizePerPage, page } = this.state;
      const { discounts, totalDiscounts, loadingDiscounts, error } = this.props.ShopManager;

      const columns = [{
        dataField: 'name',
        text: 'Name',
        sort: true,
        editable: false,
      }, {
        dataField: 'requirement',
        text: 'Requirements',
        sort: true,
        editable: false,
        formatter: this.requirementFormatter,
      }, {
        dataField: 'amount',
        text: 'Action',
        sort: true,
        editable: false,
        formatter: this.actionFormatter,
      }, {
        dataField: '',
        text: 'Number of usages',
        sort: true,
        editable: false,
        formatter: this.usageFormatter,
      }, {
        dataField: '',
        text: 'Maximum number of usages',
        sort: true,
        editable: false,
        formatter: this.maxUsageFormatter,
      }, {
        dataField: 'endDate',
        text: 'Expires',
        sort: true,
        editable: false,
        formatter: this.expirationFormatter,
      }, {
        dataField: 'edit',
        text: '',
        formatter: this.editBtnFormatter,
        sort: true,
      }];

      const rowEvents = {
        onMouseEnter: (e, row, index) => {
          // console.log("Mouse entered: " + index);
          document.querySelector(`#dropdown-actions-${index}`).classList.remove('d-none');
        },
        onMouseLeave: (e, row, index) => {
          document.querySelector(`#dropdown-actions-${index}`).classList.add('d-none');
        },
        onDoubleClick: (e, row, index) => {
          // INFO If we don't include this event, the double click to edit doesn't work
          // console.log(e);
        },
      };

      const pageButtonRenderer = ({ page, active, onPageChange }) => {
        const handleClick = (e) => {
          e.preventDefault();
          onPageChange(page);
        };
        let classname = 'btn btn-outline-secondary';
        if (active) {
          classname = 'btn btn-secondary';
        }
        return (
          <li className="page-item pl-1" key={page}>
            <a href="#" onClick={handleClick} className={classname}>{ page }</a>
          </li>
        );
      };

      // TODO https://www.npmjs.com/package/react-device-detect use a different layout for mobile
      return (
        <div>
          <SweetAlert
            show={this.state.message.show}
            type={this.state.message.type}
            title={this.state.message.title}
            onConfirm={this.closeAlert}
          >
            {this.state.message.text}
          </SweetAlert>

          <Breadcrumb title="Discounts" label="Discounts" parent="Shop" />

          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="card mb-0">
                  <div className="card-body datatable-react">
                    <ToolkitProvider
                      keyField="id"
                      data={discounts}
                      columns={columns}
                      search
                    >
                      {
                        (toolkitprops) => (
                          <div>
                            {/* Known issue https://github.com/react-bootstrap-table/react-bootstrap-table2/issues/787 */}
                            <SearchBar {...toolkitprops.searchProps} delay={800} />
                            {/* <ClearSearchButton { ...props.searchProps } /> */}
                            <button
                              className="btn btn-primary mr-2"
                              onClick={() => {
                                this.onOpenModal('discount');
                              }}
                              style={isMobile ? { padding: '6px 14px', float: 'right' } : { float: 'right' }}
                            >
                              {isMobile ? (<i className="icon icon-plus" />) : 'Create New Discount'}
                            </button>
                            <BootstrapTable
                              remote
                              keyField="id"
                              data={discounts}
                              columns={columns}
                              rowEvents={rowEvents}
                              defaultSorted={defaultSorted}
                              wrapperClasses="table-responsive"
                              pagination={paginationFactory({ pageButtonRenderer, page, sizePerPage, totalDiscounts })}
                              cellEdit={cellEditFactory(cellEditProps)}
                              // filter={ filterFactory() }
                              onTableChange={this.handleTableChange}
                              noDataIndication={() => (!loadingDiscounts ? (error ? <NoDataIndicator message={`${error.message}. Please contact an admin if the problem persists.`} /> : <NoDataIndicator message="No discounts found." />) : <TableLoader />)}
                              {...toolkitprops.baseProps}
                            />
                          </div>
                        )
                      }
                    </ToolkitProvider>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {this.newDiscountModal()}
        </div>
      );
    }
}

const mapStateToProps = ({ ShopManager }) => ({ ShopManager });

export default connect(
  mapStateToProps, {
    getDiscounts,
  }
)(DiscountTable);
