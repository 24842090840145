import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { ScrollContext } from 'react-router-scroll-4';
import axios from 'axios';
import * as Sentry from '@sentry/browser';
import ls from 'local-storage';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.scss';

import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

import * as serviceWorker from './serviceWorker';

// ** Import custom components **
import store from './store';
import App from './App';

import CigarDashboard from './pages/CigarDashboard';
import CigarManager from './pages/CigarManager';
import PendingCigarManager from './pages/CigarsPending';
import CigarMatchManager from './pages/CigarMatches';
import BrandManager from './pages/BrandManager';
import BrandClaimManager from './pages/BrandClaims';
import GroupManager from './pages/GroupManager';
import DrinkManager from './pages/DrinkPairings';
import VenueManager from './pages/VenueManager';
import PendingVenueManager from './pages/VenuesPending';
import VenueClaimManager from './pages/VenueClaims';
import VenueDashboard from './pages/VenueDashboard';
import ShopDashboard from './pages/ShopDashboard';
import ShopManager from './pages/ProductManager';
import EventManager from './pages/EventManager';
import AbandonedCarts from './pages/AbandonedCarts';
import OrderManager from './pages/Orders';
import Discounts from './pages/Discounts';
import TaxConfig from './pages/TaxConfig';
import ShippingConfig from './pages/ShippingConfig';
import PaymentGatewayConfig from './pages/PaymentGatewayConfig';
import Error404 from './pages/404';
import Login from './pages/Auth/Login';
// import Register from './pages/Auth/SignUp';
import ForgetPassword from './pages/Auth/ForgotPassword';
import ResetPassword from './pages/Auth/ResetPassword';
import ComingSoon from './pages/ComingSoon';
import Maintenance from './pages/Maintenance';
import Notifications from './pages/Notifications';
import PrivateRoute from './components/private-route';
import firebaseApp from './firebase.config';
import { UserStore } from './stores';

import CacheBuster from './utils/cacheBuster';
import { initAxiosInterceptor, initAxiosRetry } from './utils/axiosAuthHelper';
import CigarBandRecognition from './pages/CigarBandRecognition';
// FIXME https://stackoverflow.com/questions/44114436/the-create-react-app-imports-restriction-outside-of-src-directory/44115058
// import packageJson from '../package';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#212121',
    },
    secondary: {
      // light: will be calculated from palette.secondary.main,
      main: '#d6c290',
      // dark: will be calculated from palette.secondary.main,
      contrastText: '#212121',
    },
    danger: {
      main: '#ff4700',
    },
    error: {
      main: '#ff4700',
    },
  },
  // FIXME https://github.com/mui-org/material-ui/issues/13875 Can't get custom palette names to work on Buttons, etc
  //       For now, using a custom component wrapper for Buttons
  // overrides: {
  //     MuiButton: {
  //         textDanger: {
  //             color: '#ff4700',
  //         },
  //         outlinedDanger: {
  //             color: '#ff4700',
  //             borderColor: '#ff4700'
  //         },
  //         containedDanger: {
  //             backgroundColor: '#ff4700',
  //         },
  //     },
  // },
});

// if (ls('boxpressd_admin_token_id')) {
//   axios.defaults.headers.common.Authorization = `Bearer ${ls('boxpressd_admin_token_id')}`;
// }

// TODO Update to these
initAxiosInterceptor();
initAxiosRetry();

// axios.interceptors.request.use(async (requestConfig) => {
//   console.log('Linking auth token in interceptor...');
//   // FIXME The problem with doing this is that the Axios calls seem to happen before firebase auth initialized the user
//   // requestConfig.headers.common.Authorization = `Bearer ${await firebaseApp.auth().currentUser?.getIdToken()}`;
//   const tokenId = ls('boxpressd_admin_token_id');
//   if (tokenId) {
//     requestConfig.headers.common.Authorization = `Bearer ${tokenId}`;
//   }
//   return requestConfig;
// }, (error) => Promise.reject(error));
//
// axios.interceptors.response.use((response) => response, async (error) => {
//   console.log('=== Axios Error ===');
//   console.log(error.config);
//   console.log(error);
//
//   if (error.response && error.response.status === 403) {
//     if (error.response.data.code && error.response.data.code === 'auth/id-token-expired') {
//       console.log('The token is expired.');
//       if (firebaseApp.auth().currentUser) {
//         const tokenId = await firebaseApp.auth().currentUser.getIdToken(true);
//         ls('boxpressd_admin_token_id', tokenId);
//         console.log('Got new token in interceptor');
//         console.log(tokenId);
//         // eslint-disable-next-line no-param-reassign
//         error.config.headers.Authorization = `Bearer ${tokenId}`;
//         // eslint-disable-next-line no-param-reassign
//         error.config.baseURL = undefined;
//         // return axios.request(error.config); // FIXME This is what causes a loop - it should only retry so many times here
//       }
//     }
//     const urlParams = new URLSearchParams(window.location.search);
//     const userToken = urlParams.get('token');
//     axios.defaults.headers.common.Authorization = userToken;
//     if (userToken) {
//       console.log('Trying call with token from query param:');
//       console.log(userToken);
//       // eslint-disable-next-line no-param-reassign
//       error.config.headers.Authorization = `Bearer ${userToken}`;
//       // eslint-disable-next-line no-param-reassign
//       error.config.baseURL = undefined;
//       return axios.request(error.config);
//     }
//   }
//   // FIXME Will redirect to auth service..?
//   return Promise.reject(error);
// });

// export const initAxiosRetry = () => {
//   axiosRetry(axios, {
//     retries: 5,
//     retryDelay: exponentialDelay,
//     retryCondition: async (e) => {
//       console.log('Axios retry response...');
//       console.log(e);
//       console.log(e.response);
//       if (e.response.status === 403) {
//         console.log('Generating new auth token in axios retry condition...');
//         const user = firebaseApp.auth().currentUser;
//         if (user) {
//           await user.getIdToken(true).then((tokenId) => {
//             console.log('Got new auth token in axios retry condition...');
//             // axios.defaults.headers.common.Authorization = `Bearer ${tokenId}`;
//             ls('boxpressd_admin_token_id', tokenId);
//           });
//           return true;
//         }
//         return false;
//       }
//       return e.response.status === 429;
//     },
//   });
// };
// initAxiosRetry();

class Root extends React.Component {
  state = {
    authenticated: UserStore.getRawState().authenticated,
    currentUser: null,
    userToken: null,
    loading: true,
  };

  componentWillMount() {
    firebaseApp.auth().onAuthStateChanged(async (user) => {
      if (user) {
        UserStore.update((s) => {
          s.firebaseUser = user;
          s.authenticated = true;
        });
        console.log('User is logged in!');
        Sentry.setUser({
          email: user.email,
        });
        await user.getIdToken().then((data) => {
          ls('boxpressd_admin_token_id', data);
        });
      }
      // else {
      //   console.log('User is logged out!');
      //   // cookies.set('tokenId', '', { path: '/' });
      //   ls('boxpressd_admin_token_id', 0);
      //   this.setState({
      //     authenticated: false,
      //     currentUser: null,
      //     userToken: null,
      //     loading: false,
      //   });
      // }
    });

    // axios.interceptors.request.use(async function (config) {
    //     let user = firebaseApp.auth().currentUser;
    //     if (user) {
    //         await user.getIdToken().then((data) => {
    //             // console.log(JSON.stringify(data));
    //             cookies.set('tokenId', data, {path: '/'});
    //             this.setState({
    //                 authenticated: true,
    //                 currentUser: user,
    //                 userToken: data,
    //                 loading: false
    //             });
    //         });
    //     }
    //     return config;
    // }, function (error) {
    //     return Promise.reject(error);
    // });
  }

  // componentDidMount() {
  //     firebaseApp.auth().onAuthStateChanged(async user => {
  //         if (user) {
  //             await user.getIdToken().then((data) => {
  //                 // console.log(JSON.stringify(data));
  //                 //cookies.set('tokenId', data, { path: '/' });
  //                 this.setState({
  //                     authenticated: true,
  //                     currentUser: user,
  //                     userToken: data,
  //                     loading: false
  //                 });
  //             });
  //         } else {
  //             this.setState({
  //                 authenticated: false,
  //                 currentUser: null,
  //                 userToken: null,
  //                 loading: false
  //             });
  //         }
  //     });
  // }

  render() {
    const { authenticated, loading } = this.state;

    return (
      <CacheBuster>
        {({ loading, isLatestVersion, refreshCacheAndReload }) => {
          if (loading) return null; // TODO Loading screen?
          if (!loading && !isLatestVersion) {
            refreshCacheAndReload();
          }

          return (
            <Provider store={store}>
              <ThemeProvider theme={theme}>
                <BrowserRouter basename="/">
                  <ScrollContext>
                    <Switch>
                      <Redirect exact from={`${process.env.PUBLIC_URL}/`} to="/cigars/dashboard" />

                      {/* FIXME Move the routes to their own file like in the main app */}
                      <Route path={`${process.env.PUBLIC_URL}/login`} component={Login} />
                      {/*<Route path={`${process.env.PUBLIC_URL}/pages/register`} component={Register} />*/}
                      <Route path={`${process.env.PUBLIC_URL}/pages/forget-password`} component={ForgetPassword} />
                      <Route path={`${process.env.PUBLIC_URL}/pages/reset-password`} component={ResetPassword} />
                      <Route path={`${process.env.PUBLIC_URL}/pages/coming-soon`} component={ComingSoon} />
                      <Route path={`${process.env.PUBLIC_URL}/pages/maintenance`} component={Maintenance} />

                      <App>
                        <PrivateRoute
                          path={`${process.env.PUBLIC_URL}/cigars/dashboard`}
                          component={CigarDashboard}
                          authenticated={authenticated}
                        />
                        <PrivateRoute
                          path={`${process.env.PUBLIC_URL}/cigars/manager`}
                          component={CigarManager}
                          authenticated={authenticated}
                        />
                        <PrivateRoute
                          path={`${process.env.PUBLIC_URL}/targets/manager`}
                          component={CigarBandRecognition}
                          authenticated={authenticated}
                        />
                        <PrivateRoute
                          path={`${process.env.PUBLIC_URL}/drink-pairings/manager`}
                          component={DrinkManager}
                          authenticated={authenticated}
                        />
                        <PrivateRoute
                          path={`${process.env.PUBLIC_URL}/cigars/pending`}
                          component={PendingCigarManager}
                          authenticated={authenticated}
                        />
                        <PrivateRoute
                          path={`${process.env.PUBLIC_URL}/cigars/matches`}
                          component={CigarMatchManager}
                          authenticated={authenticated}
                        />
                        <PrivateRoute
                          path={`${process.env.PUBLIC_URL}/brands/manager`}
                          component={BrandManager}
                          authenticated={authenticated}
                        />
                        <PrivateRoute
                          path={`${process.env.PUBLIC_URL}/brands/claims`}
                          component={BrandClaimManager}
                          authenticated={authenticated}
                        />
                        <PrivateRoute
                          path={`${process.env.PUBLIC_URL}/groups/manager`}
                          component={GroupManager}
                          authenticated={authenticated}
                        />
                        <PrivateRoute
                          path={`${process.env.PUBLIC_URL}/venues/dashboard`}
                          component={VenueDashboard}
                          authenticated={authenticated}
                        />
                        <PrivateRoute
                          path={`${process.env.PUBLIC_URL}/venues/manager`}
                          component={VenueManager}
                          authenticated={authenticated}
                        />
                        <PrivateRoute
                          path={`${process.env.PUBLIC_URL}/venues/pending`}
                          component={PendingVenueManager}
                          authenticated={authenticated}
                        />
                        <PrivateRoute
                          path={`${process.env.PUBLIC_URL}/venues/claims`}
                          component={VenueClaimManager}
                          authenticated={authenticated}
                        />

                        <PrivateRoute
                          path={`${process.env.PUBLIC_URL}/shop/dashboard`}
                          component={ShopDashboard}
                          authenticated={authenticated}
                        />
                        <PrivateRoute
                          path={`${process.env.PUBLIC_URL}/shop/manager`}
                          component={ShopManager}
                          authenticated={authenticated}
                        />
                        <PrivateRoute
                          path={`${process.env.PUBLIC_URL}/shop/carts/abandoned`}
                          component={AbandonedCarts}
                          authenticated={authenticated}
                        />
                        <PrivateRoute
                          path={`${process.env.PUBLIC_URL}/shop/orders`}
                          component={OrderManager}
                          authenticated={authenticated}
                        />
                        <PrivateRoute
                          path={`${process.env.PUBLIC_URL}/shop/discounts`}
                          component={Discounts}
                          authenticated={authenticated}
                        />
                        <PrivateRoute
                          path={`${process.env.PUBLIC_URL}/shop/config/payment-gateways`}
                          component={PaymentGatewayConfig}
                          authenticated={authenticated}
                        />
                        <PrivateRoute
                          path={`${process.env.PUBLIC_URL}/shop/config/taxes`}
                          component={TaxConfig}
                          authenticated={authenticated}
                        />
                        <PrivateRoute
                          path={`${process.env.PUBLIC_URL}/shop/config/shipping`}
                          component={ShippingConfig}
                          authenticated={authenticated}
                        />
                        <PrivateRoute
                          path={`${process.env.PUBLIC_URL}/events/manager`}
                          component={EventManager}
                          authenticated={authenticated}
                        />
                        <PrivateRoute
                          path={`${process.env.PUBLIC_URL}/notifications`}
                          component={Notifications}
                          authenticated={authenticated}
                        />
                      </App>

                      <Route component={Error404} />
                    </Switch>
                  </ScrollContext>
                </BrowserRouter>
              </ThemeProvider>
            </Provider>
          );
        }}
      </CacheBuster>
    );
  }
}

Sentry.init({
  dsn: 'https://d1c8beec9d3248999bf0230f738208e6@o351878.ingest.sentry.io/5298507',
  environment: process.env.NODE_ENV,
  // release: packageJson.version,
});

ReactDOM.render(<Root />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
serviceWorker.register();
