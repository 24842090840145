import React, { useState } from 'react';
import { Card, CardHeader, Table } from 'reactstrap';
import Breadcrumb from '../../components/common/breadcrumb.component';

function CigarBandRecognition(props) {
  const [cigars, setCigars] = useState([]);
  return (
    <div>
      <Breadcrumb title="Cigar Band Image Recognition" label="Image Recognition" parent="Cigar Manager" />

      <div className="container-fluid">
        <Card>
          <CardHeader>
            {/*<h5>Cigar Band Image Recognition</h5>*/}
            <span style={{ float: 'left' }}>
              {'Coming Soon'}
            </span>
            <button
              className="btn"
              onClick={() => window.open(
                'https://us-east-2.console.aws.amazon.com/rekognition/custom-labels',
                '_blank',
              )}
              style={{ backgroundColor: '#ff9900', float: 'right', color: 'white', fontWeight: 600 }}
            >
              {'View in AWS Rekognition'}
            </button>
          </CardHeader>
          <div className="table-responsive">
            <Table size="lg">
              <thead>
                <tr>
                  <th scope="col">ID</th>
                  <th scope="col">Full Name</th>
                  <th scope="col">Training Targets</th>
                  <th scope="col">Test Targets</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {cigars.map((cigar) => (
                  <tr>
                    <td>{cigar.id}</td>
                    <td>{cigar.name}</td>
                    {/* TODO Both of these should map them to a grid of images - clicking on an image should open lightbox or a custom modal with results from AWS */}
                    {/* TODO Also may want to include confidence scores per item if possible - may need to cache them in a new db table unless we can get them from AWS without additional fees */}
                    <td>{cigar.targets.training}</td>
                    <td>{cigar.targets.testing}</td>
                    {/* TODO Edit button or menu dropdown with options */}
                    <td>...</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </Card>
      </div>
    </div>
  );
}

export default CigarBandRecognition;
