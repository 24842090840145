import React from 'react';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { Input, Label, Modal, Spinner } from 'reactstrap';
import { connect } from 'react-redux';
import Lightbox from 'react-image-lightbox';
import { isMobile } from 'mobile-device-detect';
import queryString from 'query-string';
import axios from 'axios';
import SweetAlert from 'react-bootstrap-sweetalert';
import Cookies from 'universal-cookie';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Badge from '@material-ui/core/Badge';
import Icon from '../../components/icon';
import { renderTimestamp } from '../../utils/formatting';
import RowMoreOptions from '../../components/table/row-more-options';
import Placeholder from '../../config/placeholder.config';
import Button from '../../components/overrides/button';
import { Constants } from '../../constants';
import {
  getCigarMatches,
  getCigarMatchById, getAllCigars,
} from '../../redux/actions/cigar.actions';
import Breadcrumb from '../../components/common/breadcrumb.component';

const defaults = {
  selectedMatchId: null,
  selectedCigar: '',
  photoIndex: 0,
  isOpen: false,
  error: {
    show: false,
    title: '',
    message: '',
  },
};

class CigarTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      sizePerPage: 10,
      showModal: false,
      submitting: false,
      alertUser: true,
      ...defaults,
    };
  }

  componentDidMount() {
    const query = queryString.parse(this.props.location.search);
    if (query.id) {
      // TODO Get data for specified match request ID
      this.props.getCigarMatchById(query.id);
    } else {
      this.props.getCigarMatches(1, 30);
    }
  }

  keyForType = (type) => {
    switch (type) {
      case 'choose':
        return 'showModal';
      // TODO Other types? Confirmations?
      default:
        return '';
    }
  };

  onOpenModal = (type, matchId) => {
    this.setState({
      [this.keyForType(type)]: true,
      selectedMatchId: matchId,
    });
  };

  onCloseModal = (type) => {
    this.setState({
      [this.keyForType(type)]: false,
      ...defaults,
    });
  };

  onSaveModal = (type) => {
    console.log('Submitting!');
    if (type === 'choose') {
      const { selectedMatchId, selectedCigar, alertUser } = this.state;
      console.log(`Match ID: ${selectedMatchId}`);
      console.log(`Cigar ID: ${selectedCigar}`);

      this.setState({
        submitting: true,
      });

      // FIXME This needs tested
      axios.put(`${Constants.apiPath}/cigars/matches/${selectedMatchId}`, { id: selectedCigar.value, notify: alertUser }).then((res) => {
        this.setState({
          [this.keyForType(type)]: false,
          submitting: false,
          ...defaults,
        });
        // TODO Refresh the list, for now reset the page?
        window.location.reload();
      }).catch((e) => {
        console.log(e.message);
        this.setState({
          submitting: false,
          error: {
            show: true,
            title: 'Unable to update match information',
            message: e.message,
          },
        });
      });
    }
  };

  handleChange = (key) => (value) => {
    this.setState({ [key]: value });
  };

  loadCigars = (inputValue, callback) => {
    console.log(`Loading cigars for query "${inputValue}"`);
    // FIXME This should debounce - only solutions I see require lodash.debounce (_.debounce)
    this.props.getAllCigars(1, 20, inputValue);
    setTimeout(() => { // FIXME How do we wait or listen for the change instead?
      if (typeof callback === 'function') {
        callback(this.props.CigarManager.cigars.map((cigar) => {
          console.log(cigar);
          return {
            value: cigar.id,
            label: cigar.full_name,
            images: cigar.images,
          };
        }));
      }
    }, 500);
  };

  getImage = (cigar) => {
    let imageUrl = null;

    if (cigar.images && cigar.images.length > 0) {
      console.log('Cigar images:');
      console.log(cigar.images);

      for (let i = 0; i < cigar.images.length; i++) {
        const image = cigar.images[i];
        if (image.image_type === 0) {
          // Main band
          imageUrl = image.image_url;
          break;
        } else if (image.image_type === 1) {
          // Full band
          imageUrl = image.image_url;
          break;
        }
      }
    }

    return imageUrl;
  };

  closeAlert = () => {
    this.setState({
      error: {
        show: false,
        title: '',
        message: '',
      },
    });
  };

  toggleMoreOptions = (match) => {
    this.setState({
      selectedMatch: match,
      showMoreOptions: !this.state.showMoreOptions,
    });
  };

  render() {
    const { photoIndex, isOpen } = this.state;
    const { pendingMatches, loading, matchIdNotFound } = this.props.CigarManager;

    // TODO https://www.npmjs.com/package/react-device-detect change for mobile
    return (
      <div>
        <SweetAlert
          show={this.state.error.show}
          type="danger"
          title={this.state.error.title}
          onConfirm={this.closeAlert}
        >
          {this.state.error.message}
        </SweetAlert>

        <Breadcrumb title="Pending Matches" parent="Manager" />
        <div className="container-fluid">
          <div className="row">
            {/* TODO Loading skeleton cards */}
            {!loading && !matchIdNotFound && pendingMatches.length === 0 && (
              <div
                style={{
                  textAlign: isMobile ? 'center' : 'left',
                  width: '100%',
                }}
              >
                <div
                  style={{
                    textAlign: isMobile ? 'center' : 'left',
                    margin: 20,
                  }}
                >
                  {'All caught up!'}
                </div>
              </div>
            )}
            {!loading && matchIdNotFound && (
              <div
                style={{
                  textAlign: isMobile ? 'center' : 'left',
                  width: '100%',
                }}
              >
                <div
                  style={{
                    textAlign: isMobile ? 'center' : 'left',
                    margin: 20,
                  }}
                >
                  {'A match for this ID was not found'}
                </div>
                <Button
                  onClick={() => window.location.href = '/cigars/matches'}
                  variant="contained"
                  color="secondary"
                  style={{ marginLeft: isMobile ? 0 : 30 }}
                >
                  {'Clear filter'}
                </Button>
              </div>
            )}
            {pendingMatches.map((match, i) => match.scan && (
              <div className="col-sm-12 col-md-6 col-lg-5 col-xl-4">
                <div className="card height-equal" style={{ height: 500 }}>
                  <div
                    className="card-header"
                    style={{
                      display: 'flex',
                      padding: 5,
                    }}
                  >
                    <Avatar
                      src={match.scan.user.image_url}
                      style={{
                        height: 28,
                        width: 28,
                        margin: '8px 12px',
                      }}
                    >
                      {match.scan.user.first_name.charAt(0)}
                    </Avatar>
                    <div style={{ flex: 1 }}>
                      <div
                        style={{
                          fontWeight: 600,
                          color: '#2b2b2b !important',
                        }}
                      >
                        {`${match.scan.user.first_name} ${match.scan.user.last_name}`}
                      </div>
                      <div style={{ fontSize: 12 }}>
                        {/* FIXME When users already have a scan, cigar will be null here unless we merge scans and update the match request table with the main scan ID */}
                        {(match.matched && match.scan.cigar) ? `Matched as ${match.scan.cigar.full_name} - ` : 'Request sent'}
                        {' '}
                        {renderTimestamp(match.timestamp)}
                      </div>
                    </div>
                    <RowMoreOptions
                      style={{ paddingTop: 10 }}
                      row={i}
                      items={[{
                        type: 'MenuItem',
                        onClick: () => this.onOpenModal('choose', match.id),
                        title: 'Choose Cigar',
                      }, {
                        type: 'MenuItem',
                        onClick: () => {
                        },
                        title: 'Request Additional Photo',
                      }, {
                        type: 'MenuItem',
                        onClick: () => {
                        },
                        title: 'Request Purchase Location',
                      }, {
                        type: 'Divider',
                      }, {
                        type: 'MenuItem',
                        onClick: () => {
                        },
                        style: { color: '#ff4700' },
                        title: 'Delete',
                      }]}
                    />
                  </div>
                  <div
                    className="card-body native-image-bg"
                    style={{
                      backgroundImage: `url('${match.image_url}')`,
                      backgroundSize: 'cover',
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'center',
                    }}
                    onClick={() => {
                      console.log(pendingMatches[photoIndex].image);
                      this.setState({
                        photoIndex: i,
                        isOpen: true,
                      });
                    }}
                  />
                  <div
                    style={{
                      display: match.matched ? 'block' : 'none',
                      position: 'absolute',
                      maxWidth: '100%',
                      top: 180,
                    }}
                  >
                    <img
                      src="https://cdn.boxpressd.io/assets/img/matched-stamp.png"
                      style={{
                        maxWidth: '100%',
                        transform: 'rotate(-45deg)',
                        filter: 'invert(12%) sepia(83%) saturate(4496%) hue-rotate(356deg) brightness(95%) contrast(79%)',
                      }}
                    />
                  </div>
                  {/* <div className="card-footer" style={{flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}> */}
                  {/*    <button type="button" className="btn btn-outline-danger" onClick={() => console.log("TODO Confirm delete / merge")}><i className='icon-trash'/> </button> */}
                  {/*    <button type="button" className="btn btn-primary ml-2" onClick={() => this.onOpenModal('choose', match.id)}>Choose</button> */}
                  {/* </div> */}
                </div>
              </div>
            ))}
          </div>
        </div>
        {isOpen && (
          <Lightbox
            mainSrc={pendingMatches[photoIndex].image_url}
            nextSrc={pendingMatches[(photoIndex + 1) % pendingMatches.length].image_url}
            prevSrc={pendingMatches[(photoIndex + pendingMatches.length - 1) % pendingMatches.length].image_url}
            imageTitle={`${photoIndex + 1}/${pendingMatches.length}`}
            onCloseRequest={() => this.setState({ isOpen: false })}
            onMovePrevRequest={() => this.setState({
              photoIndex: (photoIndex + pendingMatches.length - 1) % pendingMatches.length,
            })}
            onMoveNextRequest={() => this.setState({
              photoIndex: (photoIndex + 1) % pendingMatches.length,
            })}
          />
        )}
        {this.chooserModal()}
      </div>
    );
  }

  chooserModal = () => (
    <Modal scrollable isOpen={this.state.showModal} onClosed={() => this.onCloseModal('choose')}>
      <div className="modal-header">
        <h5 className="modal-title">Choose Cigar</h5>
      </div>
      <div className="modal-body">
        {isMobile && (
          <div>
            <input
              className="form-control sticky"
              placeholder="Search cigars..."
              onChange={(event) => this.loadCigars(event.target.value)}
            />
            <List>
              {this.props.CigarManager.cigars.length > 0 && this.props.CigarManager.cigars.map((cigar) => (
                <ListItem
                  key={`cigar-item-${cigar.id}`}
                  onClick={() => {
                    this.setState({
                      selectedCigar: {
                        value: cigar.id,
                        label: cigar.full_name,
                      },
                    });
                  }}
                >
                  <Badge
                    color="secondary"
                    overlap="circle"
                    badgeContent={(
                      <Icon
                        name="check"
                        style={{
                          margin: 0,
                          height: 8,
                          width: 8,
                        }}
                      />
                    )}
                    invisible={this.state.selectedCigar.value !== cigar.id}
                  >
                    <Avatar
                      variant={this.getImage(cigar) ? 'square' : 'circle'}
                      src={this.getImage(cigar)}
                      style={{
                        marginRight: 10,
                        height: 80,
                        width: 80,
                      }}
                      alt={cigar.full_name}
                      imgProps={{ style: { objectFit: 'contain' } }}
                    >
                      <img
                        src={Placeholder.cigar}
                        style={{
                          height: 80,
                          width: 80,
                        }}
                      />
                    </Avatar>
                  </Badge>
                  <ListItemText
                    primary={(
                      <span className="padded-multiline" style={{ marginLeft: 10 }}>{cigar.full_name}</span>
                    )}
                  />
                </ListItem>
              ))}
              {this.props.CigarManager.cigars.length === 0 && (
                <div
                  style={{
                    textAlign: 'center',
                    margin: 10,
                  }}
                >
                  {'No cigars found'}
                </div>
              )}
            </List>
          </div>
        )}
        {!isMobile && (
          <div>
            <AsyncCreatableSelect
              value={this.state.selectedCigar}
              style={{
                control: (styles) => ({
                  ...styles,
                  minWidth: 300,
                }),
              }}
              onChange={this.handleChange('selectedCigar')}
              getOptionLabel={(option) => (
                <ListItem key={`cigar-item-${option.value}`}>
                  <Avatar
                    variant="square"
                    src={this.getImage(option)}
                    style={{ marginRight: 10 }}
                    alt={option.label}
                    imgProps={{ style: { objectFit: 'contain' } }}
                  >
                    <img
                      src={Placeholder.cigar}
                      style={{
                        height: 40,
                        width: 40,
                      }}
                    />
                  </Avatar>
                  <ListItemText
                    primary={(
                      <span className="padded-multiline" style={{ marginLeft: 10 }}>{option.label}</span>
                    )}
                  />
                </ListItem>
              )}
              // FIXME Below is what we want in the input field after selection
              // getOptionLabel={(option) => (
              //     <ListItem key={`cigar-item-${option.value}`} style={{ height: 30 }}>
              //         <Avatar src={this.getImage(option)} style={{ marginRight: 10, height: 22, width: 22 }} alt={option.label}>
              //             <img src={Placeholder.cigar} style={{ height: 22, width: 22 }} />
              //         </Avatar>
              //         <ListItemText
              //             primary={(
              //                 <span className="padded-multiline" style={{ marginLeft: 10 }}>{option.label}</span>
              //             )}
              //         />
              //     </ListItem>
              // )}
              getOptionValue={(option) => `${option}`}
              isOptionSelected={(option) => this.state.selectedCigar.value === option.value}
              loadOptions={this.loadCigars}
            />
            <div className="checkbox checkbox-primary ml-3">
              <Input
                name="alert-user"
                id="alert-user-cb"
                type="checkbox"
                checked={this.state.alertUser}
                onChange={(e) => this.setState({ alertUser: e.target.checked })}
              />
              <Label className="mb-0" htmlFor="alert-user-cb">
                {'Alert user?'}
              </Label>
            </div>
          </div>
        )}
      </div>
      <div className="modal-footer">
        <Button onClick={() => this.onCloseModal('choose')}>Cancel</Button>
        <Button
          disabled={this.state.submitting}
          onClick={() => this.onSaveModal('choose')}
          variant="contained"
          color="secondary"
        >
          {this.state.submitting && <Spinner color="light" style={{ marginRight: 8 }} />}
          {this.state.submitting ? 'Matching...' : 'Match'}
        </Button>
      </div>
    </Modal>
  );
}

const mapStateToProps = ({ CigarManager }) => ({ CigarManager });

export default connect(
  mapStateToProps, {
    getAllCigars,
    getCigarMatches,
    getCigarMatchById,
  },
)(CigarTable);
