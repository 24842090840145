import React, { Component } from 'react';
import * as Icons from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { Progress } from 'reactstrap';
import axios from 'axios';
import CustomScroll from 'react-custom-scrollbars';
import { Constants } from '../../../constants';
import { MENUITEMS } from '../../../config/menu';
import packageJson from '../../../../package.json';

import './styles.scss';
import IconSVG from '../../svg-icon';

class Sidebar extends Component {
  state = {
    totalCigars: 0,
    totalVenues: 0,
    totalProducts: 0,
    totalUsers: 0,
    selectedPath: '1',
    mainmenu: [],
  };

  onItemSelection = (arg, e) => {
    this.setState({ selectedPath: arg.path });
  };

  componentWillMount() {
    this.setState({
      mainmenu: MENUITEMS,
    });
  }

  componentDidMount() {
    const currentUrl = window.location.pathname;

    axios.get(`${Constants.apiPath}/stats/totals`)
      .then((res) => {
        this.setState({
          totalCigars: res.data.cigar_count,
          totalVenues: res.data.venue_count,
          totalProducts: res.data.product_count,
          totalUsers: res.data.user_count,
        });
      }).catch((e) => {
        console.log(e.message);
      });

    this.state.mainmenu.filter((items) => {
      if (!items.children) {
        if (items.path === currentUrl) {
          this.setNavActive(items);
        }
        return false;
      }
      items.children.filter((subItems) => {
        if (subItems.path === currentUrl) {
          this.setNavActive(subItems);
        }
        if (!subItems.children) return false;
        subItems.children.filter((subSubItems) => {
          if (subSubItems.path === currentUrl) {
            this.setNavActive(subSubItems);
          }
        });
      });
    });
  }

  setNavActive(item) {
    if (!item.children) {
      const contentwidth = window.innerWidth;
      if ((contentwidth) <= 991) {
        document.querySelector('.page-body-wrapper').classList.add('sidebar-close');
      }
    }
    MENUITEMS.filter((menuItem) => {
      if (menuItem != item) {
        menuItem.active = false;
      }
      if (menuItem.children && menuItem.children.includes(item)) {
        menuItem.active = true;
      }
      if (menuItem.children) {
        menuItem.children.filter((submenuItems) => {
          if (submenuItems.children && submenuItems.children.includes(item)) {
            menuItem.active = true;
          }
          if (submenuItems != item) {
            submenuItems.active = false;
          }
        });
      }
    });
    item.active = !item.active;

    this.setState({
      mainmenu: MENUITEMS,
    });
  }

  renderIcon = (menuItem) => {
    if (menuItem.iconType === 'component') {
      const IconName = Icons[menuItem.icon];
      return (
        <IconName />
      );
    } if (menuItem.iconType === 'svg') {
      // FIXME This only works for this SVG - find a way to normalize
      // return <IconSVG icon={menuItem.icon} style={{ height: '1em', width: '1em', transform: 'scale(0.7) translate(-3px, -5px)' }} />
      return (
        <IconSVG
          icon={menuItem.icon}
          style={{
            height: 24,
            width: 24,
          }}
        />
      );
    }
    // Themify Icons
    return (
      <i className={`icon-${menuItem.icon}`}></i>
    );
  };

  render() {
    const theme = {
      selectionColor: '#C51162',
    };

    // TODO Use https://github.com/wmira/react-sidenav?
    const mainmenu = this.state.mainmenu.map((menuItem, i) => (
      <li className={`${menuItem.active ? 'active' : ''}`} key={i}>
        {(menuItem.sidebartitle)
          ? <div className="sidebar-title">{menuItem.sidebartitle}</div>
          : ''}
        {(menuItem.type === 'sub')
          ? (
            <a className="sidebar-header " href="javascript:void(0)" onClick={() => this.setNavActive(menuItem)}>
              {this.renderIcon(menuItem)}
              <span>{menuItem.title}</span>
              <i className="fa fa-angle-right pull-right"></i>
            </a>
          )
          : ''}
        {(menuItem.type === 'link')
          ? (menuItem.path.indexOf('http') !== -1)
            ? (
              <a
                href={`${menuItem.path}`}
                className="sidebar-header"
                target="_blank"
              >
                {this.renderIcon(menuItem)}
                <span>{menuItem.title}</span>
                {menuItem.children
                  ? <i className="fa fa-angle-right pull-right"></i> : ''}
              </a>
            )
            : (
              <Link
                to={`${process.env.PUBLIC_URL}${menuItem.path}`}
                className={`sidebar-header ${menuItem.active ? 'Active' : ''}`}
                onClick={() => this.setNavActive(menuItem)}
              >
                {this.renderIcon(menuItem)}
                <span>{menuItem.title}</span>
                {menuItem.children
                  ? <i className="fa fa-angle-right pull-right"></i> : ''}
              </Link>
            )
          : ''}
        {(menuItem.type === 'action') && (
          <a
            href="#"
            className="sidebar-header"
            onClick={menuItem.onClick}
          >
            {this.renderIcon(menuItem)}
            <span>{menuItem.title}</span>
            {menuItem.children
              ? <i className="fa fa-angle-right pull-right"></i> : ''}
          </a>
        )}
        {menuItem.children
          ? (
            <ul
              className={`sidebar-submenu ${menuItem.active ? 'menu-open' : ''}`}
              style={menuItem.active ? {
                opacity: 1,
                transition: 'opacity 500ms ease-in',
              } : {}}
            >
              {menuItem.children.map((childrenItem, index) => (
                <li key={index} className={childrenItem.children ? childrenItem.active ? 'active' : '' : ''}>
                  {(childrenItem.type === 'sub')
                    ? (
                      <a href="javascript:void(0)" onClick={() => this.setNavActive(childrenItem)}>
                        <i className="fa fa-angle-right"></i>
                        {childrenItem.title}
                      </a>
                    )
                    : ''}

                  {(childrenItem.type === 'link')
                    ? (childrenItem.path.indexOf('http') !== -1)
                      ? (
                        <a
                          href={`${childrenItem.path}`}
                          className="sidebar-header"
                          target="_blank"
                        >
                          <i className="fa fa-angle-right"></i>
                          {childrenItem.title}
                        </a>
                      )
                      : (
                        <Link
                          to={`${process.env.PUBLIC_URL}${childrenItem.path}`}
                          className={childrenItem.active ? 'active' : ''}
                          onClick={() => this.setNavActive(childrenItem)}
                        >
                          <i className="fa fa-angle-right"></i>
                          {childrenItem.title}
                          {' '}

                        </Link>
                      )
                    : ''}
                  {childrenItem.children
                    ? (
                      <ul className={`sidebar-submenu ${childrenItem.active ? 'menu-open' : ''}`}>
                        {childrenItem.children.map((childrenSubItem, key) => (
                          <li className={childrenSubItem.active ? 'active' : ''} key={key}>
                            {(childrenSubItem.type === 'link')
                              ? (
                                <Link
                                  to={`${process.env.PUBLIC_URL}${childrenSubItem.path}`}
                                  className={childrenSubItem.active ? 'active' : ''}
                                >
                                  <i className="fa fa-angle-right"></i>
                                  {childrenSubItem.title}
                                </Link>
                              )
                              : ''}
                          </li>
                        ),)}
                      </ul>
                    )
                    : ''}
                </li>
              ),)}
            </ul>
          )
          : ''}
      </li>
    ),);

    const userPercent = parseInt((this.state.totalUsers / 10000) * 100, 10);
    // console.log(userPercent);
    const year = new Date().getFullYear();

    return (
      <div
        className="page-sidebar custom-scrollbar page-sidebar-open"
        style={{
          overflow: 'initial',
          display: 'flex',
          height: 'calc(100vh - 60px)',
        }}
      >
        <CustomScroll heightRelativeToParent="100%">
          {/* <UserPanel /> */}
          <ul className="sidebar-menu">
            {mainmenu}
          </ul>
          <div className="sidebar-widget p-20" style={{ color: '#d9d9d9' }}>
            <div>
              <span style={{ fontSize: 12 }}>
                {`Total Cigars: ${this.state.totalCigars}`}
              </span>
            </div>
            <div>
              <span style={{ fontSize: 12 }}>
                {`Total Venues: ${this.state.totalVenues}`}
              </span>
            </div>
            <div>
              <span style={{ fontSize: 12 }}>
                {`Total Products: ${this.state.totalProducts}`}
              </span>
            </div>
            <hr style={{ borderColor: '#636467' }} />
            <div>
              <span style={{ fontSize: 12 }}>
                {`User count: ${this.state.totalUsers} / 10000 (${userPercent}%)`}
              </span>
              <Progress className="sm-progress-bar" color="secondary" value={userPercent} />
            </div>
          </div>
          <div
            style={{
              textAlign: 'center',
              padding: 10,
            }}
          >
            <span
              style={{
                fontSize: 12,
                color: 'rgba(255, 255, 255, 0.7)',
              }}
            >
              {`© ${year} Boxpressd Ltd. All rights reserved. v${packageJson.version}`}
            </span>
          </div>
        </CustomScroll>
      </div>
    );
  }
}

export default Sidebar;
