import React from 'react';
import { DropzoneComponent as Dropzone } from 'react-dropzone-component';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
  PaginationTotalStandalone,
  SizePerPageDropdownStandalone,
} from 'react-bootstrap-table2-paginator';
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import AsyncCreatableSelect from 'react-select/async-creatable';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { Card, Modal, Input, Label, Tooltip, Spinner, Collapse } from 'reactstrap';
import { isMobile } from 'react-device-detect';
import queryString from 'query-string';
import { connect } from 'react-redux';
import FancyBox from 'react-fancybox';
import 'react-fancybox/lib/fancybox.css';

import axios from 'axios';
import SweetAlert from 'react-bootstrap-sweetalert';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import List from '@material-ui/core/List';
import Avatar from '@material-ui/core/Avatar';
import { FormattedInput } from '@buttercup/react-formatted-input';
import Hashids from 'hashids';
import Toast from 'cogo-toast';
import MediaCropper from '../../components/cropper';
import Icon from '../../components/icon';
import TableLoader from '../../components/table-loader';
import NoDataIndicator from '../../components/no-data-indicator';
import Button from '../../components/overrides/button';
import RowMoreOptions from '../../components/table/row-more-options';
import VitolaEditor, { defaultVitolas, formattedVitolaName } from '../../components/vitolas/editor';
import { Constants } from '../../constants';
import {
  getAllCigars,
  getCigarById,
  getBrands,
  getManufacturers,
  getOrigins,
  getWrappers,
  getBinders,
  getFillers,
  postCigar,
  putCigar,
  updateVitolas, searchCigars,
} from '../../redux/actions/cigar.actions';
import Pagination from '../../components/ui/base/pagination';
import Breadcrumb from '../../components/common/breadcrumb.component';
import TouchSpin from '../../components/touch-spin';
import './styles.scss';
import DebounceSearchBar from '../../components/DebounceSearchBar';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import { default as CsvDropzone } from '../../components/dropzone';
import DataTable from 'react-data-table-component';
import * as Sentry from '@sentry/browser';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';

const hashids = new Hashids('', 12);

const env = process.env.NODE_ENV || 'development';

const ReactDOMServer = require('react-dom/server');
const config = require('../../config/config.json')[env];

const defaultSorted = [{
  dataField: 'brand',
  order: 'asc',
}];

const cellEditProps = {
  mode: 'dbclick',
  beforeSaveCell(oldValue, newValue, row, column, done) {
    console.log(`Before save for ${column.dataField}`);
    // TODO Call done() after success from axios PUT, otherwise done(false) for failure
    // return { async: true };
  },
  afterSaveCell: (oldValue, newValue, row, column) => {
    console.log(`After save for ${column}`);
  },
};

const strengths = ['Mellow', 'Mellow-Medium', 'Medium', 'Medium-Full', 'Full'];

// TODO Remote Search https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html?selectedKind=Remote&selectedStory=Remote%20Search&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel

const defaults = {
  selectedId: null,
  selectedBrand: '',
  selectedName: '',
  selectedManufacturer: '',
  selectedOrigin: '',
  selectedWrapper: '',
  selectedBinder: '',
  selectedFiller: '',
  selectedStrength: '',
  selectedTags: '',
  selectedVitolas: [],
  strengths: [
    {
      value: 1,
      label: 'Mellow',
    },
    {
      value: 2,
      label: 'Mellow-Medium',
    },
    {
      value: 3,
      label: 'Medium',
    },
    {
      value: 4,
      label: 'Medium-Full',
    },
    {
      value: 5,
      label: 'Full',
    },
  ],
  mergeCigar: '',
  vitolas: [
    {
      alias: {
        value: '',
        label: '',
      },
      shape: {
        value: '',
        label: '',
      },
      gauge: {
        value: '',
        label: '',
      },
      length: {
        value: '',
        label: '',
      },
    },
  ],
  similarCigars: [
    {
      value: '',
      label: '',
    },
  ],
  cigarImageFull: null,
  cigarImageBand: null,
  cigarImageFoot: null,
  cigarImageDisplay: null,
  selectedFacebook: false,
  selectedInstagram: false,
  selectedTwitter: false,
  showUrlTooltip: false,
  showMergeTooltip: false,
  showImportModal: false,
  showCigarById: false,
  showMediaCropper: false,
  importVitolasOnly: false,
  merging: false,
  selectedUrl: '',
  openLightbox: false,
  photoIndex: -1,
  selectedForceMerge: false,
  activeDropzone: null,
  activeDropzoneId: null,
  cigarImportType: 'single_import',
  dialogModal: {
    show: false,
    title: '',
    text: '',
  },
};

const djsPreviewTemplate = ReactDOMServer.renderToStaticMarkup(
  <div className="dz-preview dz-file-preview">
    <div className="dz-details">
      {/* <div className="dz-filename"><span data-dz-name="true"></span></div> */}
      <img
        style={{
          maxWidth: 120,
          maxHeight: 120,
        }}
        data-dz-thumbnail="true"
      />
    </div>
    {/* <div className="dz-progress"><span className="dz-upload" data-dz-uploadprogress="true"></span></div> */}
    {/* <div className="dz-success-mark"><span>✔</span></div> */}
    {/* <div className="dz-error-mark"><span>✘</span></div> */}
    {/* <div className="dz-error-message"><span data-dz-errormessage="true"></span></div> */}
  </div>,
);

class CigarTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cigars: [],
      page: 1,
      sizePerPage: 10,
      showCigarModal: false,
      showMergeModal: false,
      showVitolaModal: false,
      showVitolasModal: false,
      showSimilarModal: false,
      showSocialModal: false,
      showPagePickerModal: false,
      cigarImageView: 'band', // Show either the 'band' or the 'display' image in the table rows
      message: {
        type: 'info',
        show: false,
        title: '',
        text: '',
      },
      ...defaults,
    };
    this.cigarImageFull = null;
    this.cigarImageBand = null;
    this.cigarImageFoot = null;
    this.cigarImageDisplay = null;
    this.vitolaImageFull = null;
    this.handleTableChange = this.handleTableChange.bind(this);
    this.toggleTooltip = this.toggleTooltip.bind(this);
  }

  componentDidMount() {
    const query = queryString.parse(this.props.location.search);
    if (query.id) {
      console.log(`Getting data for cigar ID: ${query.id}`);
      // INFO handleTableChange overrides this query so we need the flag below to temporarily block it
      this.setState({
        showCigarById: true,
      });
      this.props.getCigarById(query.id);
    } else if (isMobile) {
      this.props.getAllCigars(1, 10);
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    let { cigars } = nextProps.CigarManager;
    if (cigars) {
      if (!Array.isArray(cigars)) {
        console.debug('Got a single cigar');
        console.debug(cigars);
        // FIXME When we pass in a cigar ID, it only returns the 1 object
        cigars = [cigars];
      }
      this.setState({ cigars: cigars.map((cigar) => cigar) });
    }
  }

  getMainImage = (cigar) => {
    let imageUrl = '';
    if (cigar.images && cigar.images.length > 0) {
      for (let i = 0; i < cigar.images.length; i++) {
        const image = cigar.images[i];
        if (image.image_type === 0) {
          imageUrl = image.image_url;
          break;
        } else if (image.image_type === 1) {
          imageUrl = image.image_url;
          break;
        }
      }
    }
    return imageUrl;
  };

  keyForType = (type) => {
    switch (type) {
      case 'cigar':
        return 'showCigarModal';
      case 'merge':
        return 'showMergeModal';
      case 'vitola':
        return 'showVitolaModal';
      case 'vitolas':
        return 'showVitolasModal';
      case 'vitolaImage':
        return 'showVitolaImageModal';
      case 'similar':
        return 'showSimilarModal';
      case 'social':
        return 'showSocialModal';
      case 'import':
        return 'showImportModal';
      case 'page':
        return 'showPagePickerModal';
      case 'datatable':
        return 'showImporterDatatable';
      default:
        return '';
    }
  };

  onOpenModal = (type, row) => {
    if (type === 'cigar') {
      if (row) {
        console.log(JSON.stringify(row));
        let wrappers = '';
        if (row.wrapper) {
          if (typeof row.wrapper === 'string') {
            row.wrapper = row.wrapper.split(', ');
          }
          wrappers = row.wrapper.map((wrapper) => ({
            value: wrapper,
            label: wrapper,
          }));
        }
        let binders = '';
        if (row.binder) {
          if (typeof row.binder === 'string') {
            row.binder = row.binder.split(', ');
          }
          binders = row.binder.map((binder) => ({
            value: binder,
            label: binder,
          }));
        }
        let fillers = '';
        if (row.filler) {
          if (typeof row.filler === 'string') {
            row.filler = row.filler.split(', ');
          }
          fillers = row.filler.map((filler) => ({
            value: filler,
            label: filler,
          }));
        }

        let cigarImageFull = null;
        let cigarImageBand = null;
        let cigarImageFoot = null;
        let cigarImageDisplay = null;

        if (row.images) {
          for (let i = 0; i < row.images.length; i++) {
            const image = row.images[i];
            if (image.approved) {
              if (image.image_type === 0) {
                cigarImageFull = image.image_url;
              } else if (image.image_type === 1) {
                cigarImageBand = image.image_url;
              } else if (image.image_type === 5) {
                cigarImageFoot = image.image_url;
              } else if (image.image_type === 6) {
                cigarImageDisplay = image.image_url;
              }
            }
          }
        }

        // console.log("The ID is: " + row.id);

        this.setState({
          selectedId: row.id ? row.id : null,
          selectedBrand: row.brand ? {
            value: row.brand,
            label: row.brand,
          } : '',
          selectedName: row.name ? row.name : '',
          selectedManufacturer: row.manufacturer ? {
            value: row.manufacturer,
            label: row.manufacturer,
          } : '',
          selectedOrigin: row.origin ? {
            value: row.origin,
            label: row.origin,
          } : '',
          selectedWrapper: wrappers,
          selectedBinder: binders,
          selectedFiller: fillers,
          selectedStrength: row.strength ? {
            value: row.strength,
            label: strengths[row.strength - 1],
          } : '',
          selectedTags: row.tags ? row.tags.map((cigarTag) => ({
            value: cigarTag.tag.title,
            label: cigarTag.tag.title,
          })) : [],
          selectedVitolas: row.vitolas ? row.vitolas.map((vitola) => ({
            ...vitola,
            select_shape: {
              value: vitola.shape,
              label: vitola.shape,
            },
          })) : [],
          cigarImageFull: cigarImageFull || null,
          cigarImageBand: cigarImageBand || null,
          cigarImageFoot: cigarImageFoot || null,
          cigarImageDisplay: cigarImageDisplay || null,
        });
      }
    } else if (type === 'social') {
      setTimeout(() => {
        this.setState({
          message: {
            type: 'info',
            show: true,
            title: 'Under Development',
            text: 'This feature isn\'t available yet.',
          },
        });
      }, 300);
    } else if (type === 'merge') {
      if (row && row.id) {
        this.setState({ selectedCigar: row, selectedId: row.id });
      }
    }
    this.setState({ [this.keyForType(type)]: true });
  };

  onCloseModal = (type) => {
    if (type === 'cigar') {
      this.setState({
        [this.keyForType(type)]: false,
        ...defaults,
      });
    } else {
      this.setState({
        [this.keyForType(type)]: false,
      });
    }
  };

  onSaveModal = (type) => {
    console.debug('Saving... ', type);
    if (type === 'cigar') {
      console.log('Submitting!');

      const {
        selectedWrapper,
        selectedBinder,
        selectedFiller,
        selectedTags,
        selectedBrand,
        selectedName,
        selectedManufacturer,
        selectedOrigin,
        selectedStrength,
        selectedId,
        selectedVitolas,
      } = this.state;

      const wrappers = [];
      if (selectedWrapper.length) {
        for (let i = 0; i < selectedWrapper.length; i++) {
          wrappers.push(selectedWrapper[i].value);
        }
      }

      const binders = [];
      if (selectedBinder.length) {
        for (let i = 0; i < selectedBinder.length; i++) {
          binders.push(selectedBinder[i].value);
        }
      }

      const fillers = [];
      if (selectedFiller.length) {
        for (let i = 0; i < selectedFiller.length; i++) {
          fillers.push(selectedFiller[i].value);
        }
      }

      const tags = [];
      if (selectedTags.length) {
        for (let i = 0; i < selectedTags.length; i++) {
          tags.push(selectedTags[i].value);
        }
      }

      const cigar = {
        brand: selectedBrand.value,
        name: selectedName,
        affiliate: selectedManufacturer.value,
        origin: selectedOrigin.value,
        wrapper: wrappers.join(', '),
        filler: fillers.join(', '),
        binder: binders.join(', '),
        strength: selectedStrength.value,
        tags,
        vitolas: selectedVitolas,
        pending: false,
        custom: false,
        cuban: selectedOrigin.value === 'Cuba', // TODO Do we even really need this in the db?
      };

      const {
        postCigar,
        putCigar,
        updateVitolas,
      } = this.props;

      // Prevent empty strings
      Object.keys(cigar).forEach(function (key, index) {
        if (this[key] === '') {
          this[key] = null;
        }
      }, cigar);

      const {
        page,
        sizePerPage,
        searchText,
        tableChangeType,
      } = this.state;

      if (selectedId) {
        cigar.id = selectedId;
        putCigar(cigar).then((updatedCigar) => {
          Toast.success('Cigar updated successfully', { heading: updatedCigar.full_name, position: 'top-right' });
          this.setState({
            [this.keyForType(type)]: false,
            ...defaults,
          });

          this.handleRowChange(updatedCigar);
        }).catch((err) => {
          this.setState({
            message: {
              show: true,
              type: 'error',
              title: 'Uh oh!',
              text: 'Unable to update the cigar details. Please contact an admin.',
            },
          });
        });
        this.updateCigarMedia(selectedId);
        if (selectedVitolas && selectedVitolas.length > 0) {
          updateVitolas(selectedId, selectedVitolas);
        }
      } else {
        postCigar(cigar).then((updatedCigar) => {
          Toast.success('Cigar added successfully', { heading: updatedCigar.full_name, position: 'top-right' });
          this.updateCigarMedia(updatedCigar.id);

          this.setState({
            [this.keyForType(type)]: false,
            ...defaults,
          });

          this.handleRowChange(updatedCigar);
        }).catch((err) => {
          this.setState({
            message: {
              show: true,
              type: 'error',
              title: 'Uh oh!',
              text: 'Unable to add the cigar details. Please contact an admin.',
            },
          });
        });
      }

      console.log(cigar);
    } else if (type === 'import') {
      console.log(`Getting cigar details for ${this.state.selectedUrl}`);
      this.setState({ importing: true });
      axios.get(`${Constants.apiPath}/scrape/cigar`, {
        params: {
          url: this.state.selectedUrl,
        },
      }).then((res) => {
        console.debug(JSON.stringify(res.data));
        this.setState({ importing: false });
        if (res.data.vitolas && this.state.importVitolasOnly) {
          console.debug('Got vitolas!');
          console.debug(res.data.vitolas);
          this.setState({ selectedVitolas: res.data.vitolas });
        }
        this.onCloseModal('import');
        if (!this.state.importVitolasOnly) {
          this.onOpenModal('cigar', res.data);
        }
      }).catch((e) => {
        this.setState({ importing: false });
        console.log(e.message);
      });
    } else if (type === 'vitola') {
      this.onCloseModal('vitola');
    } else if (type === 'vitolas') {
      this.onCloseModal('vitolas');
    } else if (type === 'merge') {
      console.debug('Merging details...');
      const { selectedId } = this.state;
      const mergeDetails = this.state.mergeCigar;
      console.debug(selectedId);
      console.debug(mergeDetails);
      if (mergeDetails) {
        let mergeCigar;
        for (const c of this.props.CigarManager.cigarSearch) {
          if (c.id === mergeDetails.value) {
            mergeCigar = c;
            break;
          }
        }
        console.debug(mergeCigar);
        this.setState({ merging: true });
        axios.post(`${Constants.apiPath}/cigars/${selectedId}/merge`, mergeCigar).then((response) => {
          this.handleRowDelete({ id: selectedId });
          this.handleRowChange(response.data);
          this.onCloseModal('merge');
          this.onCloseModal('cigar');
          this.setState({ merging: false });
        }).catch((err) => {
          this.setState({
            message: {
              show: true,
              type: 'error',
              title: 'Uh oh!',
              text: 'Unable to merge the cigar details. Please contact an admin.',
            },
          });
        });
      } else {
        console.error('You must choose a cigar from the dropdown to merge the currently selected cigar into.');
        this.setState({
          message: {
            show: true,
            type: 'error',
            title: 'Please select a cigar to merge into',
            text: 'You must choose a cigar from the dropdown to merge the currently selected cigar into.',
          },
        });
      }
    } else if (type === 'datatable') {
      const {
        selectedCigarsFile,
      } = this.state;
      this.setState({ uploading: true });
      // FIXME This needs to be an endpoint the business panel can access, as well... have an endpoint on the business
      //  panel just use a JWT to access the admin endpoint
      axios.post(`${Constants.apiPath}/cigars/datasources/sign`, {
        filetype: selectedCigarsFile.type,
      }).then((result) => {
        console.debug('Signed response:');
        console.debug(JSON.stringify(result.data.signed_url));
        const uploadOptions = {
          method: 'PUT',
          body: selectedCigarsFile,
        };

        console.debug(result.data);

        console.debug('Direct upload in progress...');
        fetch(result.data.signed_url, uploadOptions).then(async () => {
          console.debug('S3 success');
          if (result.data.datasource_url) {
            // FIXME This needs to be an endpoint the business panel can access, as well...
            const batchEndpoint = `${Constants.apiPath}/cigars/batch`;
            console.debug('Kicking off step function...');
            await axios.post(batchEndpoint, {
              datasource_url: result.data.datasource_url,
            }).then((results) => {
              // TODO Update local state with the new datasource? Or just show alert that it's importing and they will be notified when complete?
              this.setState({ uploading: false, showImporterModal: false });
              Toast.success('You will receive an email when complete', { heading: 'Cigar Sync Started', position: 'top-right' });
            }).catch((err) => {
              console.error(err);
              this.setState({ uploading: false });
              // TODO Alert the user with ModalDialog? Reuse it for all of these catches...
              alert('Something went wrong. Please contact our support team at support@boxpressd.com');
            });
          } else {
            console.error('No data URL...');
            this.setState({ uploading: false });
            // Alert admins - something went wrong
            Sentry.setExtra('s3_signed_url_response', { id: result.id, datasource_url: result.datasource_url });
            Sentry.captureException('Unable to upload media to S3');
            alert('Something went wrong. Please contact our support team at support@boxpressd.com');
          }
        }).catch((err) => {
          this.setState({ uploading: false });
          console.debug('S3 failure:');
          console.debug(err);
          alert('Something went wrong. Please contact our support team at support@boxpressd.com');
        });
      }).catch((err) => {
        this.setState({ uploading: false });
        console.error(err);
        alert('Something went wrong. Please contact our support team at support@boxpressd.com');
      });
    }
  };

  toggleTooltip(key) {
    return () => {
      this.setState({
        [key]: !this.state[key],
      });
    };
  }

  // FIXME This isn't updating on state changes so the inner references don't update, like 'activeDropzone' or 'cropped'
  dropzoneEventHandlers(holder) {
    const self = this;
    console.log(holder);
    return {
      addRemoveLinks: true,
      accept(file, done) {
        console.log('File:');
        console.log(file);
        console.log('uploaded');
        done();
      },
      init: (dropzone) => {
        // Dropzone.autoDiscover = false;
        console.log('Initialized dropzone');
        console.log(dropzone);
        console.log(this.state);
        const image = this.state[holder];
        if (image) {
          const imageFile = {
            name: image,
            size: 0,
          };
          dropzone.emit('addedfile', imageFile);
          dropzone.emit('thumbnail', imageFile, image);
          dropzone.emit('complete', imageFile);
          dropzone.files.push(imageFile);

          // FIXME This doesn't always hide
          // Hide the message
          setTimeout(() => {
            console.log(dropzone.element);
            const messageDiv = dropzone.element.closest('.dz-message');
            if (messageDiv) {
              messageDiv.style.display = 'none';
            } else {
              dropzone.element.children[0].style.display = 'none';
            }
          }, 500);
        }
      },
      drop: (event) => {
        console.log('Dropped event:');
        console.log(event);
        console.log(event.target);
        event.target.style.display = 'none';
        const messageDiv = this[holder].dropzone.element.closest('.dz-message');
        if (messageDiv) {
          messageDiv.style.display = 'none';
        } else {
          this[holder].dropzone.element.children[0].style.display = 'none';
        }
        this.setState({
          activeDropzone: this[holder],
          activeDropzoneId: holder,
        });
      },
      addedfile(file) {
        console.log('Dropzone files:');
        console.log(this.files);

        console.log('Added file');
        console.log(file);
        console.log(this.files);

        if (this.files.length > 0) {
          if (this.files[0]) {
            const { status } = JSON.parse(JSON.stringify(this.files[0]));
            console.log('Status: ', status);
            if (status !== 'queued' && status !== 'added') {
              console.log('Deleting file...');
              this.removeFile(this.files[0]);
            }
          }
        }

        if (file && this.files.length === 1) {
          console.log(file);
          console.log('Loading file...');
          const fileReader = new FileReader();
          fileReader.onload = (event) => {
            console.log('Loaded file.');
            const dataUrl = event.target.result;
            console.log(dataUrl);
            self.setState({
              selectedImageFile: dataUrl,
              showMediaCropper: true,
            });
          };
          fileReader.readAsDataURL(file);
        }
      },
      success: (file, response, error) => {
        console.log(response);
      },
    };
  }

  imageFormatter = (cell, row) => {
    // const { cigars } = this.state;
    console.log('Recalculating image for row...');
    const cigar = row; // cigars[index];
    console.log(row);
    const imageType = this.state.cigarImageView === 'band' ? 0 : 6;
    let cigarImage;
    if (cigar.images && cigar.images.length > 0) {
      for (let i = 0; i < cigar.images.length; i++) {
        // console.log(cigar.images[i]);
        if (cigar.images[i].image_type === imageType && cigar.images[i].approved === true) {
          console.log(cigar.images[i].image_url);
          cigarImage = cigar.images[i].image_url; // FIXME If image_type == 0 isn't available, use next option? 1 for full band?
          break;
        }
      }
    }
    if (cigarImage) {
      return (
        <FancyBox
          thumbnail={cigarImage}
          image={cigarImage}
          // defaultThumbnailWidth={75}
        />
      );
    }
    return null;
  };

  handleInputChange = (key) => (event) => {
    console.log(key);
    console.log(event.target.value);
    this.setState({ [key]: event.target.value });
  };

  handleRadioChange = (key) => (event) => {
    this.setState({ [key]: event.target.value });
  };

  handleSelectChange = (key) => (value) => {
    this.setState({ [key]: value });
  };

  handlePageChange = (e, index) => {
    this.setState({
      page: index,
    });
    const {
      sizePerPage,
      searchText,
    } = this.state;
    this.props.getAllCigars(index + 1, sizePerPage, searchText && searchText.length > 0 ? searchText : null);
  };

  loadCigars = (inputValue, callback) => {
    this.props.searchCigars(inputValue);
    setTimeout(() => { // FIXME How do we wait or listen for the change instead? UPDATE This can be done with hooks / functional component useEffect
      callback(this.props.CigarManager.cigarSearch.map((cigar) => ({
        value: cigar.id,
        label: cigar.full_name,
      })));
    }, 500);
  };

  loadBrands = (inputValue, callback) => {
    this.props.getBrands(inputValue);
    setTimeout(() => { // FIXME How do we wait or listen for the change instead?
      callback(this.props.CigarManager.brands.map((value) => ({
        value,
        label: value,
      })));
    }, 500);
  };

  loadManufacturers = (inputValue, callback) => {
    this.props.getManufacturers(inputValue);
    setTimeout(() => {
      callback(this.props.CigarManager.manufacturers.map((value) => ({
        value,
        label: value,
      })));
    }, 500);
  };

  loadOrigins = (inputValue, callback) => {
    this.props.getOrigins(inputValue);
    setTimeout(() => {
      callback(this.props.CigarManager.origins.map((value) => ({
        value,
        label: value,
      })));
    }, 500);
  };

  loadWrappers = (inputValue, callback) => {
    this.props.getWrappers(inputValue);
    setTimeout(() => {
      callback(this.props.CigarManager.wrappers.map((value) => ({
        value,
        label: value,
      })));
    }, 500);
  };

  loadBinders = (inputValue, callback) => {
    this.props.getBinders(inputValue);
    setTimeout(() => {
      callback(this.props.CigarManager.binders.map((value) => ({
        value,
        label: value,
      })));
    }, 500);
  };

  loadFillers = (inputValue, callback) => {
    this.props.getFillers(inputValue);
    setTimeout(() => {
      callback(this.props.CigarManager.fillers.map((value) => ({
        value,
        label: value,
      })));
    }, 500);
  };

  editBtnFormatter = (cell, row, index) => (
    <div style={{ width: 68 }}>
      <div
        id={`dropdown-actions-${index}`}
        className="d-none"
      >
        <Icon
          name="edit2"
          className=" ml-1 mr-3"
          style={{
            cursor: 'pointer',
            display: 'inline',
            height: 18,
            width: 18,
          }}
          onClick={() => this.onOpenModal('cigar', row)}
        />
        {this.actionDropdown(index, row)}
      </div>
    </div>
  );

  updateRecords = () => {
    // FIXME This hack is to ensure that the new details load - not sure why they won't just update without switching the page...
    //  It works fine in the Brand and Venue Managers..
    const {
      page,
      sizePerPage,
      searchText,
      tableChangeType,
    } = this.state;
    this.handleTableChange(tableChangeType, {
      page: page - 1 > 0 ? page - 1 : 1,
      sizePerPage,
      searchText,
    });
    setTimeout(() => {
      console.debug('Rebuilding table for images...');
      this.handleTableChange(tableChangeType, {
        page,
        sizePerPage,
        searchText,
      });
    }, 50);
    // FIXME End hack
  }

  handleRowChange = (updatedCigar) => {
    const { cigars } = this.state;
    console.log(`Updating cigar for ${updatedCigar.id}...`);
    console.log(updatedCigar);
    const updatedCigars = cigars.map((cigar) => {
      if (cigar.id === updatedCigar.id) {
        console.log(`Found cigar to update for ${updatedCigar.id}...`);
        console.log(cigar);
        return { ...cigar, ...updatedCigar };
      }
      return cigar;
    });
    this.setState((prevState) => ({ ...prevState, cigars: updatedCigars }));
    this.updateRecords();
  };

  handleRowDelete = (deletedCigar) => {
    const { cigars } = this.state;
    console.log(`Removing cigar for ${deletedCigar.id}...`);
    console.log(deletedCigar);
    let i = cigars.length;
    while (i--) {
      const cigar = cigars[i];
      if (cigar === deletedCigar.id) {
        cigars.splice(i, 1);
      }
    }
    this.setState({ cigars });
  };

  handleTableChange = (type, { page, sizePerPage, filters, sortField, sortOrder, cellEdit, searchText }) => {
    console.log(type);
    if (!this.state.showCigarById) {
      console.log(JSON.stringify(searchText));
      // const currentIndex = (page - 1) * sizePerPage;
      this.props.getAllCigars(page, sizePerPage, searchText && searchText.length > 0 ? searchText : null);

      this.setState({
        tableChangeType: type,
        page,
        sizePerPage,
        searchText,
      });
    }
  };

  closeAlert = () => {
    this.setState({
      message: {
        show: false,
        title: '',
        text: '',
      },
    });
  };

  deleteCigar = () => {
    this.setState({
      dialogModal: {
        show: true,
        title: 'Are you sure you want to delete?',
        text: 'This will delete the cigar and all vitolas and cannot be undone. Note: If users have this cigar associated with a smoke session or review, this will return an error. If that happens, you will need to merge the cigar instead.',
      },
    });
  };

  deleteCigarConfirmed = () => {
    // TODO Delete on server
    this.setState({
      message: {
        type: 'info',
        show: true,
        title: 'Under Development',
        text: 'This feature isn\'t available yet. In the meantime, consider merging the cigar into a corresponding one instead.',
      },
    });
  };

  actionDropdown = (index, cigar) => (
    <RowMoreOptions
      row={index}
      items={[{
        type: 'MenuItem',
        onClick: () => this.onOpenModal('social', cigar),
        title: 'Create social media post',
      }, {
        type: 'Divider',
      }, {
        type: 'MenuItem',
        onClick: () => this.onOpenModal('vitolas', cigar),
        title: 'Edit Vitolas',
      }, {
        type: 'MenuItem',
        onClick: () => this.onOpenModal('similar', cigar),
        title: 'Link Similar Cigars',
      }, {
        type: 'MenuItem',
        onClick: () => this.onOpenModal('cigar', {
          ...cigar,
          vitolas: cigar.vitolas && cigar.vitolas.map((vitola) => ({
            ...vitola,
            id: null,
          })),
          images: [],
          id: null,
        }),
        title: 'Duplicate',
      }, {
        type: 'MenuItem',
        onClick: () => {
          axios.post(`${Constants.apiPath}/actions/cigars/${cigar.id}/search/index`).then(() => {
            Toast.success('Successfully re-indexed', { heading: cigar.full_name, position: 'top-right' });
          });
        },
        title: 'Re-index in Search',
      }, {
        type: 'Divider',
      }, {
        type: 'MenuItem',
        onClick: () => {
          alert('Coming back soon! This doesn\'t seem to be working properly.')
          // this.onOpenModal('merge', cigar)
        },
        title: 'Merge',
      }, {
        type: 'MenuItem',
        onClick: () => this.deleteCigar(),
        style: { color: '#ff4700' },
        title: 'Delete',
      }]}
      onShow={() => {
        // Hide the row buttons
        const dropdownActions = document.querySelector(`#dropdown-actions-${index}`);
        if (dropdownActions) {
          dropdownActions.classList.add('d-none');
        }
      }}
    />
  );

  updateCigarMedia = (cigarId) => {
    const {
      cigarImageFull,
      cigarImageBand,
      cigarImageFoot,
      cigarImageDisplay,
    } = this.state;

    if (cigarImageFull || cigarImageBand || cigarImageFoot || cigarImageDisplay) {
      console.log('Uploading media...');
      axios.put(`${Constants.apiPath}/cigars/${cigarId}/media`, {
        full_image_url: cigarImageFull,
        band_image_url: cigarImageBand,
        foot_image_url: cigarImageFoot,
        display_image_url: cigarImageDisplay,
      }).then(() => {
        console.log('Added media successfully!');
        // FIXME Endpoint to just grab the images only?
        axios.get(`${Constants.apiPath}/cigars/datatables/all/${cigarId}`).then((res) => {
          console.log('Got updated cigar data:');
          console.log(res.data);
          let cigarDetail;
          for (const c of this.state.cigars) {
            if (c.id === cigarId) {
              cigarDetail = c;
              c.images = [...res.data.images];
              break;
            }
          }
          if (cigarDetail) {
            console.debug('Updating cigar images...');
            console.debug(cigarDetail);
            this.handleRowChange(cigarDetail);
          }
        });
      }).catch((err) => {
        console.log('Unable to add media');
        console.log(err);
      });
    } else {
      console.log('No media to add to cigar...');
    }
  };

  uploadMedia = (blob, extension, type) => {
    const formData = new FormData();

    // INFO Must be named 'media' to work with our server
    const cigarHash = hashids.encode(this.state.selectedCigar ? this.state.selectedCigar.id : new Date().getTime());
    // formData.append('media', blob, `${cigarHash}-${type}.${extension}`);
    formData.append('media', blob, `${cigarHash}-${type}.jpg`);
    console.log(`${cigarHash}-${type}.jpg`);

    console.log('Uploading to media server...');
    console.log('Uploading media to server...');

    const mediaInstance = axios.create();
    mediaInstance.defaults.headers.common = {
      'Content-Type': 'multipart/form-data',
      Accept: 'text/html',
    };
    this.setState({ uploadingMedia: true });
    mediaInstance.post(`${config.mediaEndPoint}/upload?type=cigars`, formData).then((res) => {
      console.log('Uploaded to media server!');
      console.log(res.data);
      // Sentry.setExtra('uploadMedia_res_data', JSON.stringify(res.data));
      // setUploadingMedia(false);
      this.setState({
        uploadingMedia: false,
        [type]: res.data.media_url,
      });
    }).catch((err) => {
      this.setState({ uploadingMedia: false });
      // setUploadingMedia(false);
      // ErrorLogger.captureException(err);
      console.log(err);
    });
  };

  renderMediaCropper = () => {
    const {
      selectedImageFile,
      showMediaCropper,
      activeDropzone,
      activeDropzoneId,
    } = this.state;
    return (
      <MediaCropper
        aspectRatio={activeDropzoneId === 'cigarImageDisplay' ? 1 : undefined}
        src={selectedImageFile}
        open={showMediaCropper}
        toggle={() => this.setState({ showMediaCropper: !showMediaCropper })}
        onClose={() => this.setState({ showMediaCropper: false })}
        onSave={(croppedCanvas) => {
          const base64 = croppedCanvas.toDataURL('image/jpeg');
          this.setState({
            showMediaCropper: false,
            selectedImage: base64, // FIXME Need to know which image this is for?
          });
          croppedCanvas.toBlob((blob) => {
            this.forceUpdate();

            console.log('Canvas blob');
            console.log(blob);
            console.log(activeDropzone);
            console.log(activeDropzoneId);
            const fileReader = new FileReader();
            fileReader.onload = (event) => {
              const dataUrl = event.target.result;
              console.log(dataUrl);
              const { dropzone } = activeDropzone;
              dropzone.emit('addedfile', blob);
              dropzone.emit('thumbnail', blob, dataUrl);
              dropzone.emit('complete', blob);
              dropzone.files.push(blob);
            };
            fileReader.readAsDataURL(blob);

            this.uploadMedia(blob, base64.split(';')[0].split('/')[1], activeDropzoneId);
          }/* , 'image/png' */);
        }}
      />
    );
  };

  render() {
    const { sizePerPage, page, openLightbox, photoIndex } = this.state;
    const { cigars } = this.state;
    const { totalSize, loading } = this.props.CigarManager;

    // console.log(JSON.stringify(this.props.CigarManager));

    const columns = [{
      dataField: 'images',
      text: '',
      formatter: this.imageFormatter,
      sort: false,
      editable: false,
    }, {
      dataField: 'brand',
      text: 'Brand',
      sort: true,
    }, {
      dataField: 'name',
      text: 'Name',
      sort: true,
    }, {
      dataField: 'origin',
      text: 'Origin',
      sort: true,
    }, {
      dataField: 'wrapper',
      text: 'Wrapper',
      sort: true,
    }, {
      dataField: 'binder',
      text: 'Binder',
      sort: true,
    }, {
      dataField: 'filler',
      text: 'Filler',
      sort: true,
    }, {
      dataField: 'strength',
      text: 'Strength',
      formatter: (cell, row) => strengths[cell - 1],
      editor: {
        type: Type.SELECT,
        options: [{
          value: '1',
          label: 'Mellow',
        }, {
          value: '2',
          label: 'Mellow-Medium',
        }, {
          value: '3',
          label: 'Medium',
        }, {
          value: '4',
          label: 'Medium-Full',
        }, {
          value: '5',
          label: 'Full',
        }],
      },
    }, {
      // dataField: 'edit',
      text: '',
      formatter: this.editBtnFormatter,
      sort: false,
      editable: false,
    }];

    const selectRow = {
      mode: 'checkbox',
    };

    const rowEvents = {
      onMouseEnter: (e, row, index) => {
        // console.log("Mouse entered: " + index);
        document.querySelector(`#dropdown-actions-${index}`).classList.remove('d-none');
      },
      onMouseLeave: (e, row, index) => {
        document.querySelector(`#dropdown-actions-${index}`).classList.add('d-none');
      },
      onDoubleClick: (e, row, index) => {
        // INFO If we don't include this event, the double click to edit doesn't work
        // console.log(e);
      },
    };

    const pageButtonRenderer = ({ page, active, onPageChange }) => {
      const handleClick = (e) => {
        e.preventDefault();
        onPageChange(page);
      };
      let classname = 'btn btn-outline-secondary';
      if (active) {
        classname = 'btn btn-secondary';
      }
      return (
        <li className="page-item pl-1" key={page}>
          <a href="#" onClick={handleClick} className={classname}>{page}</a>
        </li>
      );
    };

    // TODO https://www.npmjs.com/package/react-device-detect change for mobile
    return (
      <div>
        {/* FIXME Turn this alert into a component - see the main app (ModalDialog, I think...) */}
        <SweetAlert
          show={this.state.message.show}
          type={this.state.message.type}
          title={this.state.message.title}
          onConfirm={this.closeAlert}
        >
          {this.state.message.text}
        </SweetAlert>

        <Breadcrumb title="Cigar Manager" label="Manager" parent="Cigars" />

        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div
                className="card mb-0"
                style={isMobile ? {
                  background: 'transparent',
                  boxShadow: 'none',
                } : {}}
              >
                <div className="card-body datatable-react">
                  <ToolkitProvider
                    keyField="id"
                    data={cigars}
                    columns={columns}
                    search
                    // exportCSV
                  >
                    {
                      (toolkitprops) => (
                        <div>
                          {/* <ExportCSVButton { ...toolkitprops.csvProps }>Export CSV!!</ExportCSVButton> */}
                          {/* Known issue https://github.com/react-bootstrap-table/react-bootstrap-table2/issues/787 */}
                          {isMobile && (
                            <DebounceSearchBar
                              {...toolkitprops.searchProps}
                              placeholder="Search cigars..."
                              onChange={() => this.setState({ page: 1 })}
                              onSearch={(query) => {
                                // INFO Since mobile doesn't use BootstrapTable, this needs managed manually
                                this.setState({ searchText: query }, this.updateRecords);
                              }}
                            />
                          )}
                          {!isMobile && (
                            <DebounceSearchBar
                              {...toolkitprops.searchProps}
                              placeholder="Search cigars..."
                              onChange={() => this.setState({ page: 1 })}
                            />
                          )}
                          {/* <ClearSearchButton { ...props.searchProps } /> */}
                          <button
                            className="btn btn-primary"
                            onClick={() => this.onOpenModal('cigar')}
                            style={isMobile ? {
                              padding: '6px 14px',
                              float: 'right',
                            } : { float: 'right' }}
                          >
                            {isMobile ? (<i className="icon icon-plus" />) : 'Add Cigar'}
                          </button>
                          <button
                            className="btn btn-primary mr-2"
                            onClick={() => this.onOpenModal('import')}
                            style={isMobile ? {
                              padding: '6px 14px',
                              float: 'right',
                            } : { float: 'right' }}
                          >
                            {isMobile ? (<i className="icon icon-import" />) : 'Import Cigar'}
                          </button>
                          {/* FIXME I think this would be better in a "View Options" menu - this way, we can end up selecting each of the 6 image types to change to */}
                          {!isMobile && (
                            <button
                              className="btn btn-outline-primary mr-2"
                              onClick={() => {
                                this.setState((prevState) => ({ cigarImageView: prevState.cigarImageView === 'band' ? 'display' : 'band' }), () => {
                                  this.updateRecords();
                                });
                              }}
                              style={isMobile ? {
                                padding: '6px 14px',
                                float: 'right',
                              } : { float: 'right' }}
                            >
                              {isMobile ? (<i className="icon icon-view-grid" />) : 'View Options'}
                            </button>
                          )}
                          {isMobile ? (
                            <div>
                              {cigars.map((cigar, i) => {
                                let cigarImageFull = '';
                                // var cigarImageBand = null;
                                // var cigarImageFoot = null;
                                // var cigarImageDisplay = null;

                                if (cigar.images) {
                                  for (let j = 0; j < cigar.images.length; j++) {
                                    const image = cigar.images[j];
                                    if (image.image_type === 0) {
                                      cigarImageFull = image.image_url;
                                    }
                                    // else if (image.image_type === 1) {
                                    //     cigarImageBand = image.image_url;
                                    // } else if (image.image_type === 5) {
                                    //     cigarImageFoot = image.image_url;
                                    // } else if (image.image_type === 6) {
                                    //     cigarImageDisplay = image.image_url;
                                    // }
                                  }
                                }
                                return (
                                  <div
                                    className="col-sm-12 col-md-6 col-lg-4 col-xl-3"
                                    style={{ padding: 0, display: 'inline-block' }}
                                  >
                                    <div className="card height-equal">
                                      <div className="card-body native-image-bg">
                                        <span style={{ float: 'right' }}>
                                          {this.actionDropdown(i, cigar)}
                                        </span>
                                        <div className="row">
                                          <div className="col-4">
                                            <img
                                              src={cigarImageFull}
                                              style={{ width: '100%' }}
                                              onClick={() => {
                                                console.log(cigarImageFull);
                                                this.setState({
                                                  photoIndex: i,
                                                  openLightbox: true,
                                                });
                                              }}
                                            />
                                          </div>
                                          <div className="col-8">
                                            <h4>
                                              {cigar.brand}
                                              {' '}
                                              {cigar.name}
                                            </h4>
                                            <p>
                                              <strong>Origin:</strong>
                                              {' '}
                                              {cigar.origin}
                                            </p>
                                            <p>
                                              <strong>Wrapper:</strong>
                                              {' '}
                                              {cigar.wrapper}
                                            </p>
                                            <p>
                                              <strong>Binder:</strong>
                                              {' '}
                                              {cigar.binder}
                                            </p>
                                            <p>
                                              <strong>Filler:</strong>
                                              {' '}
                                              {cigar.filler}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        className="card-footer"
                                        style={{
                                          flexDirection: 'row',
                                          justifyContent: 'center',
                                          alignItems: 'center',
                                        }}
                                      >
                                        <button
                                          type="button"
                                          className="btn btn-primary ml-2"
                                          style={{ float: 'right' }}
                                          onClick={() => this.onOpenModal('cigar', cigar)}
                                        >
                                          {'Edit'}
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}

                              <Pagination
                                page={page}
                                sizePerPage={sizePerPage}
                                totalSize={totalSize}
                                handlePageChange={this.handlePageChange}
                                onShowPagePicker={() => this.onOpenModal('page')}
                              />
                            </div>
                          ) : (
                            <PaginationProvider
                              pagination={paginationFactory({
                                pageButtonRenderer,
                                page,
                                sizePerPage,
                                // withFirstAndLast: false,
                                custom: true,
                                totalSize,
                                showTotal: true,
                              })}
                            >
                              {
                                ({
                                  paginationProps,
                                  paginationTableProps,
                                }) => (
                                  <div>
                                    <div style={{ marginLeft: 10, float: 'left' }}>
                                      <SizePerPageDropdownStandalone
                                        {...paginationProps}
                                      />
                                    </div>
                                    <BootstrapTable
                                      remote
                                      keyField="id"
                                      data={cigars}
                                      columns={columns}
                                      // selectRow={ selectRow }
                                      rowEvents={rowEvents}
                                      defaultSorted={defaultSorted}
                                      wrapperClasses="table-responsive"
                                      {...paginationTableProps}
                                      cellEdit={cellEditFactory(cellEditProps)}
                                      onTableChange={this.handleTableChange}
                                      noDataIndication={() => (!loading
                                        ? <NoDataIndicator message="No cigars found matching search query." />
                                        : <TableLoader />)}
                                      {...toolkitprops.baseProps}
                                    />
                                    <PaginationTotalStandalone
                                      {...paginationProps}
                                    />
                                    <div style={{ width: 200, display: 'inline-block', float: 'right' }}>
                                      <Pagination
                                        hideNavigation
                                        page={page}
                                        sizePerPage={sizePerPage}
                                        totalSize={totalSize}
                                        handlePageChange={this.handlePageChange}
                                        onShowPagePicker={() => this.onOpenModal('page')}
                                      />
                                    </div>
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                )
                              }
                            </PaginationProvider>
                          )}

                          {this.pagePickerModal()}
                          {this.mergeModal()}
                          {this.vitolasModal()}
                          {this.addVitolaModal()}
                          {this.similarModal()}
                          {this.importModal()}
                          {this.renderImporterDatatableModal()}
                          {this.socialModal()}
                          {this.cigarModal()}
                          {this.dialogModal()}
                          {this.vitolaImageModal()}
                          {this.renderMediaCropper()}

                          {/* TODO I would rather the "next" images be the ones for the same cigar - there are up to 4 images linked to each cigar, iterate those instead.
                                                        TODO We may have to map the images to an array here or on the server */}
                          {/* {openLightbox && ( */}
                          {/*  <Lightbox */}
                          {/*    mainSrc={this.getMainImage(cigars[photoIndex])} */}
                          {/*    nextSrc={this.getMainImage(cigars[photoIndex + 1])} */}
                          {/*    prevSrc={this.getMainImage(cigars[(photoIndex + cigars.length - 1) % cigars.length])} */}
                          {/*    imageTitle={`${photoIndex + 1}/${cigars.length}`} */}
                          {/*    onCloseRequest={() => this.setState({ isOpen: false })} */}
                          {/*    onMovePrevRequest={() => this.setState({ */}
                          {/*      photoIndex: (photoIndex + cigars.length - 1) % cigars.length, */}
                          {/*    })} */}
                          {/*    onMoveNextRequest={() => this.setState({ */}
                          {/*      photoIndex: (photoIndex + 1) % cigars.length, */}
                          {/*    })} */}
                          {/*  /> */}
                          {/* )} */}
                        </div>
                      )
                    }
                  </ToolkitProvider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  cigarModal = () => {
    const {
      brands, // INFO This and below aren't needed here unless we add the most common default options to the reducer
      manufacturers,
      origins,
      wrappers,
      binders,
      fillers,
    } = this.props.CigarManager;

    const {
      selectedId,
      selectedBrand,
      selectedManufacturer,
      selectedOrigin,
      selectedWrapper,
      selectedBinder,
      selectedFiller,
      selectedStrength,
      selectedTags,
    } = this.state;

    return (
      <Modal
        isOpen={this.state.showCigarModal}
        onClosed={() => this.onCloseModal('cigar')}
        toggle={() => this.onCloseModal('cigar')}
        className="modal-dialog-lg"
      >
        <div className="modal-header">
          <h5 className="modal-title" id="cigar-modal-title">{selectedId ? 'Edit Cigar' : 'Add New Cigar'}</h5>
        </div>
        <div className="modal-body">
          <div className="row">
            <div className="col-md-8">
              <Card style={{ padding: 10 }}>
                <div>
                  <span style={{ fontWeight: 700 }}>Images</span>
                  {isMobile && (
                    <span
                      style={{
                        color: '#d6c290',
                        cursor: 'pointer',
                        float: 'right',
                      }}
                      onClick={() => alert('Coming soon')}
                    >
                      {'Edit'}
                    </span>
                  )}
                </div>
                {isMobile ? <div>Coming soon</div> : (
                  <div
                    className="row"
                    style={{
                      marginBottom: 10,
                      marginLeft: -20,
                      marginRight: 0,
                    }}
                  >
                    <div className="col-md-3">
                      <Dropzone
                        id="cigarImageFull"
                        ref={(ref) => this.cigarImageFull = ref}
                        config={{
                          postUrl: 'no-url',
                          iconFiletypes: ['.jpg', '.png'],
                        }}
                        djsConfig={{
                          dictDefaultMessage: 'Drop band (on cigar) here',
                          previewTemplate: djsPreviewTemplate,
                        }}
                        eventHandlers={this.dropzoneEventHandlers('cigarImageFull')}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <section className="dropzone">
                            <div style={{ height: '100%' }} {...getRootProps()}>
                              <input {...getInputProps()} />
                              <p>Drop band (on cigar) here</p>
                            </div>
                          </section>
                        )}
                      </Dropzone>
                    </div>
                    <div className="col-md-3">
                      <Dropzone
                        id="cigarImageBand"
                        ref={(ref) => this.cigarImageBand = ref}
                        config={{
                          postUrl: 'no-url',
                          iconFiletypes: ['.jpg', '.png'],
                        }}
                        djsConfig={{
                          dictDefaultMessage: 'Drop band (full) here',
                          previewTemplate: djsPreviewTemplate,
                        }}
                        eventHandlers={this.dropzoneEventHandlers('cigarImageBand')}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <section className="dropzone">
                            <div style={{ height: '100%' }} {...getRootProps()}>
                              <input {...getInputProps()} />
                              <p>Drop band (full) here</p>
                            </div>
                          </section>
                        )}
                      </Dropzone>
                    </div>
                    <div className="col-md-3">
                      <Dropzone
                        id="cigarImageFoot"
                        ref={(ref) => this.cigarImageFoot = ref}
                        config={{
                          postUrl: 'no-url',
                          iconFiletypes: ['.jpg', '.png'],
                        }}
                        djsConfig={{
                          dictDefaultMessage: 'Drop foot band here',
                          previewTemplate: djsPreviewTemplate,
                        }}
                        eventHandlers={this.dropzoneEventHandlers('cigarImageFoot')}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <section className="dropzone">
                            <div style={{ height: '100%' }} {...getRootProps()}>
                              <input {...getInputProps()} />
                              <p>Drop foot band here</p>
                            </div>
                          </section>
                        )}
                      </Dropzone>
                    </div>
                    <div className="col-md-3">
                      <Dropzone
                        id="cigarImageDisplay"
                        ref={(ref) => this.cigarImageDisplay = ref}
                        config={{
                          postUrl: 'no-url',
                          iconFiletypes: ['.jpg', '.png'],
                        }}
                        djsConfig={{
                          dictDefaultMessage: 'Drop display image here',
                          previewTemplate: djsPreviewTemplate,
                        }}
                        eventHandlers={this.dropzoneEventHandlers('cigarImageDisplay')}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <section className="dropzone">
                            <div style={{ height: '100%' }} {...getRootProps()}>
                              <input {...getInputProps()} />
                              <p>Drop display image here</p>
                            </div>
                          </section>
                        )}
                      </Dropzone>
                    </div>
                  </div>
                )}
              </Card>
              <Card style={{ padding: 10 }}>
                <div className="row">
                  <div className="col-md-6">
                    <span style={{ fontWeight: 700 }}>Brand</span>
                    <div
                      style={{
                        marginTop: 10,
                        marginBottom: 10,
                      }}
                    >
                      <AsyncCreatableSelect
                        value={selectedBrand}
                        onChange={this.handleSelectChange('selectedBrand')}
                        getOptionLabel={(option) => `${option.label}`}
                        getOptionValue={(option) => `${option}`}
                        isOptionSelected={(option) => selectedBrand && (selectedBrand.value === option.value)}
                        loadOptions={this.loadBrands}
                        defaultOptions={brands.map((value) => ({
                          value,
                          label: value,
                        }))}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <span style={{ fontWeight: 700 }}>Name</span>
                    <div
                      style={{
                        marginTop: 10,
                        marginBottom: 10,
                      }}
                    >
                      <input
                        className="form-control"
                        type="text"
                        name="cigar-name"
                        value={this.state.selectedName}
                        style={{ textTransform: 'capitalize' }}
                        onChange={this.handleInputChange('selectedName')}
                        id="select-name"
                      />
                    </div>
                  </div>
                </div>
                <span style={{ fontWeight: 700 }}>Manufacturer</span>
                <div
                  style={{
                    marginTop: 10,
                    marginBottom: 10,
                  }}
                >
                  <AsyncCreatableSelect
                    value={selectedManufacturer}
                    onChange={this.handleSelectChange('selectedManufacturer')}
                    getOptionLabel={(option) => `${option.label}`}
                    getOptionValue={(option) => `${option}`}
                    isOptionSelected={(option) => selectedManufacturer && (selectedManufacturer.value === option.value)}
                    loadOptions={this.loadManufacturers}
                    defaultOptions={manufacturers.map((value) => ({
                      value,
                      label: value,
                    }))}
                  />
                </div>
              </Card>
              <Card style={{ padding: 10 }}>
                <span style={{ fontWeight: 700 }}>Origin</span>
                <div
                  style={{
                    marginTop: 10,
                    marginBottom: 10,
                  }}
                >
                  <AsyncCreatableSelect
                    value={selectedOrigin}
                    onChange={this.handleSelectChange('selectedOrigin')}
                    getOptionLabel={(option) => `${option.label}`}
                    getOptionValue={(option) => `${option}`}
                    isOptionSelected={(option) => selectedOrigin && (selectedOrigin.value === option.value)}
                    loadOptions={this.loadOrigins}
                    defaultOptions={origins.map((value) => ({
                      value,
                      label: value,
                    }))}
                  />
                </div>
                <span style={{ fontWeight: 700 }}>Wrapper</span>
                <div
                  style={{
                    marginTop: 10,
                    marginBottom: 10,
                  }}
                >
                  <AsyncCreatableSelect
                    value={selectedWrapper}
                    onChange={this.handleSelectChange('selectedWrapper')}
                    isMulti
                    getOptionLabel={(option) => `${option.label}`}
                    getOptionValue={(option) => `${option}`}
                    isOptionSelected={(option) => selectedWrapper && (selectedWrapper.value === option.value)}
                    loadOptions={this.loadWrappers}
                    defaultOptions={wrappers.map((value) => ({
                      value,
                      label: value,
                    }))}
                  />
                </div>
                <span style={{ fontWeight: 700 }}>Binder</span>
                <div
                  style={{
                    marginTop: 10,
                    marginBottom: 10,
                  }}
                >
                  <AsyncCreatableSelect
                    value={selectedBinder}
                    onChange={this.handleSelectChange('selectedBinder')}
                    isMulti
                    getOptionLabel={(option) => `${option.label}`}
                    getOptionValue={(option) => `${option}`}
                    isOptionSelected={(option) => selectedBinder && (selectedBinder.value === option.value)}
                    loadOptions={this.loadBinders}
                    defaultOptions={binders.map((value) => ({
                      value,
                      label: value,
                    }))}
                  />
                </div>
                <span style={{ fontWeight: 700 }}>Filler</span>
                <div
                  style={{
                    marginTop: 10,
                    marginBottom: 10,
                  }}
                >
                  <AsyncCreatableSelect
                    value={selectedFiller}
                    onChange={this.handleSelectChange('selectedFiller')}
                    isMulti
                    getOptionLabel={(option) => `${option.label}`}
                    getOptionValue={(option) => `${option}`}
                    isOptionSelected={(option) => selectedFiller && (selectedFiller.value === option.value)}
                    loadOptions={this.loadFillers}
                    defaultOptions={fillers.map((value) => ({
                      value,
                      label: value,
                    }))}
                  />
                </div>
                <span style={{ fontWeight: 700 }}>Strength</span>
                <div
                  style={{
                    marginTop: 10,
                    marginBottom: 10,
                  }}
                >
                  <Select
                    value={selectedStrength}
                    onChange={this.handleSelectChange('selectedStrength')}
                    options={this.state.strengths}
                    getOptionLabel={(option) => `${option.label}`}
                    getOptionValue={(option) => `${option}`}
                    isOptionSelected={(option) => selectedStrength && (selectedStrength.value === option.value)}
                  />
                </div>
              </Card>
            </div>
            <div className="col-md-4">
              <Card style={{ padding: 10 }}>
                <span style={{ fontWeight: 700 }}>Tags</span>
                <div
                  style={{
                    marginTop: 10,
                    marginBottom: 10,
                  }}
                >
                  <AsyncCreatableSelect
                    value={selectedTags}
                    onChange={this.handleSelectChange('selectedTags')}
                    options={this.state.tags}
                    isMulti
                    // getOptionLabel={option =>
                    //     `${option}`
                    // }
                    // getOptionValue={option => `${option}`}
                    isOptionSelected={(option) => selectedTags && (selectedTags === option)}
                  />
                </div>
              </Card>

              <Card style={{ padding: 10 }}>
                <div>
                  <span style={{ fontWeight: 700 }}>Vitolas</span>
                  <span
                    style={{
                      color: '#d6c290',
                      cursor: 'pointer',
                      float: 'right',
                    }}
                    onClick={() => this.onOpenModal('vitolas')}
                  >
                    {'Manage'}
                  </span>
                </div>
                {(this.state.selectedVitolas && this.state.selectedVitolas.length > 0) ? this.state.selectedVitolas.sort((a, b) => (a.formatted_name || formattedVitolaName(a)).localeCompare(b.formatted_name || formattedVitolaName(b))).map((vitola, index) => (
                  <>
                    <div
                      style={{
                        paddingTop: 5,
                        paddingBottom: 5,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <div style={{ flex: 1 }}>{vitola.formatted_name || formattedVitolaName(vitola)}</div>
                      <div style={{ flex: 0 }}>
                        <IconButton
                          aria-label="more"
                          id={`vitola-row-${index}`}
                          aria-controls="long-menu"
                          aria-expanded={this.state.anchorEl && this.state.anchorEl.id === `vitola-row-${index}` ? 'true' : undefined}
                          aria-haspopup="true"
                          onClick={(event) => {
                            this.setState({
                              anchorEl: event.currentTarget,
                            });
                          }}
                        >
                          <MoreVertIcon />
                        </IconButton>
                        <Menu
                          // id="basic-menu"
                          anchorEl={this.state.anchorEl}
                          open={this.state.anchorEl && this.state.anchorEl.id === `vitola-row-${index}`}
                          onClose={() => {
                            this.setState({ anchorEl: null });
                          }}
                          MenuListProps={{
                            'aria-labelledby': 'basic-button',
                          }}
                        >
                          <MenuItem
                            onClick={(e) => {
                              e.stopPropagation();
                              this.setState({
                                selectedVitola: vitola,
                                vitolaImageFull: vitola.image_url,
                                showVitolaImageModal: true,
                                selectedImageUrl: null,
                                downloadingMedia: false,
                                anchorEl: null,
                              });
                            }}
                          >
                            <Icon name="image" />
                            {' '}
                            {vitola.image_url ? 'Edit Image' : 'Add Image'}
                          </MenuItem>
                          <MenuItem
                            onClick={(e) => {
                              e.stopPropagation();
                              // showMergeModal(vitola);
                              this.setState({ anchorEl: null });
                              alert('Coming soon!');
                            }}
                          >
                            <Icon name="award" />
                            {' '}
                            {'Manage Awards'}
                          </MenuItem>
                          <MenuItem
                            onClick={(e) => {
                              e.stopPropagation();
                              // showMergeModal(vitola);
                              this.setState({ anchorEl: null });
                              alert('Coming soon!');
                            }}
                          >
                            <Icon name="git-merge" />
                            {' '}
                            {'Merge'}
                          </MenuItem>
                          <MenuItem
                            onClick={(e) => {
                              e.stopPropagation();
                              // onDeleteVitola(vitola);
                              this.setState({ anchorEl: null });
                              alert('Coming soon!');
                            }}
                          >
                            <Icon name="trash" />
                            {' '}
                            {'Remove'}
                          </MenuItem>
                        </Menu>
                      </div>
                    </div>
                    {vitola.image_url && <img src={vitola.image_url} alt={vitola.formatted_name} style={{ width: '100%' }} />}
                  </>
                )) : (
                  <div>No linked vitolas</div>
                )}
                <span
                  style={{
                    color: '#d6c290',
                    cursor: 'pointer',
                    float: 'right',
                    width: '100%',
                    textAlign: 'center',
                    marginTop: 10,
                  }}
                  onClick={() => {
                    this.setState({ showImportModal: true, importVitolasOnly: true });
                  }}
                >
                  {'Sync from URL'}
                </span>
              </Card>

              <Card style={{ padding: 10 }}>
                <div>
                  <span style={{ fontWeight: 700 }}>Similar Cigars</span>
                  <span
                    style={{
                      color: '#d6c290',
                      cursor: 'pointer',
                      float: 'right',
                    }}
                    onClick={() => this.onOpenModal('similar')}
                  >
                    {'Manage'}
                  </span>
                </div>
                <List>
                  {(this.state.similarCigars && this.state.similarCigars.length > 0 && this.state.similarCigars[0].id) ? this.state.similarCigars.map((cigar) => {
                    if (cigar.id) {
                      return (
                        <ListItem key={cigar.id} button>
                          <ListItemAvatar>
                            <Avatar alt={cigar.full_name} src={cigar.image_url}>{cigar.full_name.charAt(0)}</Avatar>
                          </ListItemAvatar>
                          <ListItemText primary={cigar.full_name} />
                        </ListItem>
                      );
                    }
                  }) : (
                    <div>No similar cigars</div>
                  )}
                </List>
              </Card>

              <div className="row" style={{ justifyContent: 'center' }}>
                <button
                  id="merge"
                  style={{ margin: 5 }}
                  type="button"
                  className="btn btn-raised btn-default btn-sm"
                  onClick={() => {
                    alert('Coming back soon! This doesn\'t seem to be working properly.');
                    // this.onOpenModal('merge')
                  }}
                >
                  {'Merge Into Another Cigar'}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <Button onClick={this.deleteCigar} color="danger">Delete</Button>
          <Button onClick={() => this.onCloseModal('cigar')}>Cancel</Button>
          <Button
            onClick={() => this.onSaveModal('cigar')}
            variant="contained"
            disabled={this.state.uploadingMedia}
            color="secondary"
          >
            {this.state.uploadingMedia ? 'Please wait...' : 'Save'}
          </Button>
        </div>
      </Modal>
    );
  };

  // TODO Make this a component? Make all the modals their own components?
  mergeModal = () => (
    <Modal
      isOpen={this.state.showMergeModal}
      onClosed={() => this.onCloseModal('merge')}
      toggle={() => this.onCloseModal('merge')}
    >
      <div className="modal-header">
        <h5 className="modal-title">Merge Cigar</h5>
      </div>
      <div className="modal-body md">
        Select cigar to merge into:
        <AsyncSelect
          value={this.state.mergeCigar}
          onChange={this.handleSelectChange('mergeCigar')}
          getOptionLabel={(option) => `${option.label}`}
          getOptionValue={(option) => `${option}`}
          isOptionSelected={(option) => this.state.mergeCigar.value === option.value}
          loadOptions={this.loadCigars}
        />
        <div className="checkbox checkbox-primary ml-3">
          <Input name="force" id="force-cb" type="checkbox" checked={this.state.selectedForceMerge} />
          <Label className="mb-0" htmlFor="force-cb">
            {'Force details into selected cigar?'}
            <i
              style={{ marginLeft: 10 }}
              className="icon-info-alt"
              id="forceTooltip"
            />
          </Label>
        </div>
        <Tooltip
          placement="top"
          isOpen={this.state.showMergeTooltip}
          target="forceTooltip"
          toggle={this.toggleTooltip('showMergeTooltip')}
        >
          This will force the active cigar details into the cigar from the select field above. When left unchecked, the
          software will only use the data from the active cigar to fill in missing data from the one in the select
          field.
        </Tooltip>
      </div>
      <div className="modal-footer">
        <Button onClick={() => this.onCloseModal('merge')}>Cancel</Button>
        <Button
          onClick={() => this.onSaveModal('merge')}
          variant="contained"
          color="secondary"
          disabled={this.state.merging}
        >
          {this.state.merging && <Spinner color="light" style={{ marginRight: 8 }} />}
          {this.state.merging ? 'Merging...' : 'Merge'}
        </Button>
      </div>
    </Modal>
  );

  importModal = () => (
    <Modal
      isOpen={this.state.showImportModal}
      onClosed={() => this.onCloseModal('import')}
      toggle={() => this.onCloseModal('import')}
    >
      <div className="modal-header">
        <h5 className="modal-title">{this.state.importVitolasOnly ? 'Import Vitolas' : 'Import Cigars'}</h5>
      </div>
      <div className="modal-body">
        <RadioGroup
          row
          aria-label="product import type"
          name="product_import_type"
          value={this.state.cigarImportType}
          onChange={this.handleRadioChange('cigarImportType')}
        >
          <FormControlLabel
            value="single_import"
            control={<Radio color="primary" />}
            label={this.state.importVitolasOnly ? 'Vitolas from URL' : 'Single cigar from URL'}
            labelPlacement="end"
          />
          <FormControlLabel
            value="bulk_import"
            control={<Radio color="primary" />}
            label="Bulk import from file"
            labelPlacement="end"
          />
        </RadioGroup>
        <Collapse isOpen={this.state.cigarImportType === 'single_import'}>
          {'URL'}
          {' '}
          <i className="icon-info-alt" id="urlTooltip" />
          <Tooltip
            placement="top"
            isOpen={this.state.showUrlTooltip}
            target="urlTooltip"
            toggle={this.toggleTooltip('showUrlTooltip')}
            style={{ width: 300 }}
          >
          Importable URLs include:
            {' '}
            <br />
            <br />
            <ul>
              <li>https://www.cigarsinternational.com/p/[*]</li>
              <li>
                {'https://www.jrcigars.com/item/[*]'}
                <strong style={{ color: 'red' }}>(currently unavailable)</strong>
              </li>
              <li>https://www.famous-smoke.com/[*]</li>
              <li>https://www.neptunecigar.com/cigar/[*]</li>
              <li>https://halfwheel.com/[*]</li>
            </ul>
          </Tooltip>
          <input
            className="form-control"
            type="text"
            name="import-url"
            value={this.state.selectedUrl}
            onChange={this.handleInputChange('selectedUrl')}
          />
        </Collapse>
        <Collapse isOpen={this.state.cigarImportType === 'bulk_import'}>
          <div>File Upload</div>
          <CsvDropzone
            id="dropzone"
            config={{
              postUrl: 'no-url',
              iconFiletypes: ['.csv'],
            }}
            djsConfig={{
              dictDefaultMessage: 'Add csv file or drop here to upload',
              previewTemplate: djsPreviewTemplate,
            }}
            onChange={(files) => {
              // eslint-disable-next-line no-console
              console.log(files);
              this.setState({
                selectedInventoryFile: files[0],
              }, () => {
                this.onCloseModal('import');
                this.onOpenModal('datatable');
              });
            }}
            getJson={(json) => {
              const selectedRecords = json.slice(0, 10);
              console.debug(selectedRecords);
              this.setState({
                selectedRecords,
                selectedRecordCount: json.length,
              });
            }}
            style={{ minHeight: 'auto' }}
          />
        </Collapse>
      </div>
      <div className="modal-footer">
        <Button onClick={() => this.onCloseModal('import')}>Cancel</Button>
        <Button
          onClick={() => this.onSaveModal('import')}
          variant="contained"
          color="secondary"
          disabled={this.state.importing}
        >
          {this.state.importing && <Spinner color="light" style={{ marginRight: 8 }} />}
          {this.state.importing ? 'Importing' : 'Import'}
        </Button>
      </div>
    </Modal>
  );

  renderImporterDatatableModal = () => {
    const {
      selectedRecords,
      selectedRecordCount,
      showImporterDatatable,
      uploading,
    } = this.state;
    if (selectedRecords && selectedRecordCount) {
      console.log(`Showing the first ${selectedRecords.length < 10 ? selectedRecords.length : 10} out of ${selectedRecordCount} records`);
    }
    return (
      <Modal
        isOpen={showImporterDatatable}
        toggle={() => this.onCloseModal('datatable')}
        onClosed={() => this.onCloseModal('datatable')}
      >
        <div className="modal-header">
          <h5 className="modal-title h2">Confirm</h5>
          <Button className="close" color="" onClick={() => this.onCloseModal('datatable')}>
            <Icon name="x" />
          </Button>
        </div>
        <div className="modal-body">
          {selectedRecords && selectedRecordCount && (
            <div>
              <div style={{ marginTop: 26 }}>
                {`Showing the first ${selectedRecords.length < 10 ? selectedRecords.length : 10} out of ${selectedRecordCount} records`}
              </div>
              {/* TODO We should validate here to ensure required fields are present with the correct names */}
              <DataTable
                noHeader
                pagination={false}
                columns={Object.keys(selectedRecords[0]).map((name) => ({
                  name: name.replace('_', ' ').replace('_', ' ')
                    .split(' ')
                    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
                    .join(' '),
                  selector: name,
                }))}
                data={selectedRecords}
                selectableRows={false}
              />
            </div>
          )}
        </div>
        <div className="modal-footer">
          <Button onClick={() => this.onCloseModal('datatable')}>Cancel</Button>
          <Button
            disabled={uploading}
            onClick={() => this.onSaveModal('datatable')}
            variant="contained"
            color="secondary"
          >
            {uploading
            && <Spinner type="border" color="light" size="sm" style={{ marginRight: 10 }} />}
            {uploading ? 'Importing...' : 'Import'}
          </Button>
        </div>
      </Modal>
    );
  };

  vitolaImageModal = () => (
    <Modal
      isOpen={this.state.showVitolaImageModal}
      onClosed={() => this.onCloseModal('vitolaImage')}
      toggle={() => this.onCloseModal('vitolaImage')}
    >
      <div className="modal-header">
        <h5 className="modal-title">Vitola Image</h5>
      </div>
      <div className="modal-body md vitola-modal">
        <div style={{ display: 'flex' }}>
          <input
            placeholder="Image URL"
            style={{ flex: 1 }}
            className="form-control"
            value={this.state.selectedImageUrl}
            onChange={this.handleInputChange('selectedImageUrl')}
          />
        </div>
        <div style={{ textAlign: 'center', marginTop: 6 }}>{' - or - '}</div>
        <Dropzone
          id="vitolaImageFull"
          ref={(ref) => this.vitolaImageFull = ref}
          config={{
            postUrl: 'no-url',
            iconFiletypes: ['.jpg', '.png'],
          }}
          djsConfig={{
            dictDefaultMessage: 'Drop full cigar image for this vitola here',
            // previewTemplate: djPreviewTemplate,
          }}
          eventHandlers={this.dropzoneEventHandlers('vitolaImageFull')}
        >
          {({ getRootProps, getInputProps }) => (
            <section className="dropzone">
              <div style={{ height: '100%' }} {...getRootProps()}>
                <input {...getInputProps()} />
                <p>{`Drop vitola image here or ${isMobile ? 'tap' : 'click'} to browse`}</p>
              </div>
            </section>
          )}
        </Dropzone>
        {/*<i*/}
        {/*  style={{ marginLeft: 10 }}*/}
        {/*  className="icon-info-alt"*/}
        {/*  id="forceTooltip"*/}
        {/*/>*/}
        {/*<Tooltip*/}
        {/*  placement="top"*/}
        {/*  isOpen={this.state.showMergeTooltip}*/}
        {/*  target="forceTooltip"*/}
        {/*  toggle={this.toggleTooltip('showMergeTooltip')}*/}
        {/*>*/}
        {/*  This will force the active cigar details into the cigar from the select field above. When left unchecked, the*/}
        {/*  software will only use the data from the active cigar to fill in missing data from the one in the select*/}
        {/*  field.*/}
        {/*</Tooltip>*/}
      </div>
      <div className="modal-footer">
        <Button onClick={() => this.onCloseModal('vitolaImage')}>Cancel</Button>
        <Button
          onClick={() => {
            const { selectedVitola, vitolaImageFull, selectedImageUrl, selectedVitolas } = this.state;
            if (selectedImageUrl) {
              this.setState({ downloadingMedia: true });
              const urlParts = selectedImageUrl.split('?')[0].split('/');
              axios.post(`${Constants.apiPath}/import/media`, {
                original_src: this.state.selectedImageUrl,
                filename: urlParts[urlParts.length - 1],
                prefix: 'vitolas', // This is the CDN prefix, e.g. 'products', 'cigars', etc
              }).then((response) => {
                console.log(response.data);
                const media = response.data;
                const vitolas = this.state.selectedVitolas || [];
                selectedVitola.image_uri = media.media_url.replace(config.cdnUrl, '').replace('https://', '');
                selectedVitola.image_url = media.media_url;
                for (let i = 0; i < vitolas.length; i += 1) {
                  if (vitolas[i].id === selectedVitola.id) {
                    vitolas[i] = selectedVitola;
                    break;
                  }
                }
                this.setState({ selectedVitolas: vitolas });
                this.onCloseModal('vitolaImage');
              }).catch((error) => {
                console.error(error);
              }).finally(() => this.setState({ downloadingMedia: true }));
            } else {
              if (!selectedVitola || !vitolaImageFull) {
                return;
              }
              const vitolas = selectedVitolas || [];
              for (let i = 0; i < vitolas.length; i += 1) {
                if (vitolas[i].id === selectedVitola.id) {
                  selectedVitola.image_uri = vitolaImageFull.replace(config.cdnUrl, '').replace('https://', '');
                  selectedVitola.image_url = vitolaImageFull;
                  vitolas[i] = selectedVitola;
                  break;
                }
              }
              this.setState({ selectedVitolas: vitolas });
              this.onCloseModal('vitolaImage');
            }
          }}
          variant="contained"
          color="secondary"
          disabled={this.state.downloadingMedia}
        >
          {this.state.downloadingMedia && <Spinner color="light" style={{ marginRight: 8 }} />}
          {this.state.downloadingMedia ? 'Importing...' : 'Next'}
        </Button>
      </div>
    </Modal>
  );

  handleSelectedVitolaChange = (index, type) => (option) => {
    console.debug('Updating option for type: ', type);
    console.debug(option);
    const vitolas = this.state.selectedVitolas;
    const vitola = vitolas[index] || {};
    if (option) {
      if (type === 'shape') {
        vitola[type] = option.value;
        vitola.select_shape = option;
      } else if (option.target) {
        vitola[type] = option.target.value;
      } else {
        vitola[type] = option;
      }
    }
    vitola.formatted_name = formattedVitolaName(vitola);
    vitolas[index] = vitola;
    this.setState({ selectedVitolas: vitolas });
  };

  loadShapes = (searchTerm, callback) => {
    console.log(`${Constants.clientPath}/cigars/shapes?q=${searchTerm}`);
    return axios.get(`${Constants.clientPath}/cigars/shapes?q=${searchTerm}`)
      .then((res) => {
        console.log(res.data);
        callback(res.data.map((shape) => ({
          value: shape,
          label: shape,
        })));
      }).catch((err) => {
        console.log(err);
      });
  };

  renderLinkedVitolaRow = (index) => {
    const idPattern = [
      {
        char: /\d/,
        repeat: 1,
      },
      { exactly: '.' },
      {
        char: /\d/,
        repeat: 1,
      },
      { exactly: '"' },
      { exactly: 'x' },
      {
        char: /\d/,
        repeat: 2,
      },
    ];

    const vitola = this.state.selectedVitolas[index] || {};
    console.debug('Selected vitola:');
    console.debug(vitola);
    if (vitola.id && (!vitola.size || vitola.size.length === 0)) {
      return (
        <div
          className="row"
          style={{
            marginLeft: 10,
            marginRight: 10,
          }}
        >
          <div>Invalid vitola details. Please contact an admin.</div>
        </div>
      );
    }
    return (
      <div
        className="row"
        style={{
          marginLeft: 10,
          marginRight: 10,
        }}
      >
        <div className="col-md-4">
          <span>Alias (Optional)</span>
          <input
            className="form-control"
            value={vitola.alias}
            onChange={this.handleSelectedVitolaChange(index, 'alias')}
          />
        </div>
        <div className="col-md-4">
          <span>{vitola.id ? 'Shape' : 'Search Shape To Link'}</span>
          <AsyncCreatableSelect
            className={`${this.state.selectedVitolas[index] && !vitola.shape ? 'is-invalid' : ''}`}
            value={vitola.select_shape}
            onChange={this.handleSelectedVitolaChange(index, 'shape')}
            loadOptions={this.loadShapes.bind(this)}
            defaultOptions={this.state.selectedVitolas[index] && vitola.select_shape ? [{
              label: vitola.shape,
              value: vitola.shape,
            }] : defaultVitolas}
            isOptionSelected={(option) => vitola.select_shape && (vitola.select_shape.value === option.value)}
          />
        </div>
        <div className="col-md-3">
          <span>Size</span>
          <FormattedInput
            className={`form-control ${this.state.selectedVitolas[index] && !vitola.size ? 'is-invalid' : ''}`}
            value={vitola.size}
            format={idPattern}
            onChange={this.handleSelectedVitolaChange(index, 'size')}
          />
        </div>
        {vitola.id && (
          <div
            style={{
              lineHeight: '60px',
              marginTop: 8,
            }}
          >
            <Icon
              name="trash"
              style={{ cursor: 'pointer' }}
              onClick={() => {
                // TODO Need to build an array of vitolas to remove off the server
                const { selectedVitolas } = this.state;
                selectedVitolas.splice(index, 1);
                this.setState({ selectedVitolas });
              }}
            />
          </div>
        )}
      </div>
    );
  };

  renderLinkedVitolas = () => {
    const linkedVitolas = [...this.state.selectedVitolas];
    if (!isMobile) {
      linkedVitolas.push({}); // Adds an extra empty object to the end
    }
    return (
      <div className="linked-cigar-block">
        {
          linkedVitolas.map((vitola, index) => this.renderLinkedVitolaRow(index))
        }
        {/* <button type="button" className="btn btn-outline-primary" onClick={() => {}}>Add Another</button> */}
      </div>
    );
  };

  vitolasModal = () => (
    <Modal
      isOpen={this.state.showVitolasModal}
      onClosed={() => this.onCloseModal('vitolas')}
      toggle={() => this.onCloseModal('vitolas')}
    >
      <div className="modal-header">
        <h5 className="modal-title">Edit Vitolas</h5>
      </div>
      <div className="modal-body md">
        {this.renderLinkedVitolas()}
        {isMobile && (
          <Button onClick={() => this.onOpenModal('vitola')}>Add Vitola</Button>
        )}
      </div>
      <div className="modal-footer">
        <Button onClick={() => this.onCloseModal('vitolas')}>Cancel</Button>
        <Button onClick={() => this.onSaveModal('vitolas')} variant="contained" color="secondary">Save</Button>
      </div>
    </Modal>
  );

  addVitolaModal = () => (
    <VitolaEditor
      open={this.state.showVitolaModal}
      vitola={{}}
      onClose={() => this.onCloseModal('vitola')}
      onSave={(vitola) => {
        this.setState((prevState) => ({ selectedVitolas: [...prevState.selectedVitolas, vitola] }));
        this.onSaveModal('vitola');
      }}
    />
  );

  similarModal = () => (
    <Modal
      isOpen={this.state.showSimilarModal}
      onClosed={() => this.onCloseModal('similar')}
      toggle={() => this.onCloseModal('similar')}
    >
      <div className="modal-header">
        <h5 className="modal-title">Link Similar Cigars</h5>
      </div>
      <div className="modal-body md">
        {/* TODO Need to be dynamic - add a blank option to the end when text in first is updated / selected */}
        {/* TODO Only show delete button if index > 0 */}
        {this.state.similarCigars.map((cigar) => (
          <div key={cigar.value || 0} className="row">
            <AsyncCreatableSelect
              value={cigar}
              className="similar-cigar-search"
              style={{
                control: (styles) => ({
                  ...styles,
                  minWidth: 300,
                }),
              }}
              onChange={this.handleSelectChange('mergeCigar')}
              getOptionLabel={(option) => `${option.label}`}
              getOptionValue={(option) => `${option}`}
              isOptionSelected={(option) => cigar.value === option.value}
            />
            <button
              type="button"
              className="btn btn-outline-danger ml-2"
              style={{ float: 'left' }}
              onClick={() => {
                console.log('Not implemented yet');
              }}
            >
              <i className="icon-trash" />
            </button>
          </div>
        ))}
      </div>
      <div className="modal-footer">
        <Button onClick={() => this.onCloseModal('similar')}>Cancel</Button>
        <Button onClick={() => this.onSaveModal('similar')} variant="contained" color="secondary">Update</Button>
      </div>
    </Modal>
  );

  socialModal = () => (
    <Modal
      isOpen={this.state.showSocialModal}
      onClosed={() => this.onCloseModal('social')}
      toggle={() => this.onCloseModal('social')}
    >
      <div className="modal-header">
        <h5 className="modal-title">Create Post</h5>
      </div>
      <div className="modal-body">
        <Dropzone
          onDrop={(acceptedFiles) => console.log(acceptedFiles)}
          config={{
            postUrl: 'no-url',
            iconFiletypes: ['.jpg', '.png'],
          }}
        >
          {({ getRootProps, getInputProps }) => (
            <section className="dropzone">
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <p>Drop images here</p>
              </div>
            </section>
          )}
        </Dropzone>
        Post body
        {/* <textarea className='form-control' placeholder='#cigarpics #cigarlifestyle #cigaraficianado #nowsmoking #boutthattime #cigarart #cigarphotography #smokeemifyougotem #cigarians #whisky #whiskey #cigarsnearme' /> */}
        <textarea className="form-control" />
        Additional info
        <br />
        <button
          type="button"
          className="btn btn-outline-primary mr-2"
          onClick={() => console.log('Not yet implemented')}
        >
          {'Add Rating'}
        </button>
        <button
          type="button"
          className="btn btn-outline-primary"
          onClick={() => console.log('Not yet implemented')}
        >
          {'Add Flavors'}
        </button>
        <br />
        {'Post to'}
        <br />
        <div className="checkbox checkbox-primary ml-3">
          <Input name="force" id="fb-cb" type="checkbox" checked={this.state.selectedFacebook} />
          <Label className="mb-0" htmlFor="fb-cb">Facebook</Label>
        </div>
        <div className="checkbox checkbox-primary ml-3">
          <Input name="force" id="insta-cb" type="checkbox" checked={this.state.selectedInstagram} />
          <Label className="mb-0" htmlFor="insta-cb">Instagram</Label>
        </div>
        <div className="checkbox checkbox-primary ml-3">
          <Input name="force" id="twit-cb" type="checkbox" checked={this.state.selectedTwitter} />
          <Label className="mb-0" htmlFor="twit-cb">Twitter</Label>
        </div>
      </div>
      <div className="modal-footer">
        <Button onClick={() => this.onCloseModal('social')}>Cancel</Button>
        <Button onClick={() => this.onSaveModal('social')} variant="contained" color="secondary">Post</Button>
      </div>
    </Modal>
  );

  dialogModal = () => (
    <Dialog
      open={this.state.dialogModal.show}
      onClose={this.closeDialogModal}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{this.state.dialogModal.title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {this.state.dialogModal.text}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={this.closeDialogModal}>
          Cancel
        </Button>
        <Button
          onClick={() => {
            this.closeDialogModal();
            this.deleteCigarConfirmed();
          }}
          variant="outlined"
          color="danger"
          autoFocus
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );

  closeDialogModal = () => {
    this.setState({
      dialogModal: {
        show: false,
        title: '',
        text: '',
      },
    });
  };

  // FIXME Make this a re-usable component
  pagePickerModal = () => (
    <Modal
      isOpen={this.state.showPagePickerModal}
      onClosed={() => this.onCloseModal('page')}
      toggle={() => this.onCloseModal('page')}
    >
      <div className="modal-header">
        <h5 className="modal-title">Select Page</h5>
      </div>
      <div className="modal-body">
        <TouchSpin
          max={Math.ceil(this.props.CigarManager.totalSize / this.state.sizePerPage)}
          min={1}
          step={1}
          value={this.state.number || (!this.state.edited && this.state.page)}
          onChange={(value) => {
            this.setState({ edited: true, number: value });
          }}
        />
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-outline-secondary" onClick={() => this.onCloseModal('page')}>
          {'Cancel'}
        </button>
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => {
            this.handlePageChange(null, this.state.number);
            this.onCloseModal('page');
          }}
        >
          {'Go'}
        </button>
      </div>
    </Modal>
  );
}

const mapStateToProps = ({ CigarManager }) => ({ CigarManager });

export default connect(
  mapStateToProps, {
    getAllCigars,
    searchCigars,
    getCigarById,
    getBrands,
    getManufacturers,
    getOrigins,
    getWrappers,
    getBinders,
    getFillers,
    postCigar,
    putCigar,
    updateVitolas,
  },
)(CigarTable);
