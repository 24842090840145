import React from "react";
import {Divider, Menu, MenuItem} from "@material-ui/core";
import Icon from "../icon";

class RowMoreOptions extends React.Component {
    state = {
        showPopover: false,
        anchorElement: null
    };

    togglePopover = (event) => {
        this.setState({
            showPopover: !this.state.showPopover,
            anchorElement: event.currentTarget,
        });
    };

    closePopover = () => {
        this.setState({
            showPopover: false,
            anchorElement: null,
        });
    };

    render() {
        const { items, onShow, style } = this.props;
        return (
            <div style={{display: 'inline', ...style}}>
                <Icon
                   name="more-vertical"
                   aria-label="more"
                   aria-controls="long-menu"
                   aria-owns={this.state.anchorElement ? 'long-menu' : null}
                   aria-haspopup="true"
                   className="dropbtn"
                   style={{cursor: 'pointer', color: '#2b2b2b', padding: 0, display: 'inline', height: 18, width: 18 }}
                   onClick={this.togglePopover}
                />

                <Menu
                    id="long-menu"
                    open={Boolean(this.state.anchorElement)}
                    anchorEl={this.state.anchorElement}
                    onClose={this.closePopover}
                    onEntered={onShow}
                >
                    { items.map(item => {
                        if (item.type === 'MenuItem') {
                            return (
                                <MenuItem onClick={() => { item.onClick(); this.closePopover() }} style={item.style}>{item.title}</MenuItem>
                            );
                        } else if (item.type === 'Divider') {
                            return (
                                <Divider />
                            );
                        }
                    }) }
                </Menu>
            </div>
        )
    }
}

export default RowMoreOptions;
