import axios from 'axios';
import {
  GET_ALL_BRANDS, SEARCH_BRANDS,
} from './actionTypes';
import { Constants } from '../../constants';

export const getAllBrands = (page, limit, query, brandId) => (dispatch) => {
  const urlParams = new URLSearchParams(window.location.search);
  const filter = urlParams.get('filter');
  let cigarBrand;
  if (filter) {
    if (filter === 'cigar_brands') {
      cigarBrand = true;
    } else if (filter === 'other_brands') {
      cigarBrand = false;
    }
  }
  axios.get(`${Constants.apiPath}/brands/datatables`, {
    params: {
      page,
      limit,
      q: query,
      cigar_brand: cigarBrand,
    },
  }).then((res) => {
    dispatch({
      type: GET_ALL_BRANDS,
      payload: {
        data: res.data.data,
        totalSize: res.data.recordsFiltered,
      },
    });
  }).catch((e) => {
    console.log(e.message);
  });
};

export const searchBrands = (query) => (dispatch) => {
  axios.get(`${Constants.apiPath}/cigars/brands`, {
    params: {
      q: query,
      full_detail: true,
    },
  }).then((res) => {
    // console.log(JSON.stringify(res.data));
    dispatch({
      type: SEARCH_BRANDS,
      payload: {
        loadingBrands: false,
        data: res.data,
      },
    });
  }).catch((e) => {
    console.log(e.message);
  });
};
